import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./styles.scss"
import { useSelector, useDispatch } from "react-redux";
import { Button, FormGroup } from "reactstrap";
import Select from 'react-select';
import { DateTime } from 'luxon';
import classNames from "classnames";
import { setAppUserInfo } from "../../redux/actions";
import airtable from '../../airtables';

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];

const formatTime = (hour) => {
  const adjustedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);
  return `${adjustedHour}`;
};

const Screen = ({ hostId }) => {
  const { userInfo, userType, totalTimezones } = useSelector(state => state.appInfo);

  const isTeamViewer = useMemo(() => userType === 'Team' && userInfo['Status'] !== 'Session Host', [userType, userInfo]);

  const [availability, setAvailability] = useState({});
  const [sessionHosts, setSessionHosts] = useState([]);
  const [selectedHost, setSelectedHost] = useState({ value: 'all', label: 'All Hosts' });
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [viewedHostName, setViewedHostName] = useState("");
  const [error, setError] = useState("");
  const [availableHosts, setAvailableHosts] = useState({});
  const [changed, setChanged] = useState(false);
  const dispatch = useDispatch();

  const [hostTimezone, setHostTimezone] = useState(null);

  const convertToEastern = (hour, timezone) => {
    if (timezone === 'America/Toronto') return hour;
    const date = DateTime.now().setZone(timezone).set({ hour, minute: 0, second: 0, millisecond: 0 });
    return date.setZone('America/Toronto').hour;
  };

  const fetchIndividualHostAvailability = useCallback(async (hostId) => {
    try {
      const userData = await airtable.teams.select(hostId);
      let st = userData["Availability"] ? JSON.parse(userData["Availability"]) : null;
      let userTimezone = 'America/Toronto';

      if (userData["Time Zone"]) {
        const tz = totalTimezones.find(tt => tt.id === userData["Time Zone"][0]);
        if (tz) {
          userTimezone = tz["Connected North System"];
          setHostTimezone(userTimezone);
        }
      }

      const hostAvailability = {};
      weekDays.forEach((wd, i) => {
        hostAvailability[wd] = Array(11).fill(0);
        if (st && st[i] && st[i].length) {
          st[i].forEach(a => {
            const convertedHour = convertToEastern(a, userTimezone);
            if (convertedHour >= 8 && convertedHour <= 18) {
              hostAvailability[wd][convertedHour - 8] = 1;
            }
          });
        }
      });

      setAvailability(hostAvailability);
      setViewedHostName(userData["Name"] || "Host");
    } catch (error) {
      console.error("Error fetching host data:", error);
      setError("Unable to fetch host data. Please try again.");
    }
  }, [totalTimezones]);

  const fetchAvailability = useCallback(async () => {
    if (isTeamViewer) {
      try {
        const hosts = await airtable.teams.getContractSessionHosts();
        setSessionHosts(hosts);

        if (selectedHost.value !== 'all') {
          await fetchIndividualHostAvailability(selectedHost.value);
        } else {
          setHostTimezone(null);
          // Fetch all hosts' availability
          const allAvailability = {};
          const allAvailableHosts = {};

          for (const host of hosts) {
            const userData = await airtable.teams.select(host.id);
            let st = userData["Availability"] ? JSON.parse(userData["Availability"]) : null;
            let userTimezone = 'America/Toronto';

            if (userData["Time Zone"]) {
              const tz = totalTimezones.find(tt => tt.id === userData["Time Zone"][0]);
              if (tz) userTimezone = tz["Connected North System"];
            }

            weekDays.forEach((wd, i) => {
              if (!allAvailability[wd]) allAvailability[wd] = Array(11).fill(0);
              if (!allAvailableHosts[wd]) allAvailableHosts[wd] = Array(11).fill([]);

              if (st && st[i] && st[i].length) {
                st[i].forEach(a => {
                  const convertedHour = convertToEastern(a, userTimezone);
                  if (convertedHour >= 8 && convertedHour <= 18) {
                    allAvailability[wd][convertedHour - 8]++;
                    allAvailableHosts[wd][convertedHour - 8].push(host.Name);
                  }
                });
              }
            });
          }

          setAvailability(allAvailability);
          setAvailableHosts(allAvailableHosts);
          setViewedHostName("All Hosts");
        }
      } catch (error) {
        console.error("Error fetching availability data:", error);
        setError("Unable to fetch availability data. Please try again.");
      }
    } else {
      // Fetch individual Session Host availability
      let st = userInfo["Availability"] ? JSON.parse(userInfo["Availability"]) : null;
      const tmpArray = {};
      weekDays.forEach((wd, i) => {
        tmpArray[wd] = Array(11).fill(false);
        if (st && st[i] && st[i].length) {
          st[i].forEach(a => {
            if (a >= 8 && a <= 18) {
              tmpArray[wd][a - 8] = true;
            }
          });
        }
      });
      setAvailability(tmpArray);
    }

    if (userInfo["Time Zone"]) {
      const tz = totalTimezones.find(tt => tt.id === userInfo["Time Zone"][0]);
      if (tz) {
        setSelectedTimezone({ label: tz["Connected North System"], value: tz.id });
      }
    }
  }, [isTeamViewer, selectedHost, totalTimezones, fetchIndividualHostAvailability, userInfo]);

  useEffect(() => {
    fetchAvailability();
  }, [fetchAvailability]);

  const handleBlockClick = (day, hour) => {
    if (isTeamViewer) {
      if (selectedHost.value !== 'all') {
        // For individual host view, just show if they're available
        const isAvailable = availability[day][hour] === 1;
        alert(`${viewedHostName} is ${isAvailable ? 'available' : 'not available'} at this time.`);
      } else {
        // For all hosts view, show list of available hosts
        const hosts = availableHosts[day][hour];
        const uniqueHosts = [...new Set(hosts)]; // Remove duplicates
        alert(`Available hosts: ${uniqueHosts.join(", ")}`);
      }
    } else {
      const newAvailability = { ...availability };
      newAvailability[day][hour] = !newAvailability[day][hour];
      setAvailability(newAvailability);
      setChanged(true);
    }
  };

  const saveChange = async () => {
    if (userInfo['Status'] !== "Session Host") return;
    
    if (!selectedTimezone) {
      alert("Please select your time zone.");
      return;
    }

    const result = [];
    weekDays.forEach((wd) => {
      const wdTimes = availability[wd]
        .map((da, i) => ({ availability: da, time: i + 8 }))
        .filter(a => a.availability)
        .map((d) => d.time);
      result.push(wdTimes || []);
    })
    const updatedUserInfo = await airtable.teams.update(userInfo.id, {
      "Availability": JSON.stringify(result),
      "Time Zone": [selectedTimezone.value]
    })
    dispatch(setAppUserInfo(updatedUserInfo));
    setChanged(false);
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="main-container">
      <div className="page-head">
        <div className="page-title">
          <h1>{isTeamViewer ? "Session Host Availability" : "My Availability"}</h1>
          <p>{isTeamViewer
            ? "View availability for all Contract Session Hosts. Select a specific Host below, or click on a block to see available hosts for that time slot." 
            : "Let us know which days of the week and hours in each day (in your time zone) you're available for hosting by clicking the cells below!"}
          </p>
        </div>
        {!isTeamViewer && (
          <div className="head-actions">
            <Button
              color="primary"
              type="button"
              disabled={!changed}
              onClick={() => saveChange()}
            ><i className="fa fa-save" />&nbsp;Save</Button>
          </div>
        )}
      </div>
      {isTeamViewer && (
        <div className="host-select">
          <FormGroup>
            <label>Select Session Host</label>
            <Select
              className="form-style"
              value={selectedHost}
              placeholder="All Hosts"
              onChange={(selectedOption) => {
                setSelectedHost(selectedOption);
                if (selectedOption.value === 'all') {
                  fetchAvailability();
                } else {
                  fetchIndividualHostAvailability(selectedOption.value);
                }
              }}
              options={[
                { value: 'all', label: 'All Hosts' },
                ...sessionHosts.map(host => ({ value: host.id, label: host.Name }))
              ]}
            />
          </FormGroup>
        </div>
      )}
      {!isTeamViewer && (
        <div className="timezone-select">
          <FormGroup>
            <label>What time zone do you live in?</label>
            <Select
              className="form-style"
              value={selectedTimezone}
              placeholder="Time Zone"
              onChange={(selectedOption) => {
                setSelectedTimezone(selectedOption)
                setChanged(true);
              }}
              options={totalTimezones
                .filter(tt => tt["Connected North System"].includes("Canada"))
                .map(tt => ({ value: tt.id, label: tt["Connected North System"] }))
                .sort((a, b) => a.label.localeCompare(b.label))
              }
            />
          </FormGroup>
        </div>
      )}
      <table className="availability">
        <thead>
          {isTeamViewer && (
            <tr>
              <th className="head-weekday"></th>
              <th className="head-time" colSpan="11">
                {selectedHost.value !== 'all' && hostTimezone
                  ? `Converted from ${hostTimezone} to Eastern Time (ET)`
                  : 'Eastern Time (ET)'}
              </th>
            </tr>
          )}
          <tr>
            <th className="head-weekday"></th>
            {Array.from({ length: 11 }, (_, i) => {
              const startHour = i + 8;
              const endHour = i + 9;
              const period = endHour >= 12 ? 'PM' : 'AM';
              return (
                <th key={i} className="head-time">
                  {`${formatTime(startHour)}-${formatTime(endHour)} ${period}`}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {weekDays.map(wd => (
            <tr key={wd}>
              <th className="head-weekday">{wd}</th>
              {availability[wd] && availability[wd].map((value, i) => (
                <td
                  key={i}
                  className={classNames({
                    "available-cell": true,
                    "selected": !isTeamViewer && value,
                    "low-availability": isTeamViewer && selectedHost?.value === 'all' && value > 0 && value <= 3,
                    "medium-availability": isTeamViewer && selectedHost?.value === 'all' && value > 3 && value <= 6,
                    "high-availability": isTeamViewer && selectedHost?.value === 'all' && value > 6,
                    "individual-host-available": isTeamViewer && selectedHost?.value !== 'all' && value === 1
                  })}
                  onClick={() => handleBlockClick(wd, i)}
                >
                  {isTeamViewer && selectedHost?.value === 'all' && value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="12">
              <div className="foot-row">
                {isTeamViewer ? (
                  selectedHost?.value === 'all' ? (
                    <>
                      <div className="low-availability-ex" />
                      <p>1-3 hosts available</p>
                      <div className="medium-availability-ex" />
                      <p>4-6 hosts available</p>
                      <div className="high-availability-ex" />
                      <p>7+ hosts available</p>
                    </>
                  ) : (
                    <>
                      <div className="individual-host-available-ex" />
                      <p>Host is available</p>
                      <div className="individual-host-unavailable-ex" />
                      <p>Host is not available</p>
                    </>
                  )
                ) : (
                  <>
                    <div className="selected-ex" />
                    <p>time blocks you're available</p>
                    <div className="gap" />
                    <div className="unselected-ex" />
                    <p>time blocks you're not available</p>
                  </>
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Screen;