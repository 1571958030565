import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import airtable from '../../../airtables';
import { useTranslation } from "react-i18next";

export const useSchools = () => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [school, setSchool] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (schools && schools.length) setSchool(schools[0]);
  }, [schools]) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    let sIds = null;
    if (userType === 'Team') {
      if (!userInfo.Administrator) {
        sIds = userInfo["Schools Managed"];
      }
    } else if (userType === 'Teacher') {
      if (userInfo["Cluster Leadership"]) {
        const cluster = await airtable.clusters.select(userInfo["Cluster Leadership"][0]);
        sIds = cluster["Schools"];
      } else {
        sIds = userInfo["School Name"];
      }

      if (!sIds || !sIds.length) {
        setSchools([]);
        setLoading(false)
        return;
      }
    }

    airtable.schools.getAllSchools(sIds).then(ss => {
      setSchools(ss);
      setLoading(false)
    });
  }

  const getTitle = () => {
    if (loading) return t("principal.heading");
    if (schools && schools.length > 1) return t("principal.heading-multi");
    return t("principal.heading");
  }

  const addNewTeacher = async (name, email) => {
    let t = await airtable.teachers.selectTeacherByEmail(email);

    if (t) {
      throw Error(`A teacher with ${email} already registered.`)
    }

    const cluster = await airtable.clusters.selectBySchool(school.School);
    const defaultCredits = (cluster && cluster["Default New Teacher Credits"])
      ? cluster["Default New Teacher Credits"]
      : 15;

    const res = await airtable.teachers.create({
      "Teacher Name": name,
      "Email": email,
      "School Name": [school.id],
      "Platform Logins": 0,
      "Status": "Active",
      "Created by": "Principal through Platform",
      "E-mail Reminders": ["24 hours before"]
    });

    await airtable.credit.create({
      "Teacher": [res.id],
      "Credits": defaultCredits,
      "Action": "Credits Issued by Program",
      "Display Note": "Introductory Credits - Teacher joined the Connected North Platform"
    })
    return res;
  }

  return {
    loading,
    school,
    setSchool,
    schools,
    getTitle,
    addNewTeacher,
    refresh: initialize
  }
}
