import React, { Fragment, useEffect, useState } from 'react';
import './styles.scss';
import { Button, FormGroup, Input, Label, Modal } from 'reactstrap';
import { useSelector } from 'react-redux';
import airtable from '../../../airtables';
import { useTranslation } from 'react-i18next';
import { CurrentGradeLevels } from '../../../utils/constants';
import Select from 'react-select';
import Loading from '../../../components/Loading';
import { toast } from 'react-toastify';

const FillTeacherInfoModal = ({
  onToggle,
  teacher,
  name: n,
  email: e,
  refreshPage
}) => {
  const { totalSubjects } = useSelector(state => state.appInfo);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(n);
  const [email, setEmail] = useState(e);
  const [saving, setSaving] = useState(false);
  const [schools, setSchools] = useState([]);
  const [phone, setPhone] = useState("");
  const [school, setSchool] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const { t } = useTranslation();

  const checkIsEdit = () => {
    if (!teacher) return false;

    if (n && e) {
      if (e === teacher["Email"]) return true;
      return false;
    } else return true;
  }

  useEffect(() => {
    const ie = checkIsEdit()
    setIsEdit(ie);

    airtable.schools.getAllSchools().then(scs => {
      setSchools(scs)

      if (teacher && ie) {
        setName(teacher["Teacher Name"] || "");
        setEmail(teacher["Email"] || "");
        setPhone(teacher["Personal Phone"] || "");

        const sch = scs.find(s => s.id === teacher["School Name"][0]);
        if (sch) setSchool({ value: sch.id, label: sch.School })

        setSubjects(teacher["Subject(s) Taught"] || []);
        setGrades(teacher["Grade(s) Taught"] || []);
      }

      setLoading(false);
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const editTeacher = () => {
    setIsEdit(true);

    setName(teacher["Teacher Name"] || "");
    setEmail(teacher["Email"] || "");
    setPhone(teacher["Personal Phone"] || "");

    const sch = schools.find(s => s.id === teacher["School Name"][0]);
    if (sch) setSchool({ value: sch.id, label: sch.School })

    setSubjects(teacher["Subject(s) Taught"] || []);
    setGrades(teacher["Grade(s) Taught"] || []);
  }

  const toggleSubjects = (subject) => {
    if (saving) return;

    const pos = subjects.indexOf(subject.id);
    if (pos < 0) {
      const newSubs = [...subjects, subject.id];
      setSubjects(newSubs);
    } else {
      const newSubs = subjects.filter(item => item !== subject.id);
      setSubjects(newSubs);
    }
  }

  const toggleGrades = (grade) => {
    if (saving) return;

    const pos = grades.indexOf(grade);
    if (pos < 0) {
      const newGrades = [...grades, grade];
      setGrades(newGrades);
    } else {
      const newGrades = grades.filter(g => g !== grade);
      setGrades(newGrades);
    }
  }

  const saveTeacher = async () => {
    setSaving(true);
    if (!isEdit) {
      let teacher = await airtable.teachers.getTeacherByEmail(email);
      if (teacher) {
        toast.info(`A teacher with ${email} already registered.`);
        setSaving(false);
        return;
      } else {
        const cluster = await airtable.clusters.selectBySchool(school.label);
        const defaultCredits = (cluster && cluster["Default New Teacher Credits"])
          ? cluster["Default New Teacher Credits"]
          : 15;
          
        const res = await airtable.teachers.create({
          "Teacher Name": name,
          "Email": email,
          "School Name": [school.value],
          "Personal Phone": phone,
          "Subject(s) Taught": subjects,
          "Grade(s) Taught": grades,
          "Self Signup": true,
          "Platform Logins": 0,
          "Created by": "Lead through Platform",
          "Status": "Active",
          "E-mail Reminders": ["24 hours before"]
        });

        await airtable.credit.create({
          "Teacher": [res.id],
          "Credits": defaultCredits,
          "Action": "Credits Issued by Program",
          "Display Note": "Introductory Credits - Teacher joined the Connected North Platform"
        })
      }
    } else {
      await airtable.teachers.update(teacher.id, {
        "Teacher Name": name,
        "Email": email,
        "School Name": [school.value],
        "Personal Phone": phone,
        "Subject(s) Taught": subjects,
        "Grade(s) Taught": grades,
        "Status": "Active",
      });
    }
    refreshPage();
    onToggle();
  }

  const renderContent = () => {
    if (loading) return (
      <div style={{ width: '100%' }} align="center">
        <Loading size={30} />
      </div>
    )

    return (
      <Fragment>
        <div className="warning">
          {(teacher && teacher.Email === e) && (
            <span>This teacher is already added to our platform. Edit their profile below:</span>
          )}
          {(teacher && n && e && !isEdit) && (
            <span>A teacher with this name exists, teaching at {teacher["School Name Text"][0]} with e-mail address {teacher["Email"]}. Please confirm this is not the same person before proceeding to add their new record! Or, you can <span
              className="edit-teacher-link"
              onClick={() => editTeacher()}
            >edit that teacher here.</span></span>
          )}
        </div>

        <FormGroup className="registering-item">
          <Label for="name">Name</Label>
          <Input
            id="name"
            className="form-style"
            placeholder={t("full-name")}
            value={name}
            readOnly
          />
        </FormGroup>
        <FormGroup className="registering-item">
          <Label for="email">Email</Label>
          <Input
            id="email"
            className="form-style"
            placeholder={t("email")}
            value={email}
            readOnly
          />
        </FormGroup>
        <FormGroup className="registering-item">
          <Label for="phone">Mobile Phone Number (optional)</Label>
          <Input
            id="phone"
            className="form-style"
            placeholder={t("phone-number")}
            defaultValue={phone}
            onChange={e => setPhone(e.target.value)}
            disabled={saving}
          />
        </FormGroup>

        <FormGroup className="registering-item">
          <Label for="school">Select their School</Label>
          <Select
            className="form-style"
            value={school}
            placeholder="Which School do they teach at?"
            onChange={(selectedOption) => setSchool(selectedOption)}
            options={schools.map(school => ({ value: school.id, label: school.School }))}
            disabled={saving}
          />
        </FormGroup>

        <FormGroup className="registering-item">
          <Label for="subjects">Subject(s) Taught</Label>
          <div className="flex-wrap-container">
            {
              totalSubjects
                .filter(sub => sub.Subject !== "Premium Session" && sub.Subject !== "Therapy (SLP/OT)" && sub.Subject !== "Holiday Themed")
                .map((sub, i) => (
                  <div
                    className={subjects.indexOf(sub.id) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                    key={i}
                    onClick={() => toggleSubjects(sub)}
                  ><span>{sub.Subject}</span></div>
                ))
            }
          </div>
        </FormGroup>

        <FormGroup className="registering-item">
          <Label for="grades">Grade(s) Taught</Label>
          <div className="flex-wrap-container">
            {
              CurrentGradeLevels.map((grade, i) => (
                <div
                  className={grades.indexOf(grade) >= 0 ? "multicheck-item item-checked" : "multicheck-item"}
                  key={i}
                  onClick={() => toggleGrades(grade)}
                ><span>{grade}</span></div>
              ))
            }
          </div>
        </FormGroup>
      </Fragment>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Complete or Edit Teacher Information</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {renderContent()}
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >Cancel</Button>
        <Button
          color="success"
          type="button"
          onClick={saveTeacher}
        >{saving && (<Loading size={20} />)}{isEdit ? "Update Teacher" : "Add Teacher"}</Button>
      </div>
    </Modal>
  )
}

export default FillTeacherInfoModal;
