import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { getPDFFiles } from "../constants/pdf-files";
import { StatusLabel } from "./StatusLabel";
import Menu from "./Menu";

export const PDPlanLayout = ({
	children,
	onSubmitPlan = () => {},
	disableSubmit = true,
	renderSubmit = false,
	reviewerMode = false,
	teacherName = "",
	planStatus = "",
	userInfo,
}) => {
	const { t } = useTranslation();
	const pdfFiles = getPDFFiles(userInfo);

	return (
		<Box
			className="main-container"
			id="pd-plan"
			sx={{
				fontFamily: "inherit",
				color: "#0D1120",
				paddingBottom: "3rem",
				h2: {
					fontWeight: "bold",
					fontSize: "1.8rem",
					lineHeight: "1.3",
				},
				"*, h1, h2, h3, h4, p, button": {
					fontFamily: "inherit",
				},
				"> .MuiPaper-root": {
					margin: "3.5rem 0",
				},
				"button:focus, a[href]:focus": {
					textDecoration: "underline",
					outline: "solid 0.15rem #0e6ba8",
					outlineOffset: "0.1rem",
				},
			}}
		>
			<Box component="section">
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: "3rem",
						gap: "2rem",
					}}
				>
					<Typography
						variant="h3"
						component="h1"
						sx={{
							fontSize: "2.5rem",
							lineHeight: "1.5",
						}}
					>
						{`${reviewerMode ? `${teacherName}'s` : "My"} PD Plan`}
					</Typography>
					<Menu
						menuItems={pdfFiles}
						title={t("pd-plan.download-pdfs")}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "space-between",
						marginBottom: "3rem",
						gap: "2rem",
					}}
				>
					<Box maxWidth="48rem">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
								marginBottom: "1rem",
								gap: "3rem",
							}}
						>
							<Typography variant="h5" component="h2">
								2024-2025
							</Typography>
							<StatusLabel label={planStatus} />
						</Box>
						<Typography variant="body1" component="p">
							Based on the PD Framework, this tool allows you to create a personalized plan for your professional development, through developing and sharing goals with your school leader. During the school year, you will be able to share feedback on your progress during the three review cycles, and update your plan as needed.
						</Typography>
					</Box>
					<Box>
						{renderSubmit && (
							<Button
								color="primary"
								onClick={onSubmitPlan}
								disabled={disableSubmit}
							>
								Submit Plan
							</Button>
						)}
					</Box>
				</Box>
			</Box>
			{children}
		</Box>
	);
};
