import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import { Checkbox, FormControlLabel } from '@mui/material';
import RenderSchoolSessions from '../SessionList';
import { useUpcoming } from './useUpcoming';
import DatePicker from '../../../components/DatePicker';
import classNames from 'classnames';
import Select from 'react-select';
import { FILTER_SELECTBOX_STYLE } from '../../../utils/constants';

const Screen = () => {
  const { t } = useTranslation()
  const {
    schools,
    data,
    filterSchool,
    setFilterSchool,
    filterKeyword,
    setFilterKeyword,
    filterDate,
    setFilterDate,
    filterCILC,
    setFilterCILC,
    refresh
  } = useUpcoming();
  const [activeTab, setActiveTab] = useState('upcoming')

  return (
    <div className="main-container upcoming">
      <h1>{t('my-sessions')}</h1>
      <div className='filter-conditions'>
        <Row>
          <Col lg={4} md={6} sm={12} className='filter-col'>
            <Input
              type="text"
              className="form-style"
              placeholder={t("search-by-name")}
              value={filterKeyword}
              onChange={e => setFilterKeyword(e.target.value)}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className='filter-col-selector'>
            <Select
              value={filterSchool ? { label: filterSchool.School, value: filterSchool.id } : { label: "All Schools", value: "" }}
              placeholder="Select School"
              styles={FILTER_SELECTBOX_STYLE}
              className="form-style"
              onChange={(selectedOption) => setFilterSchool(selectedOption)}
              options={[
                { label: "All Schools", value: "" },
                ...schools.map(sc => ({ value: sc.id, label: sc.School }))
              ]}
            />
          </Col>
          <Col lg={2} md={6} sm={12} className='filter-col'>
            <DatePicker
              value={filterDate}
              inputProps={{
                placeholder: "Session Date",
              }}
              timeFormat={false}
              onChange={(e) => {
                if (typeof e !== 'string') setFilterDate(e.toDate());
              }}
              clearDate={() => setFilterDate(null)}
            />
          </Col>
          <Col lg={1} md={3} sm={12} className='filter-col'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterCILC ? true : false}
                  onChange={e => setFilterCILC(e.target.checked)}
                />
              }
              label="CILC"
            />
          </Col>
          <Col lg={1} md={3} sm={12} className='filter-col'>
            <Button
              color="primary"
              size='sm'
              onClick={() => refresh()}
            >
              Filter
            </Button>
          </Col>
        </Row>
      </div>
      <Nav tabs className="upcoming-tabs">
        {data.map((tabData, i) => (
          <NavItem key={i}>
            <NavLink
              className={classNames({ active: activeTab === tabData.name })}
              onClick={() => setActiveTab(tabData.name)}
            >
              {tabData.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data.map((tabData, i) => (
          <TabPane tabId={tabData.name} key={i}>
            <RenderSchoolSessions
              loading={tabData.loading}
              sessions={tabData.sessions}
              type={tabData.type}
              noMessage={t("no-upcoming-sessions")}
              refreshPage={refresh}
            />
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default Screen
