import AirtableTable from "../AirtableTable";

export class TeamTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Team")

    this.fields = {
      "Name": "fldhEHQh8CWPyCARv",
      "Email": "fldhk8P5AEPuCHSJm",
      "Schools": "fldsM7aYLvbj75btl",
      "Sessions": "fldvgnaTCbktpAdxz",
      "Primary Session Host": "fldWPH0DVsQVHXHYQ",
      "Schools Managed": "fldw9lGlTsJBlKwCS",
      "Time Zone": "fldT3DnVu14zF7Mzm", 
      "Availability": "fldrn87ZbmNDehP7K"
    }
  }

  getTeamByEmail = (email) => {
    const em = email.toUpperCase();
    return this.selectOneByCondition({
      filterByFormula: `"${em}" = UPPER({Email})`
    })
  }

  getAllTeams = () => {
    return this.list({
      sort: [
        { field: 'Name', direction: 'asc' }
      ],
      filterByFormula: `{Status} != 'Alumni'`
    });
  }

  getPrimarySessionHost = () => {
    return this.list({
      sort: [
        { field: 'Name', direction: 'asc' }
      ],
      filterByFormula: `AND({Primary Session Host} = TRUE(), {Status} != 'Alumni')`
    });
  }

  getContractSessionHosts = () => {
    return this.list({
      sort: [
        { field: 'Name', direction: 'asc' }
      ],
      filterByFormula: `AND(Status = 'Session Host', {Host Email} != '')`
    });
  }

  getNonPrimarySessionHost = () => {
    return this.list({
      sort: [
        { field: 'Name', direction: 'asc' }
      ],
      filterByFormula: `AND({Primary Session Host} != TRUE(), {Status} != 'Alumni')`
    });
  }

  getAllTeamsWithPagination = (params, listener) => {
    const { pageSize } = params;
    return this.listItems({
      filterByFormula: `{Status} != 'Alumni'`,
      sort: [{ field: 'Name', direction: 'asc' }],
      pageSize: pageSize
    }, listener);
  }

  getAvailableSessionHosters = () => {
    return this.list({
      sort: [
        { field: 'Name', direction: 'asc' }
      ],
      filterByFormula: `AND({Status} = 'Session Host', {Availability} != '')`
    });
  }
}