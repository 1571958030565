export const getSession = (sessionId) => {
    var sess = window.localStorage.getItem(sessionId);
    if (sess && sess !== "undefined") {
        return JSON.parse(sess)
    } else {
        return null;
    }
}

export const setSession = (sessionId, data) => {
    window.localStorage.setItem(sessionId, JSON.stringify(data));
    return true;
}

export const removeSession = (sessionId) => {
    window.localStorage.removeItem(sessionId);
    return true;
}

export const removeAllSession = async () => {
    window.localStorage.clear();
}

export const compareGrade = (a, b) => {
    if (a === 'K') return -1;
    if (b === 'K') return 1;
}

export const sortGrades = (grades) => {
    if (!grades) return [];
    const newGrades = [...grades];
    newGrades.forEach((g, i) => {
        const v = parseInt(g);
        newGrades[i] = isNaN(v) ? g : v;
    });
    if (!newGrades) return [];
    newGrades.sort((a, b) => {
        if (typeof a === 'number' && typeof b === 'number') return a - b;
        if (typeof a === 'number' && typeof b === 'string') return 1;
        if (typeof b === 'number' && typeof a === 'string') return -1;
        return a.localeCompare(b);
    });
    return newGrades;
}

export const compareGrades = (grades1, grades2) => {
    for (let i = 0; i < grades1.length; i++) {
        if (i >= grades2.length) return 1;
        const a = parseInt(grades1[i]), b = parseInt(grades2[i]);
        if (isNaN(a) && !isNaN(b)) return -1;
        if (!isNaN(a) && isNaN(b)) return 1;
        if (isNaN(a) && isNaN(b)) return grades1[i].localeCompare(grades2[i])
        if (grades1[i] !== grades2[i]) return grades1[i] - grades2[i];
    }
    if (grades1.legnth === grades2.length) return 0;
    return -1;
}

export const getProviderSessionIDFromSession = (session) => {
    if (session["Provider Session Record ID"]) return session["Provider Session Record ID"][0];
    if (session["Session Title"]) return session["Session Title"][0];
    return "";
}