import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useSchools } from './hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingBar from '../../components/LoadingBar';
import Select from 'react-select';
import School from './School';
import TransferCreditModal from '../modals/TransferCredit';
import SchoolCharts from './SchoolCharts';
import { Button } from 'reactstrap';
import AddTeacherModal from '../modals/AddTeacher';
import { hideLoading, showLoading } from '../../utils/loading';
import { toast } from 'react-toastify';

const Screen = () => {
  const { t } = useTranslation();
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const {
    loading: schoolLoading,
    schools,
    school,
    setSchool,
    addNewTeacher,
    refresh,
    getTitle
  } = useSchools();
  const [showTransferCreditModal, setShowTransferCreditModal] = useState(false);
  const [newTeacherModal, showNewTeacherModal] = useState(false);
  const [refreshSchool, setRefreshScool] = useState(1);

  useEffect(() => {
    if (
      userType === 'Teacher' &&
      (!userInfo["School Leader?"] && !userInfo["District Leader?"])
    ) {
      window.location.replace("/cn/dashboard");
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isMulti = false;
    if (userType === 'Team' || (schools && schools.length > 1)) isMulti = true;

    const schoolTitle = isMulti ? t("principal.heading-multi") : t("principal.heading");

    window.document.title = `${schoolTitle} - Connected North`;
  }, [schools]) // eslint-disable-line react-hooks/exhaustive-deps

  const onAddNewTeacher = async (name, email) => {
    try {
      showNewTeacherModal(false);
      showLoading("Adding...");
      const newTeacher = await addNewTeacher(name, email);
      setRefreshScool(refreshSchool + 1);
      hideLoading();

      if (newTeacher) {
        toast.success("A new teacher was created successfully!");
      }
    } catch (error) {
      const errorMsg = error?.message || "Unknown Error"
      toast.error(errorMsg);
      hideLoading()
    }
  }

  return (
    <div className="main-container">
      <div className="schools-header">
        <h1>{getTitle()}</h1>
        <div>
          {userType === 'Teacher' && userInfo["School Leader?"] && !userInfo["District Leader?"] && (
            <Button
              className="btn btn-secondary"
              onClick={() => showNewTeacherModal(true)}
            >Add Teacher</Button>
          )}
          {userType === 'Teacher' && !userInfo["District Leader?"] && (
            <a
              className="btn btn-primary"
              href="/cn/credits/earn-more"
            >{t("principal.buy-credits")}</a>
          )}
          {userType === 'Team' && (
            <button
              className="btn btn-primary"
              onClick={() => setShowTransferCreditModal(true)}
            >Credit Transfer</button>
          )}
        </div>
      </div>
      {schoolLoading && <LoadingBar />}

      {(!!schools && schools.length > 1) && (
        <div className="school-selector">
          <Select
            className="form-style"
            value={school ? { value: school.id, label: school.School } : null}
            placeholder="Select a School"
            onChange={(selectedOption) => {
              const sc = schools.find(s => s.id === selectedOption.value);
              sc && setSchool(sc);
            }}
            options={schools.map(sc => ({ value: sc.id, label: sc.School }))}
          />
        </div>
      )}

      {!!school && (
        <School
          school={{ id: school.id, name: school.School }}
          isSchoolCredit={(userType === 'Teacher' || userType === 'Team') && schools.length > 1}
          refresh={refreshSchool}
          refresher={() => setRefreshScool(refreshSchool + 1)}
        />
      )}

      {!!school && (userType === 'Team' || userInfo["District Leader?"]) && (<SchoolCharts school={school} />)}

      {(showTransferCreditModal) && (
        <TransferCreditModal
          onToggle={() => setShowTransferCreditModal(false)}
          onPost={() => refresh()}
        />
      )}

      {newTeacherModal && (
        <AddTeacherModal
          confirmBtnLabel="Save Teacher"
          onToggle={() => showNewTeacherModal(!newTeacherModal)}
          addTeacher={onAddNewTeacher}
        />
      )}
    </div>
  )
}

export default Screen;