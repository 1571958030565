import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const LanguageRevitalization = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("bestPractices.lang-revitalization.title")}</h1>
      <div className="main-content">
        <h3>{t("bestPractices.lang-revitalization.sub-title")}</h3>
        <p>{t("bestPractices.lang-revitalization.p1")}</p>
        <p>{t("bestPractices.lang-revitalization.p2")}</p>
        <p>{t("bestPractices.lang-revitalization.p3")}</p>
        <p>{t("bestPractices.lang-revitalization.p4")}</p>
        <p>
          <Trans i18nKey="bestPractices.lang-revitalization.p5" components={{ b: <b /> }} />
        </p>
        <p>
          <Trans i18nKey="bestPractices.lang-revitalization.p6" components={{ b: <b /> }} />
        </p>
        <p>{t("bestPractices.lang-revitalization.p7")}</p>

        <span style={{ textDecoration: "underline" }}>{t("bestPractices.lang-revitalization.ul-title")}</span>
        <ul>
          <li>{t("bestPractices.lang-revitalization.li1")}</li>
          <li>{t("bestPractices.lang-revitalization.li2")}</li>
          <li>{t("bestPractices.lang-revitalization.li3")}</li>
          <li>{t("bestPractices.lang-revitalization.li4")}</li>
          <li>{t("bestPractices.lang-revitalization.li5")}</li>
        </ul>
      </div>
    </div>
  );
};

export default LanguageRevitalization;