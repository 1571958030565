import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useId } from "react";

export const GoalTextField = ({
	label,
	description,
	error = false,
	...props
}) => {
	const fieldId = useId();
	const errorId = `${fieldId}-error`;
	return (
		<Box mb="1.5rem">
			<Box
				mb="0.25rem"
				fontSize="1.1rem"
				component="label"
				htmlFor={fieldId}
			>
				<Typography
					variant="body1"
					fontWeight="bold"
					component="span"
					fontSize="inherit"
				>
					{`${label}: `}
				</Typography>
				<Typography variant="body1" component="span" fontSize="inherit">
					{description}
				</Typography>
			</Box>
			<Box>
				<TextField
					id={fieldId}
					aria-describedby={errorId}
					error={error}
					fullWidth
					required
					{...props}
				/>
			</Box>
			<Box id={errorId}>
				{error && (
					<Typography
						variant="body1"
						fontWeight="bold"
						color="error"
						mt="0.25rem"
						id={errorId}
					>
						{`"${label}" must not be left empty.`}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export const GoalSelectField = ({
	label,
	description,
	options = [],
	error = false,
	...props
}) => {
	const fieldId = useId();
	const errorId = `${fieldId}-error`;
	return (
		<Box mb="1.5rem">
			<Box
				mb="0.25rem"
				fontSize="1.1rem"
				component="label"
				htmlFor={fieldId}
			>
				<Typography
					variant="body1"
					fontWeight="bold"
					component="span"
					fontSize="inherit"
				>
					{`${label}: `}
				</Typography>
				<Typography variant="body1" component="span" fontSize="inherit">
					{description}
				</Typography>
			</Box>
			<Box>
				<Select
					id={fieldId}
					error={error}
					aria-describedby={errorId}
					fullWidth
					required
					{...props}
				>
					{options.map((opt) => {
						const value = typeof opt === "string" ? opt : opt.value;
						const label = typeof opt === "string" ? opt : opt.label;
						return (
							<MenuItem key={value} value={value}>
								{label}
							</MenuItem>
						);
					})}
				</Select>
			</Box>
			<Box id={errorId}>
				{error && (
					<Typography
						variant="body1"
						fontWeight="bold"
						color="error"
						mt="0.25rem"
						id={errorId}
					>
						{`"${label}" must have a selection.`}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
