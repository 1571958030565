import { getSession } from "../../../utils/session";

const isEnabledGlobally = true;

export const TEACHER_ONBOARD_ENABLED =
	"CONNECTED_NORTH_TEACHER_ONBOARD_ENABLED";

export const getIsTeacherOnboardEnabled = () =>
	isEnabledGlobally || getSession(TEACHER_ONBOARD_ENABLED);

// To test with session flag, open the browser console and run:
// window.localStorage.setItem('CONNECTED_NORTH_TEACHER_ONBOARD_ENABLED', true);
