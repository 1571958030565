import AirtableTable from "../../AirtableTable";

export class PDPlanGoalsTable extends AirtableTable {
  constructor(instance) {
    super(instance, "PD Plan Goals");

    this.fields = {
      "PD Plan": "fld4onpVcXmKVQV24",
      "Standard": "fldBXp9s3ecnKVuA5",
      "Specific": "fld3t0dWoeBb4g9w1",
      "Measurable": "fldZBDa644tUUy1Tk",
      "Achievable": "fld9enHL09zqtMSoH",
      "Relevant": "fldxtBHj0HHLCwJ9Q",
      "Time-Phased": "fldFusdPiPC5Xvchm",
      "School Supports": "fldMVGpjSorUMIrFt"
    };
  };

  getGoalsByPlanId = (planId) => {
    return this.selectByCondition({
      filterByFormula: `{PD Plan} = "${planId}"`
    })
  }

	getGoalsByIds = (goalIds = []) => {
		if (goalIds.length === 0) return [];
		const filter = goalIds.map((id) => `RECORD_ID() = "${id}"`);
		return this.list({
			filterByFormula: `OR(${filter})`,
			sort: [{ field: "ID", direction: "asc" }],
		});
	};
}
