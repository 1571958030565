import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import Badge from '@mui/material/Badge';
import SVGIcon from '../Icons/SVGIcons';

const CNNavItem = ({
  path,
  label,
  intercomLabel,
  tooltip,
  to,
  iconImg,
  icon,
  svg,
  Icon,
  hideNav,
  onClick,
  notificationNumber,
  openInNewTab // Add this new prop
}) => {
  const tooltipId = useRef(`tooltip${Math.floor(Math.random() * 100)}`).current;

  const renderIcon = () => {
    if (Icon) return Icon;
    if (icon) return <i className={icon} />
    if (iconImg) return <img src={iconImg} alt={`Connected North ${label}`} />
    if (svg) return (
      <div className="svg-icon">
        <SVGIcon name={svg} color="#1094ce" />
      </div>
    )
    return null;
  }

  const tooltipLabel = tooltip ? tooltip : label;

  if (onClick) {
    return (
      <NavItem data-intercom-target={intercomLabel}>
        <NavLink
          className="nav-link-icon"
          id={tooltipId}
          href="#"
          onClick={(e) => onClick()}
        >
          <div className="sidebar-item">
            <div className="sidebar-item-icon">
              {renderIcon()}
            </div>
            <span className="nav-link-inner--text">{label}</span>
          </div>
        </NavLink>
        <UncontrolledTooltip
          delay={0}
          target={tooltipId}
        >{tooltipLabel}</UncontrolledTooltip>
      </NavItem>
    )
  }

  const linkProps = openInNewTab
    ? { href: to, target: "_blank", rel: "noopener noreferrer" }
    : { to: path + to, tag: Link };

  return (
    <NavItem data-intercom-target={intercomLabel}>
      <NavLink
        className="nav-link-icon"
        id={tooltipId}
        onClick={hideNav}
        {...linkProps}
      >
        <div className="sidebar-item">
          <div className="sidebar-item-icon">
            {renderIcon()}
          </div>
          {notificationNumber ? (
            <Badge badgeContent={notificationNumber} color="error" variant="dot">
              <span className="nav-link-inner--text">{label}</span>
            </Badge>
          ) : (
            <span className="nav-link-inner--text">{label}</span>
          )}
        </div>
      </NavLink>
      <UncontrolledTooltip
        delay={0}
        target={tooltipId}
      >{tooltipLabel}</UncontrolledTooltip>
    </NavItem>
  )
}

export default CNNavItem;
