import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import airtable from '../../../airtables';

/**
 * @param {Teacher[]} teachers - teacher list size
 * @param {() => void} allocateModeOff - turn off mode
 */
export function useAllocation(teachers, allocateModeOff) {
	const size = teachers.length;
	const { userInfo: user } = useSelector((state) => state.appInfo);
	const initBalance = user["Credit Balance"] ?? 0;
	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [credit, setCredit] = useState(initBalance);

	// CREDIT ALLOCATION UI
	/** @type {[number[], React.Dispatch<React.SetStateAction<number[]>>]} */
	const [allocatedCredits, setAllocatedCredits] = useState([]);
	useEffect(() => {
		if (size === 0) {
			return;
		}
		const init = new Array(size).fill(0);
		setAllocatedCredits(() => init);
	}, [size]);

	/**
	 * @param {number} i - index of the teacher
	 * @return {React.ChangeEventHandler<HTMLInputElement>}
	 */
	function handleAllocation(i) {
		return (e) => {
			const v = parseInt(e.target.value);
			const ac = structuredClone(allocatedCredits);
			if (e.target.value === "" || Number.isNaN(v)) {
				ac[i] = 0;
			} else {
				ac[i] = v;
			}
			setAllocatedCredits(() => ac);
		};
	}

	useEffect(() => {
		const totalAllocated = allocatedCredits.reduce((sum, credit) => sum + credit, 0);
		setCredit(() => initBalance - totalAllocated);
	}, [allocatedCredits, initBalance]);

	// SUBMIT FORM
	/** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
	const [loading, setLoading] = useState(false);
	/** @type {[string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]} */
	const [error, setError] = useState(undefined);
	useEffect(() => {
		if (!error) {
			return;
		}
		const id = setTimeout(() => {
			setError(() => undefined);
		}, 5000);

		return () => clearTimeout(id);
	}, [error]);

	async function handleSubmit() {
		const totalAllocated = allocatedCredits.reduce((sum, credit) => sum + credit, 0);
		if (totalAllocated > initBalance) {
			setError(() => "Not enough Credits.");
			return;
		}
	
		try {
			setLoading(() => true);
			const allocations = teachers
				.map((t, i) => ({
					Teacher: [user.id],
					"Transferred To/From": [t.id],
					Credits: allocatedCredits[i],
					Action: "Credits Transferred from a Teacher",
					"Display Note": "Credits Transferred by Principal",
				}))
				.filter(allocation => allocation.Credits > 0);

			if (allocations.length > 0) {
				await airtable.credit.createMultiple(allocations);
				await new Promise(resolve => setTimeout(resolve, 5000));
			}
			
			window.location.reload();
			allocateModeOff();
		} catch (e) {
			console.error('Airtable error:', e.response?.data || e.message || e);
			setError(() => "Something went wrong. Please check the data and try again.");
		} finally {
			setLoading(() => false);
		}
	}	

	return {
		allocatedCredits,
		handleAllocation,
		credit,
		handleSubmit,
		loading,
		error,
	};
}
