import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ProviderCalendars = ({ provider }) => {
    const [calendars, setCalendars] = useState([]);

    useEffect(() => {
        const fetchCalendars = async () => {
            const url = `https://research.tigweb.org/connectednorth/provider-availability/calendars.html?provider=${provider}`;
            try {
                const response = await fetch(url);
                const data = await response.json();
                setCalendars(data);
            } catch (error) {
                console.error('Error fetching calendars:', error);
            }
        };

        fetchCalendars();
    }, [provider]);

    return (
		<>
			<option>Primary/Default</option>
			{calendars.map(calendar => (
				<option key={calendar.id} value={calendar.id}>
					{calendar.name}
				</option>
			))}
		</>
    );
};

ProviderCalendars.propTypes = {
    provider: PropTypes.string.isRequired,
};

export default ProviderCalendars;