import React, { useId } from "react";
import {
	Box,
	Typography,
	AccordionDetails,
	AccordionSummary,
	Accordion as MuiAccordion,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
/**
 * @param {object} props
 * @param {string | React.ReactNode} title
 * @param {string | React.ReactNode} status
 * @param {string | React.ReactNode} description
 * @param {React.ReactNode} children
 */

export const Accordion = ({
	title,
	status,
	description,
	children,
	component = "h4",
	...props
}) => {
	const { expandIcon, ...rest } = props;
	const id = useId();

	return (
		<MuiAccordion
			disableGutters
			elevation={0}
			sx={{
				"&:before": {
					display: "none",
				},
				borderBottom: "1px solid #000",
				"&.MuiPaper-root": {
					borderRadius: 0,
				},
			}}
			{...rest}
		>
			<AccordionSummary
				expandIcon={
					<KeyboardArrowDownRoundedIcon
						sx={{
							marginX: "2rem",
							color: "#4F4F4F",
							stroke: "#4F4F4F",
						}}
					/>
				}
				aria-controls={`panel-${id}-content`}
				id={`panel-${id}-header`}
				sx={{
					padding: "0.75rem 0",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 0.7,
					}}
				>
					<Typography
						component={component}
						sx={{
							fontSize: "1.3rem",
							fontWeight: "700",
							display: "flex",
							gap: "1rem",
						}}
					>
						{title}
						{status}
					</Typography>
					{description}
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: "0 0 1.5rem" }}>
				{children}
			</AccordionDetails>
		</MuiAccordion>
	);
};
