import React from "react";
import { Box, Paper as MuiPaper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

/**
 * @param {object} props
 * @param {string | React.ReactNode} titleComponent
 * @param {string | React.ReactNode} title
 * @param {string | React.ReactNode} introText
 * @param {React.ReactNode} children
 */

export const Paper = ({
	children,
	title,
	introText,
	titleComponent = "h3",
	...props
}) => {
	const theme = useTheme();
	return (
		<MuiPaper
			elevation={4}
			sx={{
				padding: "2.5rem 1.87rem",
				borderRadius: "1rem",
				border: "1px solid #9DE0F8",
				display: "flex",
				flexDirection: "column",
				gap: "0.5rem",
				boxShadow: "10px 12px 24px 0px rgba(206, 222, 238, 0.47)",
				marginBottom: "2.5rem",
			}}
			{...props}
		>
			<Box
				display="flex"
				flexDirection="column"
				gap="1.25rem"
				sx={{
					border: 0,
				}}
			>
				<Typography
					component={titleComponent}
					sx={{
						fontFamily: "Effra, sans-serif",
						fontSize: "1.5rem",
						fontWeight: "700",
						display: "flex",
						alignItems: "center",
						gap: "0.5rem",
					}}
				>
					{title}
				</Typography>
				<Typography
					component="p"
					sx={{
						width: "80%",
						[theme.breakpoints.down("md")]: {
							width: "100%",
						},
					}}
				>
					{introText}
				</Typography>
			</Box>
			{children}
		</MuiPaper>
	);
};
