import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import Loading from '../../../../components/Loading';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import BP2024ProgressBar from '../components/BP2024ProgressBar';
import { checkBestPractices } from '../utils';
import { Trans, useTranslation } from 'react-i18next';

const BPRoleExpectations = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [confirm, setConfirm] = useState(false);
  const [reflection, setReflection] = useState("");
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Role Expectations - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "roleExpectations");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const updateBestPractices = async () => {
    if (!confirm || !reflection) return;

    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "2024 Role Expectations Reviewed": confirm,
        "ReflectionRoles": reflection
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="main-container best-practices">
      <BP2024ProgressBar current="role" />
      <div style={{ height: 20 }} />
      <h1>{t("role-expectation.title")}</h1>
      <div className="main-content role-expectation">
        <div>
          <h3>{t("role-expectation.g1.title")}</h3>

          <p>{t("role-expectation.g1.desc")}</p>

          <div>
            <h4>{t("role-expectation.g1.sub1.title")}</h4>

            <span><b>{t("role-expectation.g1.sub1.ul1.label")}</b></span>
            <ul>
              <li><b>{t("role-expectation.g1.sub1.ul1.li1.label")}</b>
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub1.ul1.li1.li1")}</li>
                  <li>{t("role-expectation.g1.sub1.ul1.li1.li2")}</li>
                </ul>
              </li>
              <li><b>{t("role-expectation.g1.sub1.ul1.li2.label")}</b>
                <ul className="mb0">
                  <li>
                    {t("role-expectation.g1.sub1.ul1.li2.li1.label")}
                    <ul className="mb0">
                      <li>{t("role-expectation.g1.sub1.ul1.li2.li1.li1")}</li>
                    </ul>
                  </li>
                  <li>
                    {t("role-expectation.g1.sub1.ul1.li2.li2.label")}
                    <ul className="mb0">
                      <li>{t("role-expectation.g1.sub1.ul1.li2.li2.li1")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <span><b>{t("role-expectation.g1.sub1.ul2.label")}</b></span>
            <ul>
              <li><b>{t("role-expectation.g1.sub1.ul2.li1.label")}</b>
                <ul className="mb0">
                  <li>
                    <Trans
                      i18nKey="role-expectation.g1.sub1.ul2.li1.li1"
                      components={{
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        a: <a href="https://sessions.connectednorth.org/cn/best-practices/introduction" target="_blank" rel="noopener noreferrer" />
                      }}
                    />
                  </li>
                </ul>
              </li>
              <li><b>{t("role-expectation.g1.sub1.ul2.li2.label")}</b>
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub1.ul2.li2.li1")}</li>
                  <li>{t("role-expectation.g1.sub1.ul2.li2.li2")}</li>
                  <li>{t("role-expectation.g1.sub1.ul2.li2.li3")}</li>
                  <li>{t("role-expectation.g1.sub1.ul2.li2.li4.label")}
                    <ul>
                      <li>{t("role-expectation.g1.sub1.ul2.li2.li4.li1")}</li>
                      <li>{t("role-expectation.g1.sub1.ul2.li2.li4.li2")}</li>
                      <li>{t("role-expectation.g1.sub1.ul2.li2.li4.li3")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div>
            <h4>{t("role-expectation.g1.sub2.title")}</h4>

            <span><b>{t("role-expectation.g1.sub2.ul1.label")}</b></span>
            <ul>
              <li>
                <Trans
                  i18nKey="role-expectation.g1.sub2.ul1.li1.label"
                  components={{ b: <b /> }}
                />
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub2.ul1.li1.li1")}</li>
                  <li>{t("role-expectation.g1.sub2.ul1.li1.li2")}</li>
                  <li>
                    <Trans
                      i18nKey="role-expectation.g1.sub2.ul1.li1.li3"
                      components={{
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        a: <a href="https://sessions.connectednorth.org/cn/upcoming" target="_blank" rel="noopener noreferrer" />
                      }}
                    />
                  </li>
                  <li>{t("role-expectation.g1.sub2.ul1.li1.li4")}</li>
                </ul>
              </li>
              <li>
                <Trans
                  i18nKey="role-expectation.g1.sub2.ul1.li2.label"
                  components={{ b: <b /> }}
                />
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub2.ul1.li2.li1")}</li>
                  <li>{t("role-expectation.g1.sub2.ul1.li2.li2")}</li>
                </ul>
              </li>
            </ul>

            <span><b>{t("role-expectation.g1.sub2.ul2.label")}</b></span>
            <ul>
              <li>
                <Trans
                  i18nKey="role-expectation.g1.sub2.ul2.li1.label"
                  components={{ b: <b /> }}
                />
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub2.ul2.li1.li1")}</li>
                  <li>{t("role-expectation.g1.sub2.ul2.li1.li2")}</li>
                </ul>
              </li>
              <li>
                <Trans
                  i18nKey="role-expectation.g1.sub2.ul2.li2"
                  components={{ b: <b /> }}
                />
              </li>
            </ul>
          </div>

          <div>
            <h4>{t("role-expectation.g1.sub3.title")}</h4>

            <span><b>{t("role-expectation.g1.sub3.ul1.label")}</b></span>
            <ul>
              <li>{t("role-expectation.g1.sub3.ul1.li1.label")}
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub3.ul1.li1.li1")}</li>
                  <li>{t("role-expectation.g1.sub3.ul1.li1.li2")}</li>
                  <li>{t("role-expectation.g1.sub3.ul1.li1.li3.label")}
                    <ul className="mb0">
                      <li>{t("role-expectation.g1.sub3.ul1.li1.li3.li1")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>{t("role-expectation.g1.sub3.ul1.li2")}</li>
            </ul>

            <span><b>{t("role-expectation.g1.sub3.ul2.label")}</b></span>
            <ul>
              <li>{t("role-expectation.g1.sub3.ul2.li1.label")}
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub3.ul2.li1.li1.label")}
                    <ul className="mb0">
                      <li>{t("role-expectation.g1.sub3.ul2.li1.li1.li1")}</li>
                      <li>{t("role-expectation.g1.sub3.ul2.li1.li1.li2")}</li>
                      <li>{t("role-expectation.g1.sub3.ul2.li1.li1.li3")}</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>{t("role-expectation.g1.sub3.ul2.li2.label")}
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub3.ul2.li2.li1")}</li>
                  <li>{t("role-expectation.g1.sub3.ul2.li2.li2")}</li>
                  <li>{t("role-expectation.g1.sub3.ul2.li2.li3")}</li>
                  <li>{t("role-expectation.g1.sub3.ul2.li2.li4")}</li>
                </ul>
              </li>
              <li>{t("role-expectation.g1.sub3.ul2.li3.label")}
                <ul className="mb0">
                  <li>{t("role-expectation.g1.sub3.ul2.li3.li1")}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h3>{t("role-expectation.g2.title")}</h3>
          <p>
            <Trans
              i18nKey="role-expectation.g2.desc1"
              components={{
                b: <b />
              }}
            />
          </p>

          <p>
            <Trans
              i18nKey="role-expectation.g2.desc2"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href="http://link" target="_blank" rel="noopener noreferrer" />
              }}
            />
          </p>

          <div>
            <h4>{t("role-expectation.g2.sub.title")}</h4>

            <span><b>{t("role-expectation.g2.sub.ul1.label")}</b></span>
            <ul>
              <li>{t("role-expectation.g2.sub.ul1.li1")}</li>
              <li>{t("role-expectation.g2.sub.ul1.li2")}</li>
              <li>{t("role-expectation.g2.sub.ul1.li3")}</li>
            </ul>

            <span><b>{t("role-expectation.g2.sub.ul2.label")}</b></span>
            <ul>
              <li>
                <Trans
                  i18nKey="role-expectation.g2.sub.ul2.li1"
                  components={{ b: <b /> }}
                />
              </li>
              <li>{t("role-expectation.g2.sub.ul2.li2.label")}
                <ul className="mb0">
                  <li>
                    <Trans
                      i18nKey="role-expectation.g2.sub.ul2.li2.li1"
                      components={{ b: <b /> }}
                    />
                  </li>
                  <li>{t("role-expectation.g2.sub.ul2.li2.li2")}</li>
                  <li>
                    <Trans
                      i18nKey="role-expectation.g2.sub.ul2.li2.li3"
                      components={{ b: <b /> }}
                    />
                  </li>
                </ul>
              </li>
              <li>{t("role-expectation.g2.sub.ul2.li3")}</li>
            </ul>
          </div>

          <p><i>{t("role-expectation.g2.final")}</i></p>
        </div>

        <FormGroup check>
          <Input type='checkbox' id="check1" onChange={(e) => setConfirm(e.target.checked)} />
          <Label check for="check1" style={{ fontSize: 16 }}>
            <strong>{t("role-expectation.check-label")}</strong>
          </Label>
        </FormGroup>

        <div style={{ marginTop: 30 }}>
          <p>{t("role-expectation.reflect-label")}</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflection(e.target.value)}
            value={reflection}
          />
        </div>

        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || !confirm || !reflection}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;{t("role-expectation.submit")}</Button>
        </div>
      </div>
    </div>
  )
}

export default BPRoleExpectations;
