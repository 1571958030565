import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAllocation } from "../hooks";
import { CircularProgress } from "@mui/material";
import TeacherSessionsInSchoolModal from "../../modals/TeacherSessionsInSchool";
import { getIsPDPlanUser } from "../../PDPlan/util/get-is-pd-plan-user";
import Teacher from "./Teacher";

/**
 * @typedef {Object} Props
 * @property {boolean} props.allocate
 *
 * @param {Props & import("../hooks/useTeachers").UseTeacherProps} props
 * @returns {React.JSX}
 */
export function TeacherList({ allocate, onAllocateOff, loading, teachers, removeTeacher }) {
	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const { t } = useTranslation();
	const [sortKey, setSortKey] = useState("name"); // default sort by name
	const [sortOrder, setSortOrder] = useState("asc"); // can be 'asc' or 'desc'
	const {
		allocatedCredits,
		handleAllocation,
		credit,
		loading: submitLoading,
		handleSubmit,
		error,
	} = useAllocation(teachers, onAllocateOff);
	const [activeTeacherSessionsInfo, openTeacherSessionsModal] = useState(null);

	// Handler for clicking on headings to change the sorting
	const handleSort = (key) => {
		const isAsc = sortKey === key && sortOrder === "asc";
		setSortOrder(isAsc ? "desc" : "asc"); // toggle sort order
		setSortKey(key);
	};

	// Helper function to compare different data types
	const compareValues = useCallback((key, order = "asc") => {
		return (a, b) => {
			if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
				// property doesn't exist on either object
				return 0;
			}

			const varA =
				typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
			const varB =
				typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

			let comparison = 0;
			if (varA > varB) {
				comparison = 1;
			} else if (varA < varB) {
				comparison = -1;
			}
			return order === "desc" ? comparison * -1 : comparison;
		};
	}, []);

	// Function to sort teachers, memoized with useCallback
	const sortTeachers = useCallback((teachers, key, order) => {
		return [...teachers].sort(compareValues(key, order));
	}, [compareValues]);
	// State to hold the sorted list of teachers
	const [sortedTeachers, setSortedTeachers] = useState(() => sortTeachers(teachers, sortKey, sortOrder));

	// Sort the teachers array whenever sortKey or sortOrder changes
	useEffect(() => {
		setSortedTeachers(sortTeachers(teachers, sortKey, sortOrder));
	}, [teachers, sortKey, sortOrder, sortTeachers]);

	const getTeacherNo = (sortedTeacherNo) => {
		const teacher = sortedTeachers[sortedTeacherNo];
		const teacherIndex = teachers.findIndex(t => t.id === teacher.id);
		return teacherIndex
	}

	return (
		<>
			<section>
				{/* headers */}
				<div className="tableRow">
					<h4
						className="heading"
						onClick={() => handleSort("name")}
					>
						{t("principal.teachers.teacher-name")}
					</h4>
					{getIsPDPlanUser(userInfo) && (
						<h4 className="heading">
							PD Plan
						</h4>
					)}
					{userType === 'Team' && userInfo.Administrator && false && (
						<h4
							className="heading"
							onClick={() => handleSort("daysago")}
						>
							Last E-mailed
						</h4>
					)}
					<h4
						className="heading"
						onClick={() => handleSort("upcomingSessions")}
					>
						{t("principal.teachers.session-upcoming")}
					</h4>
					<h4
						className="heading"
						onClick={() => handleSort("completedSessions")}
					>
						{t("principal.teachers.session-completed")}
					</h4>
					<h4
						className="heading"
						onClick={() => handleSort("percentIndigenous")}
					>
						{t("principal.teachers.percent-indigenous")}
					</h4>
					<h4
						className="heading"
						onClick={() => handleSort("credits")}
					>
						{t("principal.teachers.credit")}
					</h4>
					<Allocate on={allocate}>
						<h4 className="heading allocateCell">
							{t("principal.teachers.allocate")}
						</h4>
					</Allocate>
				</div>

				{loading ? (
					<div className="loadingContainer">
						<CircularProgress />
					</div>
				) : (
					<ul className="principal-teacher-list">
						{sortedTeachers.map((tt, i) => (
							<Teacher
								key={i}
								teacherInfo={tt}
								even={i % 2 === 0}
								openTeacherSessionsModal={openTeacherSessionsModal}
								allocate={allocate}
								handleAllocation={handleAllocation}
								allocatedCredits={allocatedCredits}
								teacherNo={getTeacherNo(i)}
								removeTeacher={removeTeacher}
							/>
						))}
					</ul>
				)}
			</section>

			{((userType === 'Teacher' || userType === 'Team') && !!activeTeacherSessionsInfo) && (
				<TeacherSessionsInSchoolModal
					teacherInfo={activeTeacherSessionsInfo.teacherInfo}
					type={activeTeacherSessionsInfo.sessionsType}
					onToggle={() => openTeacherSessionsModal(null)}
				/>
			)}

			<Allocate on={allocate}>
				<section className="p-action">
					<h3>
						{t("principal.teachers.credit-left")}
						<span>{credit}</span>
					</h3>
					<div>
						<button
							className="btn btn-outline-primary"
							onClick={onAllocateOff}
						>
							{t("principal.teachers.cancel")}
						</button>
						<button
							className="btn btn-primary"
							onClick={handleSubmit}
							disabled={submitLoading}
						>
							{submitLoading ? (
								<>
									{t("principal.teachers.loading")}
									<span>
										<CircularProgress
											size="1em"
											sx={{
												color: "white",
												position: "relative",
												top: 4,
												marginLeft: "10px",
											}}
										/>
									</span>
								</>
							) : (
								t("principal.teachers.save")
							)}
						</button>
					</div>

					{error ? (
						<div>
							<p>{error}</p>
						</div>
					) : (
						<></>
					)}
				</section>
			</Allocate>
		</>
	);
}

/**
 * @param {Object} props
 * @param {boolean} props.on
 * @param {React.ReactNode} props.children
 */
function Allocate({ on, children }) {
	return on ? <>{children}</> : <></>;
}
