import { useLayoutEffect, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { FormGroup, Label } from "reactstrap";
import { OnboardPageInner } from "../components/OnboardPageInner";
import { StepProgress } from "../components/StepProgress";
import { useGradesSubjects } from "../hooks/use-grades-subjects";
import { getNextBackLinks } from "../utils/get-next-back-links";
import { useSelector } from "react-redux";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.gradesSubjects`;
const formId = "grades-subjects-form";

export const GradesSubjects = ({
	isNewTeacher,
	isIncompleteTeacher,
	currentRouteName,
	stepRoutes = [],
}) => {
	const { t } = useTranslation();
	const {
		formValues,
		setFormValues,
		loading,
		updateGradesSubjects,
		allGrades,
		allSubjects,
	} = useGradesSubjects();

	const { userInfo } = useSelector((state) => state.appInfo);

	const [isSubjectsRequired, setIsSubjectsRequired] = useState(false);

	const showNewTeacherFlow = isNewTeacher || isIncompleteTeacher;

	const { currentStep, backLink, nextLink } = getNextBackLinks(
		stepRoutes,
		currentRouteName,
	);

	const headingText = showNewTeacherFlow
		? t(`${pageKey}.newMainHeading`)
		: t(`${pageKey}.updateMainHeading`);

	const gradesLabel = showNewTeacherFlow
		? t(`${pageKey}.newGradesLabel`)
		: t(`${pageKey}.updateGradesLabel`);

	const subjectsLabel = showNewTeacherFlow
		? t(`${pageKey}.newSubjectsLabel`)
		: t(`${pageKey}.updateSubjectsLabel`);

	const onChangeInput = (e) => {
		const { name, value } = e.target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		updateGradesSubjects(nextLink);
	};

	useEffect(() => {
		// Determine if any selected grade requires subjects to be mandatory
		const lowerGrades = ["JK", "K", "1", "2", "3", "4", "5","SST","SSA","Learning Coach"];
		const hasHigherGrades = formValues.grades.some(
			(grade) => !lowerGrades.includes(grade),
		);
		setIsSubjectsRequired(hasHigherGrades);
	}, [formValues.grades]);

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	});

	return (
		<OnboardPageInner
			formId={formId}
			onSubmit={onSubmitForm}
			heading={<Typography variant="h1">{headingText}</Typography>}
			actions={
				<StepProgress
					formId={formId}
					backLink={backLink}
					nextLabel={
						showNewTeacherFlow
							? `${tKey}.nextButton`
							: `${tKey}.confirmButton`
					}
					currentStep={currentStep}
					totalSteps={stepRoutes.length}
					disabled={loading}
				/>
			}
		>
			<Box className="form-field-wrap">
				<FormGroup>
					<Label for="grades">{gradesLabel}:</Label>
					<Select
						id="grades"
						name="grades"
						className="form-control"
						value={formValues.grades}
						onChange={onChangeInput}
						disabled={loading}
						multiple
						required
					>
						{allGrades
							.filter(grade => 
								userInfo["School P/T Text"] === "NU" || 
								!["SSA", "SST", "Learning Coach"].includes(grade)
							)
							.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))
						}
					</Select>
				</FormGroup>
			</Box>
			<Box className="form-field-wrap">
				<FormGroup>
					<Label for="subjects">{subjectsLabel}:</Label>
					<Select
						id="subjects"
						name="subjects"
						className="form-control"
						value={formValues.subjects}
						onChange={onChangeInput}
						disabled={loading}
						multiple
						required={isSubjectsRequired}
					>
						{allSubjects.map((option) => (
							<MenuItem key={option.id} value={option.id}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormGroup>
			</Box>
		</OnboardPageInner>
	);
};
