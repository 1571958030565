import React, { useEffect, useState } from 'react';
import airtable from '../../airtables';
import "./styles.scss";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { getChatGPTResponse } from '../../libs/chatgpt';
import Loading from '../../components/Loading';
import { generateQuery } from './utils';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

const Screen = (props) => {
  const { match: { params: { pSessionId, sSessionId } } } = props;
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [pSession, setPSession] = useState(null);
  const [sSession, setSSession] = useState(null);
  const [provider, setProvider] = useState(null);
  const [formData, setFormData] = useState({
    activityType: '',
    framework: '',
    readingLevel: 'Grade 5',
    optionalText: ''
  });
  const [result, setResult] = useState("");
  const [generating, setGenerating] = useState(false);
  const [formErrors, setFormErrors] = useState({ activityType: '' });

  useEffect(() => {
    window.document.title = "Activity Generator - Connected North";

    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    setLoading(true);
    const ps = await airtable.providerSessions.select(pSessionId);
    window.document.title = `Activity Generator - ${ps["Session Title"]} - Connected North`;
    setPSession(ps);

    if (sSessionId) {
      const ss = await airtable.sessions.select(sSessionId);
      setSSession(ss);

      // Set default reading level based on the first grade
      if (ss["Grade(s)"] && ss["Grade(s)"].length > 0) {
        const firstGrade = ss["Grade(s)"][0];
        const readingLevel = firstGrade === 'K' ? 'Grade 1' : `Grade ${firstGrade}`;
        setFormData(prevData => ({
          ...prevData,
          readingLevel: readingLevel
        }));
      }
    }

    const providerId = ps["Provider"][0];
    const p = await airtable.providers.select(providerId);
    setProvider(p);

    setLoading(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const copyClipboard = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(result);
    } else {
      document.execCommand("copy", true, result);
    }
    toast.info("Copied to Clipboard!");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setResult("");
    const errors = {};
    if (!formData.activityType) {
      errors.activityType = 'The type of Activity is required';
    }

    try {
      setGenerating(true);
      if (Object.keys(errors).length === 0) {
        setFormErrors(errors);
        const { activityType, framework, readingLevel, optionalText } = formData;

        const query = generateQuery(
          provider,
          pSession,
          sSession,
          activityType,
          framework,
          readingLevel,
          optionalText
        );

        getChatGPTResponse(query, (response) => {
          setResult(response);
        }, async (response) => {
          let params = {
            "Session": [pSession.id],
            "Activity Type": activityType,
            "Activity": response
          }
          if (framework) params.Framework = framework;
          if (readingLevel) params["Reading Level"] = readingLevel;
          if (optionalText) params["Optional Context"] = optionalText;
          if (userType === 'Teacher') params.Teacher = [userInfo.id];
          if (userType === 'Team') params["Team Member"] = [userInfo.id];

          await airtable.activities.create(params);
          setGenerating(false);
        })
      } else {
        setGenerating(false);
        setFormErrors(errors);
      }
    } catch (error) {
      setGenerating(false);
    }
  };

  if (loading) {
    return (
      <div className="main-container">
        <h1>Activity Generator (BETA)</h1>
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 20 }}>
          <Loading size={32} />
        </div>
      </div>
    )
  }

  return (
    <div className="main-container">
      <h1>Activity Generator (BETA)</h1>
      <span><b>For <a href={`/cn/session/${pSession.id}`} target="_blank" rel="noopener noreferrer">{pSession["Session Title"]}</a></b></span>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={8} md={8} sm={10} xs={12}>
            <p>This feature supports educators in providing a starting point for classroom activities that could be used as a follow-up to enhance their Connected North Session experience. Select the type of Activity you'd like to generate below. You can optionally specify a reading level, knowledge framework to include, or even special context about the class to consider.</p>
            <p>Disclaimer: These activity ideas generated by a trained Artificial Intellegence model that we are still monitoring and improving. They should be reviewed and checked for accuracy, appropriateness and content warnings before being used.</p>
            <FormGroup>
              <Label for="activityType">What type of Activity would you like to create? <span className="required">*</span></Label>
              <Input
                type="select"
                name="activityType"
                id="activityType"
                value={formData.activityType}
                onChange={handleChange}
                className='form-control'
                disabled={generating}
                invalid={formErrors.activityType ? true : false}
              >
                <option></option>
                <option>5-8 Question Quiz</option>
                <option>5-8 Question Assignment</option>
                <option>Variety of Class Discussion Prompts</option>
                <option>Project-based Learning Activity</option>
                <option>Land-based Outdoor Learning Activity</option>
              </Input>
              <FormFeedback>{formErrors.activityType}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="framework">Would you like to reference a certain set of values or Indigenous knowledge framework from a region?</Label>
              <Input
                type="select"
                name="framework"
                id="framework"
                value={formData.framework}
                onChange={handleChange}
                className='form-control'
                disabled={generating}
              >
                <option></option>
                <option>Cree Mino-Pimatisiwn/Good Life Values</option>
                <option>Haida Ethics and Values</option>
                <option>Haudenosaunee/Ongweh'onweh Great Law of Peace</option>
                <option>Inuit Societal Values</option>
                <option>Seven Grandfather Teachings</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="readingLevel">Reading Level </Label>
              <Input
                type="select"
                name="readingLevel"
                id="readingLevel"
                value={formData.readingLevel}
                onChange={handleChange}
                className='form-control'
                disabled={generating}
              >
                <option></option>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => (
                  <option key={grade} value={`Grade ${grade}`}>Grade {grade}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="optionalText">Optional Context</Label>
              <Input
                id="optionalText"
                name="optionalText"
                type="textarea"
                value={formData.optionalText}
                onChange={handleChange}
                disabled={generating}
                placeholder="For example, students in this class love to learn about Whales, as they see them often in the ocean outside their school"
                className="optional-textarea"
              />
            </FormGroup>

            <FormGroup className='form-action-container'>
              <Button disabled={generating}>
                {generating ? (<Loading size={14} />) : (null)}Generate
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col lg={8} md={8} sm={10} xs={12} className='result'>
          {
            result ? (
              <Card className='openai-result-card'>
                {!generating && (
                  <div className='copy-clipboard'>
                    <Button
                      outline
                      color="success"
                      size="sm"
                      onClick={() => copyClipboard()}
                    >Copy to Clipboard</Button>
                  </div>
                )}
                <CardBody>
                  <p>{result} {generating && <span className="prompt-cursor">_</span>}</p>
                </CardBody>
              </Card>
            ) : null
          }
        </Col>
      </Row>
    </div>
  )
}

export default Screen;
