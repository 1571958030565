import airtable from "../../../airtables/PDAirtable";
import { GOAL_FIELDS, PLAN_FIELDS } from "../constants/pd-fields";

const { TEACHER } = PLAN_FIELDS;
const { PLAN, SPECIFIC } = GOAL_FIELDS;

const createPDPlan = async (teacherId) => {
	try {
		const response = await airtable.pdPlans.create({
			[TEACHER]: [teacherId],
		});
		return response;
	} catch (e) {
		console.error("Error creating PD Plan: ", e);
		return null;
	}
};

const createPDGoal = async (planId, goalName) => {
	try {
		const response = await airtable.pdPlanGoals.create({
			[SPECIFIC]: goalName,
			[PLAN]: [planId],
		});
		return response;
	} catch (e) {
		console.error("Error creating PD Goal: ", e);
		return null;
	}
};

const updatePDPlan = async (id, params) => {
	try {
		const response = await airtable.pdPlans.update(id, params);
		return response;
	} catch (e) {
		console.error("Error updating PD Plan: ", e);
		return null;
	}
};

const updatePDGoal = async (id, params) => {
	try {
		const response = await airtable.pdPlanGoals.update(id, params);
		return response;
	} catch (e) {
		console.error("Error updating PD Goal: ", e);
		return null;
	}
};

const deletePDGoal = async (id) => {
	try {
		const response = await airtable.pdPlanGoals.delete(id);
		return response;
	} catch (e) {
		console.error("Error deleting PD Goal: ", e);
		return null;
	}
};

export { createPDPlan, createPDGoal, updatePDPlan, updatePDGoal, deletePDGoal };
