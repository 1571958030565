import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Nav,
  NavItem,
  Row,
  TabContent,
  NavLink as TabNavLink,
  TabPane
} from "reactstrap";
import RightLeftIcon from '../../assets/img/icons/menu/right-left-solid.svg'
import classnames from 'classnames';
import LoadingBar from '../../components/LoadingBar';
import airtable from '../../airtables';
import { useTranslation } from 'react-i18next';
import PastSessions from '../modals/PastSessions';
import { useSelector } from 'react-redux';
import AddTeacherModal from '../modals/AddTeacher';
import FillTeacherInfoModal from '../modals/AddTeacher/complete';
import { Pagination } from '../../components/Pagination';
import useContacts from './useContacts';
import TransferCreditModal from '../modals/TransferCredit';

const Page = (props) => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [keyword, setKeyword] = useState("");

  const {
    loading: loadingTeams,
    pageCount: teamPageCount,
    contacts: teams,
    changePage: changeTeamPage,
    pageNo: teamPageNo
  } = useContacts(
    'Team',
    airtable.teams.getAllTeamsWithPagination,
    { pageSize: 10 },
    keyword
  );

  const {
    loading: loadingProviders,
    pageCount: providerPageCount,
    contacts: providers,
    changePage: changeProviderPage,
    pageNo: providerPageNo
  } = useContacts(
    'Provider',
    airtable.providers.getAllProvidersWithPagination,
    { pageSize: 10 },
    keyword
  );

  const {
    loading: loadingTeachers,
    pageCount: teacherPageCount,
    contacts: teachers,
    changePage: changeTeacherPage,
    pageNo: teacherPageNo,
    refresh: reloadTeachers
  } = useContacts(
    'Teacher',
    airtable.teachers.getAllTeachersWithPagination,
    { pageSize: 10 },
    keyword
  );

  const [activeTab, setActiveTab] = useState('Teams');
  const [showModal, setShowModal] = useState(false);
  const [activeTeacher, setActiveTeacher] = useState(null);

  const [activeTCTeacher, setActiveTCTeacher] = useState(null);
  const [showTransferCreditModal, setShowTransferCreditModal] = useState(false);

  const [showAddTeacherModal, setShowAddTeacherModal] = useState(false);
  const [nTeacher, setNTeacher] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Contacts - Connected North"

    const actionType = props.match.params.actionType || "";
    if (actionType === 'addTeacher') {
      setShowAddTeacherModal(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkNewTeacher = async (name, email) => {
    setShowAddTeacherModal(false);
    let t = await airtable.teachers.getTeacherByEmail(email);

    if (t) {
      setNTeacher({
        name, email,
        teacher: t
      })
      return;
    }

    t = await airtable.teachers.getTeachersByName(name);
    if (t) {
      setNTeacher({
        name, email,
        teacher: t
      })
      return;
    }

    setNTeacher({
      name,
      email,
      teacher: null
    })
  }

  const renderTeams = () => {
    if (!loadingTeams && (!teams || !teams.length)) return (
      <div className="nolist-placeholder" align="center">
        <span>No teams.</span>
      </div>
    );

    return (
      <Fragment>
        {loadingTeams && <LoadingBar />}
        <div>
          {teams.map((contact, index) => {
            if (contact.id === userInfo.id) return null;

            return (
              <div className="contact-item" key={index}>
                <div className="name">
                  <i className="fa fa-users" />&nbsp;&nbsp;
                  <span>{contact.Name}</span>
                </div>

                <div className="contact-info">
                  {!!contact.Phone && (
                    <>
                      <a href={"tel:" + contact.Phone}>
                        <div className="phone">
                          <i className="fa fa-phone" />&nbsp;<span>{contact.Phone}</span>
                        </div>
                      </a>
                    </>
                  )}

                  {!!contact.Email && (
                    <a href={"mailto:" + contact.Email}>
                      <div className="email">
                        <i className="fa fa-envelope" />&nbsp;<span>{contact.Email}</span>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div>
          {teamPageCount > 0 && (
            <Pagination
              page={teamPageNo}
              setPage={changeTeamPage}
              pageCount={teamPageCount}
            />
          )}
        </div>
      </Fragment>
    )
  }

  const renderTeachers = () => {
    if (!loadingTeachers && (!teachers || !teachers.length)) return (
      <div className="nolist-placeholder" align="center">
        <span>No teachers.</span>
      </div>
    );

    return (
      <Fragment>
        {loadingTeachers && <LoadingBar />}
        <div>
          {teachers.map((contact, index) => {
            if (contact.id === userInfo.id) return null;

            return (
              <div className="contact-item" key={index}>
                <div className="name">
                  <i className="fa fa-book" />&nbsp;&nbsp;
                  <span>{contact['Teacher Name']}</span>
                </div>

                <div className="contact-info">
                  <div onClick={() => {
                    setActiveTeacher(contact);
                  }}>
                    <div className="school">
                      <i className="fa-solid fa-school" />&nbsp;<span>{contact['School Name Text']}</span>
                    </div>
                  </div>

                  {contact.Phone ? (
                    <>
                      <a href={"tel:" + contact.Phone}>
                        <div className="phone">
                          <i className="fa fa-phone" />&nbsp;<span>{contact["Personal Phone"]}</span>
                        </div>
                      </a>
                    </>
                  ) : null}

                  {!!contact.Email && (
                    <a href={"mailto:" + contact.Email}>
                      <div className="email">
                        <i className="fa fa-envelope" />&nbsp;<span>{contact.Email}</span>
                      </div>
                    </a>
                  )}

                  {!!contact.Sessions && (
                    <div onClick={() => {
                      setActiveTeacher(contact);
                      setShowModal(true);
                    }}>
                      <div className="booked-sessions">
                        <i className="fa fa-video-camera" />&nbsp;<span>{contact.Sessions} session(s)</span>
                      </div>
                    </div>
                  )}
                  <div onClick={() => {
                    setActiveTCTeacher(contact);
                    setShowTransferCreditModal(true);
                  }}>
                    <div className="credits">
                      {/* <i className="fa fa-right-left" />&nbsp; */}
                      <img className="credit-right-left-icon" src={RightLeftIcon} alt="" />&nbsp;&nbsp;
                      <span>
                        {contact['Credit Balance']}{contact['23-24 Credit Allocation'] ? `/${contact['23-24 Credit Allocation']}` : ''} credits remaining
                      </span>
                    </div>
                  </div>

                  <div style={{ flex: 1 }} />

                  <Button outline color="primary" size='sm' onClick={() => {
                    setNTeacher({
                      name: '',
                      email: '',
                      teacher: contact
                    })
                  }}>
                    <i className="fa fa-edit" />&nbsp;&nbsp;Edit
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
        <div>
          {teacherPageCount > 0 && (
            <Pagination
              page={teacherPageNo}
              setPage={changeTeacherPage}
              pageCount={teacherPageCount}
            />
          )}
        </div>
        {(!!activeTCTeacher && showTransferCreditModal) && (
          <TransferCreditModal
            teacher={activeTCTeacher}
            onToggle={() => {
              setShowTransferCreditModal(false);
              setActiveTCTeacher(null);
            }}
          />
        )}
      </Fragment >
    )
  }

  const renderProviders = () => {
    if (!loadingProviders && (!providers || !providers.length)) return (
      <div className="nolist-placeholder" align="center">
        <span>No providers.</span>
      </div>
    );

    return (
      <Fragment>
        {loadingProviders && <LoadingBar />}
        <div>
          {providers.map((contact, index) => {
            if (contact.id === userInfo.id) return null;

            return (
              <div className="contact-item" key={index}>
                <div className="name">
                  <i className="fa fa-microphone" />&nbsp;&nbsp;
                  <span>{contact.Name}</span>
                </div>

                <div className="contact-info">
                  {!!contact.Phone && (
                    <a href={"tel:" + contact.Phone}>
                      <div className="phone">
                        <i className="fa fa-phone" />&nbsp;<span>{contact.Phone}</span>
                      </div>
                    </a>
                  )}

                  {!!contact.Email && (
                    <a href={"mailto:" + contact.Email}>
                      <div className="email">
                        <i className="fa fa-envelope" />&nbsp;<span>{contact.Email}</span>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <Pagination
            page={providerPageNo}
            setPage={changeProviderPage}
            pageCount={providerPageCount}
          />
        </div>
      </Fragment>
    )
  }

  return (
    <div className="main-container">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}> {/* Ensures space between header/button row and content below */}
        <h1>{t("contacts")}</h1>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <Button
            outline
            color="primary"
            size='sm'
            onClick={() => setShowAddTeacherModal(true)}
          >Add Teacher</Button>
        </div>
      </div>
      <div className='filter-conditions'>
        <Row>
          <Col lg={12} className='filter-col'>
            <Input
              type="text"
              className="form-style"
              placeholder={t("search-by-name")}
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 10 }}>
        {(userType === 'Team') && (
          <Nav tabs style={{ marginBottom: 16 }}>
            <NavItem>
              <TabNavLink
                className={classnames({ active: activeTab === 'Teams' })}
                onClick={() => setActiveTab('Teams')}
              >
                {t('teams')}
              </TabNavLink>
            </NavItem>
            <NavItem>
              <TabNavLink
                className={classnames({ active: activeTab === 'Teachers' })}
                onClick={() => setActiveTab('Teachers')}
              >
                {t('teachers')}
              </TabNavLink>
            </NavItem>
            <NavItem>
              <TabNavLink
                className={classnames({ active: activeTab === 'Providers' })}
                onClick={() => setActiveTab('Providers')}
              >
                {t('providers')}
              </TabNavLink>
            </NavItem>
          </Nav>
        )}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Teams">
            {renderTeams()}
          </TabPane>
          <TabPane tabId="Teachers">
            {renderTeachers()}
          </TabPane>
          <TabPane tabId="Providers">
            {renderProviders()}
          </TabPane>
        </TabContent>
      </div>

      {(showModal && activeTeacher) && (
        <PastSessions
          onToggle={() => {
            setShowModal(false)
            setActiveTeacher(null)
          }}
          teacherInfo={activeTeacher}
        />
      )}

      {showAddTeacherModal && (
        <AddTeacherModal
          onToggle={() => {
            setShowAddTeacherModal(!showAddTeacherModal);
            window.history.replaceState(null, '', `/cn/contact`)
          }}
          addTeacher={checkNewTeacher}
        />
      )}

      {!!nTeacher && (
        <FillTeacherInfoModal
          onToggle={() => setNTeacher(null)}
          teacher={nTeacher.teacher || null}
          name={nTeacher.name || ""}
          email={nTeacher.email || ""}
          refreshPage={() => reloadTeachers()}
        />
      )}
    </div>
  )
}

export default Page;
