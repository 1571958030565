import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const RecordingPractices = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('best-practices-content.rp.title')}</h1>

      <div className="main-content">
        <p>{t('best-practices-content.rp.para1')}</p>
        <p>{t('best-practices-content.rp.para2')}</p>
        <p>{t('best-practices-content.rp.para3')}</p>
        <p>{t('best-practices-content.rp.para4')}</p>
        <p>
          <Trans
            i18nKey="myinfo.recording-policy.message2"
            components={{
              a: (
                <a
                  href="mailto:contentproviders@connectednorth.org"
                  aria-label="E-mail Address"
                >
                  contentproviders@connectednorth.org
                </a>
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default RecordingPractices;