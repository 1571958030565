import { useEffect, useState } from "react"
import airtable from '../../../airtables';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export function useUpcoming() {
  const { t } = useTranslation()
  const { userInfo, teamSchools } = useSelector((state) => state.appInfo);

  const [upcoming, setUpcoming] = useState({
    loading: true,
    sessions: [],
    type: "Upcoming",
    name: "upcoming",
    label: t('my-upcoming'),
    noMessage: t("no-upcoming-sessions")
  });
  const [past, setPast] = useState({
    loading: true,
    sessions: [],
    type: "Past",
    name: "past",
    label: t('view-past-sessions'),
    noMessage: t("no-past-sessions")
  });

  const [filterKeyword, setFilterKeyword] = useState("")
  const [filterDate, setFilterDate] = useState(null);
  const [filterCILC, setFilterCILC] = useState(false);
  const [filterSchool, setFilterSchool] = useState(null);
  const [fresh, setfresh] = useState(0);

  useEffect(() => {
    refreshPage();
  }, [fresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = () => setfresh(fresh + 1);

  const refreshPage = async () => {
    airtable.sessions.getTeamUpcomingSessions(
      userInfo.id,
      userInfo['Name'],
      {
        filterSchool,
        filterKeyword,
        filterDate,
        filterCILC
      }
    ).then(uts => {
      setUpcoming({
        ...upcoming,
        loading: false,
        sessions: uts
      })
    });

    airtable.sessions.getTeamUpcomingSessions(
      userInfo.id,
      userInfo['Name'],
      {
        filterSchool,
        filterKeyword,
        filterDate,
        filterCILC
      },
      true
    ).then(pts => {
      setPast({
        ...past,
        loading: false,
        sessions: pts
      })
    });
  }

  const filterSchoolFromSelection = (selection) => {
    if (!selection.value) {
      setFilterSchool(null)
      return ;
    }
    const sId = selection.value;
    const schools = teamSchools.find(ts => sId === ts.id);
    setFilterSchool(schools);
  }

  return {
    schools: teamSchools,
    data: [upcoming, past],
    filterSchool,
    setFilterSchool: filterSchoolFromSelection,
    filterKeyword,
    setFilterKeyword,
    filterDate,
    setFilterDate,
    filterCILC,
    setFilterCILC,
    refresh
  }
}