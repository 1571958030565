import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import Loading from '../../../../components/Loading';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { checkBestPractices } from '../utils';
import BP2024ProgressBar from '../components/BP2024ProgressBar';

const BPRecording = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [saving, setSaving] = useState(false);
  const [allowed, setAllowed] = useState("");
  const [reflectionRecording, setReflectionRecording] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = "Recording - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "recording");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const updateBestPractices = async () => {
    if (!allowed) return;

    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "Recording Allowed": allowed,
        "ReflectionRecording": reflectionRecording
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="main-container best-practices">
      <BP2024ProgressBar current="recording" />
      <div style={{ height: 20 }} />
      <h1>{t("best-practices-content.rp.title")}</h1>
      <div className="main-content">
        <p>{t("best-practices-content.rp.para1")}</p>
        <p>{t("best-practices-content.rp.para2")}</p>
        <p>{t("best-practices-content.rp.para3")}</p>
        <p>{t("best-practices-content.rp.para4")}</p>
        <p>{t("best-practices-content.rp.para5")}</p>
      </div>

      <div className="confirm-reflection">
        <span><strong>{t("confirmation")}</strong></span><br />
        <FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="allowed"
                defaultChecked={allowed === "Yes"}
                onChange={(e) => setAllowed("Yes")}
              />{' '} {t("best-practices-content.rp.para6")}
            </Label>
          </FormGroup><br />
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="allowed"
                defaultChecked={allowed === "No"}
                onChange={(e) => setAllowed("No")}
              />{' '} {t("best-practices-content.rp.para7")}
            </Label>
          </FormGroup>
        </FormGroup>
        <p>{t("best-practices-content.rp.para8")}</p>
        <Input
          type="textarea"
          rows={6}
          onChange={e => setReflectionRecording(e.target.value)}
          defaultValue={reflectionRecording}
        />

        <div style={{ height: 20 }} />

        <Button
          className="ml-auto"
          color="primary"
          type="button"
          disabled={!allowed || saving}
          onClick={() => updateBestPractices()}
        >{saving ? (<Loading size={14} />) : (null)}{t("submit")}</Button>
      </div>

      <p style={{ marginTop: 10, fontSize: 14 }}><em>{t("bp-helprequired")}</em></p>
    </div>
  )
}

export default BPRecording;
