import { useState } from "react";
import { Box } from "@mui/material";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
	mapFieldsFromGoal,
	mapGoalFromFields,
	requiredFields,
} from "../util/handle-goal-forms";
import { MEASURABLE_VALUES } from "../constants/pd-fields";
import { Accordion } from "./Accordion";
import { ToastMessage } from "./ToastMessage";
import { GoalSelectField, GoalTextField } from "./GoalFields";
import { format, isValid, parse } from 'date-fns';

export const PlanGoal = ({
	readOnly = false,
	onUpdate,
	onDelete,
	onToggleExpand,
	expanded = false,
	goal = null,
	userInfo,
	reviewTeacherId,
	standardOptions,
	...props
}) => {
	const [goalFields, setGoalFields] = useState(mapFieldsFromGoal(goal));
	const [fieldErrors, setFieldErrors] = useState([]);

	const onUpdateGoalField = (e) => {
		if (readOnly) return;
		const { name, value } = e.target;
		setGoalFields({ ...goalFields, [name]: value });
	};

	const onSubmitGoal = async (e) => {
		e.preventDefault();
		if (readOnly) return;

		const errors = requiredFields.filter((name) => {
			const testValue =
				typeof goalFields[name] === "string"
					? goalFields[name].trim()
					: goalFields[name];
			return !testValue || testValue.length === 0;
		});

		setFieldErrors(errors);
		if (errors.length > 0) return;

		const updated = await onUpdate(goal.id, mapGoalFromFields(goalFields));
		if (updated) {
			toast.success(
				<ToastMessage>Goal was updated successfully.</ToastMessage>,
			);
		} else {
			toast.error(
				<ToastMessage>
					Goal could not be updated. Please try again.
				</ToastMessage>,
			);
		}
	};

	const formatDate = (dateString) => {
		if (!dateString || dateString.trim() === '') return 'Not Set';
		const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
		if (!isValid(parsedDate)) return 'Not Set';
		return format(parsedDate, 'MMMM d, yyyy');
	};

	return (
		<Accordion
			title={goalFields.specific}
			onChange={onToggleExpand}
			expanded={expanded}
			description={`Timeframe: ${formatDate(goalFields.timePhased)}`}
			{...props}
		>
			<Box onSubmit={onSubmitGoal} component="form">
				<Box>
					<GoalTextField
						name="specific"
						onChange={onUpdateGoalField}
						value={goalFields.specific}
						error={fieldErrors.includes("specific")}
						readOnly={readOnly}
						label="Specific"
						description="The specific developmental goal I want to achieve"
					/>
					<GoalSelectField
						name="standard"
						onChange={onUpdateGoalField}
						value={goalFields.standard}
						error={fieldErrors.includes("standard")}
						readOnly={readOnly}
						options={standardOptions}
						label="Standard"
						description="The standard this aligns with"
					/>
					<GoalTextField
						name="timePhased"
						onChange={onUpdateGoalField}
						value={goalFields.timePhased}
						error={fieldErrors.includes("timePhased")}
						readOnly={readOnly}
						label="Time-Phased"
						description="Timeframe to achieve my goal"
						type="date"
					/>
					<GoalSelectField
						name="measurable"
						onChange={onUpdateGoalField}
						value={goalFields.measurable}
						error={fieldErrors.includes("measurable")}
						readOnly={readOnly}
						options={MEASURABLE_VALUES}
						label="Measurable"
						description="Evidence to demonstrate my progression"
						multiple
					/>
					<GoalTextField
						name="achievable"
						onChange={onUpdateGoalField}
						value={goalFields.achievable}
						error={fieldErrors.includes("achievable")}
						readOnly={readOnly}
						label="Achievable"
						description="Actions to achieve my goal"
						rows="5"
						multiline
					/>
					<GoalTextField
						name="relevant"
						onChange={onUpdateGoalField}
						value={goalFields.relevant}
						error={fieldErrors.includes("relevant")}
						readOnly={readOnly}
						label="Relevant"
						description="Why this goal is relevant to  my professional growth, my school’s priorities, and my students’ outcomes"
						rows="5"
						multiline
					/>
					<GoalTextField
						name="schoolSupports"
						onChange={onUpdateGoalField}
						value={goalFields.schoolSupports}
						error={fieldErrors.includes("timePhased")}
						readOnly={readOnly}
						label="Supports"
						description="School support(s) to achieve my goal"
						rows="5"
						multiline
					/>
				</Box>
				{!readOnly && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							mt: "3rem",
						}}
					>
						<Button
							outline
							color="primary"
							onClick={() => onDelete(goal.id)}
							style={{
								paddingLeft: "1rem",
								display: "flex",
								alignItems: "center",
								gap: "0.5rem",
							}}
						>
							<DeleteForeverIcon /> Delete
						</Button>
						<Button color="primary" type="submit">
							Save
						</Button>
					</Box>
				)}
			</Box>
		</Accordion>
	);
};
