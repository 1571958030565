import { useEffect, useState } from "react";
import airtable from "../../../airtables";
import { WEEK_NAMES } from "../../../utils/constants";
import { toast } from 'react-toastify';

const useHook = (session) => {
  const [loading, setLoading] = useState(true)
  const [availableHosts, setAvailableHosts] = useState([]);
  const [unavailableHosts, setUnavailableHosts] = useState([]);
  const [saving, setSaving] = useState(false);
  const [leads, setLeads] = useState({});

  const checkInAvailability = async (hoster, tz) => {
    if (!hoster["Availability"]) return false;
    const availability = JSON.parse(hoster["Availability"]) || [];

    if (!tz) return false;
    const timeZone = tz["Connected North System"];
    const sessionDate = new Date(session["Session Start Date/Time"])
    const endDate = new Date(session["Session Start Date/Time"]);
    endDate.setMinutes(endDate.getMinutes() + session["Length (Minutes)"]);

    let options = {
      weekday: "long",
      timeZone
    };
    const weekDay = new Intl.DateTimeFormat("en-US", options).format(sessionDate);
    const weekNo = WEEK_NAMES[weekDay];
    const da = availability[weekNo];
    if (da.length === 0) return false;

    options = {
      timeZone,
      hour: "numeric",
      minute: "numeric",
      hour12: false
    }
    const startTime = new Intl.DateTimeFormat("en-US", options).format(sessionDate);
    const endTime = new Intl.DateTimeFormat("en-US", options).format(endDate);

    const dayAvailability = [];
    let subArray = [da[0]];
    for (let i = 1; i < da.length; i++) {
      if (da[i] - subArray[subArray.length - 1] === 1) {
        subArray.push(da[i])
      } else {
        dayAvailability.push([
          subArray[0],
          subArray[subArray.length - 1] + 1
        ])
        subArray = [da[i]];
      }
    }
    dayAvailability.push([
      subArray[0],
      subArray[subArray.length - 1] + 1
    ])

    for (const av of dayAvailability) {
      const range1 = `${av[0] >= 10 ? '' : '0'}${av[0]}:00`;
      const range2 = `${av[1] >= 10 ? '' : '0'}${av[1]}:00`;
      if (startTime > range1 && endTime < range2) {
        return true;
      }
    }
    return false;
  }

  const checkInSession = async (hosterInfo) => {
    const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
      new Date(session["Session Start Date/Time"]),
      session["Length (Minutes)"],
      'Team',
      hosterInfo.Name,
      hosterInfo.id
    );
    if (sessions && sessions.length) return true;
    return false;
  }

  useEffect(() => {
    if (session["Session Host(s)"] && session["Session Host(s)"].length) {
      const ls = session["Session Host(s)"].reduce((result, hostId) => {
        return {
          ...result,
          [hostId]: true
        }
      }, {});
      setLeads(ls);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialize = async () => {
    setLoading(true)
    const timezones = await airtable.timezones.listAll();
    const hosters = await airtable.teams.getAvailableSessionHosters();
    const availables = [], unavailables = [];

    for (let hoster of hosters) {
      const daySessions = await airtable.sessions.getTeamSessionsOnDay(
        hoster.id,
        hoster["Name"],
        session["Session Start Date/Time"]
      )
      const updatedHoster = {
        ...hoster,
        "bookedSessions": daySessions.length
      }
      const timeZone = timezones.find(tz => tz.id === hoster["Time Zone"][0]);

      const inAvailability = await checkInAvailability(hoster, timeZone);
      if (!inAvailability) {
        unavailables.push(updatedHoster);
        continue;
      }

      const inSession = await checkInSession(hoster);
      if (inSession) {
        unavailables.push(updatedHoster);
        continue;
      }

      availables.push(updatedHoster);
    }

    setAvailableHosts(availables);
    setUnavailableHosts(unavailables);
    setLoading(false);
  }
  const onSave = (onDone) => {
    const ls = Object.keys(leads);
    setSaving(true);

    airtable.sessions.update(session.id, { 'Session Host(s)': ls }).then(() => {
      setSaving(false)
      toast.success("Session Host is assigned successfully! They will receive an invite within 10 minutes.")
      onDone && onDone();
    }).catch(error => {
      setSaving(false)
      toast.error(error.toString());
    })
  }

  return {
    loading,
    availableHosts,
    unavailableHosts,
    leads,
    setLeads,
    saving,
    onSave
  }
}

export default useHook;