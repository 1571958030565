import React, { Fragment, useEffect, useState } from "react";
import CardsFooter from "../components/Footers/CardsFooter.js";
import { Redirect, Switch, Route } from "react-router-dom";
import {
	UpcomingStack,
	DashboardStack,
	RateStack,
	PendingStack,
	ContactStack,
	SearchStack,
	MySessionsStack,
	BrowserCurriculumStack,
	BrowserPhotoStack,
	MyInfoStack,
	FavoritesStack,
	BestPracticeStack,
	MystatsStack,
	MySettingsStack,
	SurveyStack,
	ResourceStack,
	EventsStack,
	PDWeekStack,
	CurriculumDetailStack,
	BPReflectionStack,
	BPCodeConductStack,
	CancellationReschedulePolicyStack,
	BPIntroductionStack,
	StudentInteractivityStack,
	TraumaInformedApproachStack,
	CreativeCustomizedContentStack,
	CreditStack,
	PoliciesStack,
	ChatGPTActivityStack,
	AskQuestionStack,
	SHBestPracticeStack,
	SHAvailabilityStack,
	AvailableSessionsStack,
	BP2024IntroductionStack,
	BP2024SurveyStack,
	BP2024ReflectionStack,
	BP2024RoleExpectationsStack,
	BP2024LanguageRevitalizationStack,
	BP2024CancellationReschedulePolicyStack,
	PDPlanTeacherStack,
	PDPlanReviewerStack,
	BP2024RecordingStack,
} from "./routers";
import { Container } from "reactstrap";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import airtable from "../airtables";
import { useDispatch, useSelector } from "react-redux";
import SessionDetail from "./SessionDetail";
import ProviderDetail from "./ProviderDetail";
import LogoutScreen from "./Logout.js";
import TagCollection from "./Browser/Tag/index";
import { Collections } from "./Collections";
import Principal from "./Principal";
import {
	setPresenterLoading,
	setPresenterUpcoming,
	setPresenterPast,
	setRegionsMap,
	setIsNewProvider,
	setTimezones,
	setTeamSchools,
} from "../redux/actions";
import { setPresentersForProvider, setTotalRegions } from "../redux/actions";
import { analytics } from "../firebase";
import ModalList from "./modals";
import AppSidebar from "../components/Sidebar";
import "./layout.scss";
import classNames from "classnames";
import { getDefaultUrl } from "../utils/url.js";
import { getIsPDPlanUser } from "./PDPlan/util/get-is-pd-plan-user.js";

const Page = (props) => {
	const [loading, setLoading] = useState("loading");
	const [isOpen, setIsOpen] = useState(true);
	const { userType, userInfo, isSessionHost } = useSelector(
		(state) => state.appInfo,
	);
	const { path } = props.match;
	const url = window.location.href;
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			if (userInfo) {
				setLoading("signed");
				try {
					await Promise.all([
						setUserId(analytics, userInfo["Email"]),
						setUserProperties(analytics, "user_type", userType),
						logEvent(analytics, "login", {
							email: userInfo["Email"],
							userType,
						}),
					]);
					const rs = await airtable.regions.getAllRegions();
					rs.sort((a, b) => a["Long Name"].localeCompare(b["Long Name"]));
					dispatch(setTotalRegions(rs));

					if (rs && rs.length) {
						const m = {};
						rs.forEach((r) => (m[r.id] = r));
						dispatch(setRegionsMap(m));
					}

					if (
						userType === "Provider" &&
						userInfo["Presenters"] &&
						userInfo["Presenters"].length
					) {
						const ps = await airtable.presenters.listByIds(userInfo["Presenters"]);
						dispatch(setPresentersForProvider(ps));
					}

					if (userType === "Team") {
						const tSchools = await airtable.schools.getTeamSchools(userInfo);
						dispatch(setTeamSchools(tSchools));
					}
				} catch (error) {
					console.log("firebase analytics error", error);
				}
			} else {
				setLoading("login");
			}

			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (userType === "Presenter" && userInfo) {
			if (
				userInfo["Sessions Delivered"] &&
				userInfo["Sessions Delivered"].length
			) {
				dispatch(setPresenterLoading(true));

				airtable.sessions.loadPresenterSessions(userInfo["Sessions Delivered"])
					.then(({ upcoming, past }) => {
						dispatch(setPresenterUpcoming(upcoming));
						dispatch(setPresenterPast(past));
						dispatch(setPresenterLoading(false));
					})
					.catch(error => dispatch(setPresenterLoading(false)))
			} else {
				dispatch(setPresenterLoading(false));
				dispatch(setPresenterUpcoming([]));
				dispatch(setPresenterPast([]));
			}
		}

		if (userType === "Provider" && userInfo) {
			dispatch(
				setIsNewProvider(
					userInfo.Status === "In Development" ||
					userInfo.Status === "Outreach started" ||
					userInfo["Total Delivered"] < 2,
				),
			);
		} else {
			dispatch(setIsNewProvider(false));
		}

		if (userType === 'Team') {
			airtable.timezones.listAll().then(res => {
				dispatch(setTimezones(res));
			})
		}
	}, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

	const getDefaultPage = (path) => {
		return `${path}${getDefaultUrl(userType, userInfo)}`;
	};

	if (loading === "loading") return <div>loading</div>;

	if (loading === "login") {
		return url ? (
			<Redirect to={`/login?url=${url}`} />
		) : (
			<Redirect to="/login" />
		);
	}

	if (!userInfo) return null;

	return (
		<div className={classNames({
			"layout-wrapper": true,
			"sidebar-callapsed": !isOpen
		})}>
			<AppSidebar
				path={path}
				isOpen={isOpen}
				shown={(v) => setIsOpen(v)}
				toggleNavbar={() => setIsOpen(!isOpen)}
			/>
			<main className="main-frame" id="main-frame">
				<section className="main-content-wrapper">
					<Container>
						<Switch>
							<Redirect
								from={`${path}/`}
								exact
								to={getDefaultPage(path)}
							/>

							<Route
								path={`${path}/`}
								exact
								component={
									userType === "Provider"
										? MySessionsStack
										: UpcomingStack
								}
							/>

							<Route
								path={`${path}/upcoming`}
								component={UpcomingStack}
							/>
							<Route
								path={`${path}/rate`}
								component={RateStack}
							/>
							<Route
								path={`${path}/pending`}
								component={PendingStack}
							/>
							<Route
								path={`${path}/contact/:actionType`}
								component={ContactStack}
							/>
							<Route
								path={`${path}/contact`}
								component={ContactStack}
							/>
							<Route
								path={`${path}/browser/curriculum`}
								component={BrowserCurriculumStack}
							/>
							<Route
								path={`${path}/browser/photo`}
								component={BrowserPhotoStack}
							/>
							<Route
								path={`${path}/collection/:tagId`}
								component={TagCollection}
							/>
							<Route
								path={`${path}/collection/`}
								component={Collections}
							/>
							<Route
								path={`${path}/session/:sessionId`}
								exact
								component={SessionDetail}
							/>
							<Route
								path={`${path}/provider/:providerId`}
								exact
								component={ProviderDetail}
							/>
							<Route
								path={`${path}/curriculum/:cId`}
								exact
								component={CurriculumDetailStack}
							/>
							<Route
								path={`${path}/logout`}
								exact
								component={LogoutScreen}
							/>
							<Route
								path={["/cn/aiactivities/:pSessionId", "/cn/aiactivities/:pSessionId/:sSessionId"]}
								exact
								component={ChatGPTActivityStack}
							/>
							
							{userType === "Teacher" && (
								<>
									<Route
										path={`${path}/favorites`}
										component={FavoritesStack}
									/>
									<Route
										path={`${path}/mystats`}
										component={MystatsStack}
									/>
									<Route
										path={`${path}/mysettings`}
										component={MySettingsStack}
									/>
									<Route
										path={`${path}/events`}
										component={EventsStack}
									/>
									<Route
										path={`${path}/dashboard`}
										component={DashboardStack}
									/>
									<Route
										path={`${path}/credits`}
										component={CreditStack}
									/>
									<Route
										path={`${path}/ask-a-question/:sessionId`}
										component={AskQuestionStack}
									/>
									<Route
										path={`${path}/principal`}
										component={Principal}
									/>
									
									{getIsPDPlanUser(userInfo) && (
										<>
											<Route
												path={`${path}/pd-plan/:teacherId`}
												exact
												component={PDPlanReviewerStack}
											/>
											<Route
												path={`${path}/pd-plan`}
												exact
												component={PDPlanTeacherStack}
											/>
										</>	
									)}
									{userInfo["School PT"] &&
										userInfo["School PT"][0] ===
										"recalRmA0o3OZhdlO" && (
											<Route
												path={`${path}/pd-week`}
												component={PDWeekStack}
											/>
										)}
									{!(
										userInfo["Limit Booking to Collection"] &&
										userInfo["Limit Booking to Collection"].length
									) ? (
										<Route
											path={`${path}/search`}
											component={SearchStack}
										/>
									) : null}
								</>
							)}

							{userType === "Team" && (
								<>
									<Route
										path={`${path}/events`}
										component={EventsStack}
									/>
									<Route
										path={`${path}/search`}
										component={SearchStack}
									/>
									<Route
										path={`${path}/principal`}
										component={Principal}
									/>
									<Route
										path={`${path}/ask-a-question/:sessionId`}
										component={AskQuestionStack}
									/>
									<Route
										path={`${path}/session-host/availability/:hostId?`}
										component={SHAvailabilityStack}
									/>
									{isSessionHost && (
										<Fragment>
											<Route
												path={`${path}/available-sessions`}
												component={AvailableSessionsStack}
											/>
											<Route
												path={`${path}/session-host/best-practices/:page`}
												component={SHBestPracticeStack}
											/>
										</Fragment>
									)}
								</>
							)}

							{userType === "Provider" && (
								<>
									<Route
										path={`${path}/survey`}
										component={SurveyStack}
									/>
									<Route
										path={`${path}/mysessions`}
										component={MySessionsStack}
									/>
									<Route
										path={`${path}/dashboard`}
										component={DashboardStack}
									/>
									<Route
										path={`${path}/best-practices/:page`}
										component={BestPracticeStack}
									/>
									<Route
										path={`${path}/best-practices-2023/introduction`}
										component={BPIntroductionStack}
									/>
									<Route
										path={`${path}/best-practices-2023/reflection`}
										component={BPReflectionStack}
									/>
									<Route
										path={`${path}/best-practices-2023/code-of-conduct`}
										component={BPCodeConductStack}
									/>
									<Route
										path={`${path}/best-practices-2023/cancellation-reschedule-policy`}
										component={CancellationReschedulePolicyStack}
									/>
									<Route
										path={`${path}/myinfo`}
										component={MyInfoStack}
									/>
									<Route
										path={`${path}/resources/facilitation-of-student-interactivity`}
										component={StudentInteractivityStack}
									/>
									<Route
										path={`${path}/resources/trauma-informed-approaches`}
										component={TraumaInformedApproachStack}
									/>
									<Route
										path={`${path}/resources/creatively-customized-content`}
										component={CreativeCustomizedContentStack}
									/>
									<Route
										path={`${path}/resources`}
										component={ResourceStack}
									/>
									<Route
										path={`${path}/policies`}
										component={PoliciesStack}
									/>

									{/* 2024 best practices routes */}
									<Route
										path={`${path}/best-practice/2024/introduction`}
										component={BP2024IntroductionStack}
									/>
									<Route
										path={`${path}/best-practice/2024/survey`}
										component={BP2024SurveyStack}
									/>
									<Route
										path={`${path}/best-practice/2024/reflection`}
										component={BP2024ReflectionStack}
									/>
									<Route
										path={`${path}/best-practice/2024/recording`}
										component={BP2024RecordingStack}
									/>
									<Route
										path={`${path}/best-practice/2024/language-revitalization`}
										component={BP2024LanguageRevitalizationStack}
									/>
									<Route
										path={`${path}/best-practice/2024/role-expectations`}
										component={BP2024RoleExpectationsStack}
									/>
									<Route
										path={`${path}/best-practice/2024/cancellation-reschedule-policy`}
										component={BP2024CancellationReschedulePolicyStack}
									/>
								</>
							)}

							{userType === "Presenter" && (
								<>
									<Route
										path={`${path}/best-practices/:page`}
										component={BestPracticeStack}
									/>
									<Route
										path={`${path}/myinfo`}
										component={MyInfoStack}
									/>
									<Route
										path={`${path}/resources/facilitation-of-student-interactivity`}
										component={StudentInteractivityStack}
									/>
									<Route
										path={`${path}/resources/trauma-informed-approaches`}
										component={TraumaInformedApproachStack}
									/>
									<Route
										path={`${path}/resources/creatively-customized-content`}
										component={CreativeCustomizedContentStack}
									/>
									<Route
										path={`${path}/resources`}
										component={ResourceStack}
									/>
								</>
							)}
						</Switch>
					</Container>
				</section>
				<CardsFooter />
				<ModalList />
			</main>
		</div>
	);
};

export default Page;
