import { GOAL_FIELDS } from "../constants/pd-fields";

const {
	SPECIFIC,
	STANDARD,
	TIME_PHASED,
	MEASURABLE,
	ACHIEVABLE,
	RELEVANT,
	SCHOOL_SUPPORTS,
} = GOAL_FIELDS;

export const defaultGoal = {
	specific: "",
	standard: "",
	timePhased: "",
	measurable: [],
	achievable: "",
	relevant: "",
	schoolSupports: "",
};

export const requiredFields = [
	"specific",
	"standard",
	"timePhased",
	"measurable",
	"achievable",
	"relevant",
	"schoolSupports",
];

export const mapFieldsFromGoal = (goal = {}) => ({
	specific: goal[SPECIFIC] || defaultGoal.specific,
	standard:
		goal[STANDARD] && goal[STANDARD][0]
			? goal[STANDARD][0]
			: defaultGoal.standard,
	timePhased: goal[TIME_PHASED] || defaultGoal.timePhased,
	measurable: goal[MEASURABLE] || defaultGoal.measurable,
	achievable: goal[ACHIEVABLE] || defaultGoal.achievable,
	schoolSupports: goal[SCHOOL_SUPPORTS] || defaultGoal.schoolSupports,
	relevant: goal[RELEVANT] || defaultGoal.relevant,
});

export const mapGoalFromFields = (fields = {}) => ({
	[SPECIFIC]: fields.specific.trim(),
	[STANDARD]: [fields.standard],
	[TIME_PHASED]: fields.timePhased.trim(),
	[MEASURABLE]: fields.measurable,
	[ACHIEVABLE]: fields.achievable.trim(),
	[SCHOOL_SUPPORTS]: fields.schoolSupports.trim(),
	[RELEVANT]: fields.relevant.trim(),
});
