import { Typography } from "@mui/material";

export const ToastMessage = ({ children }) => (
	<Typography
		variant="body1"
		component="p"
		sx={{ fontFamily: "effra, sans-serif", color: "#0D1120" }}
	>
		{children}
	</Typography>
);
