import React from "react";
import { MoreHoriz } from "@mui/icons-material";
import { Chip } from "@mui/material";

const getLabelColor = (label) => {
	switch (label) {
		case "Upcoming":
		case "In progress":
			return "#9DF8C7";
		case "Needs review":
			return "#C59DF8";
		case "Complete":
			return "#9DE0F8";
		case "Overdue":
			return "#EB57574A";
		default:
			return "#F2F2F2";
	}
};

export const StatusLabel = ({ label, variant = "filled", ...props }) => {
	const labelColour = getLabelColor(label);

	return (
		<Chip
			label={!label ? <MoreHoriz /> : label}
			variant={variant}
			sx={{
				backgroundColor: labelColour,
				"& .MuiChip-label": {
					fontFamily: "Effra, Roboto, sans-serif",
				},
			}}
			{...props}
		/>
	);
};
