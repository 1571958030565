import React, { useEffect, useState } from 'react';
import BPCodeConduct from './pages/codeConduct';
import CancellationReschedulePolicy from './pages/crpolicy';
import './styles.scss';
import FacilitatingSessions from './pages/facilitatingSessions';
import LanguageRevitalization from './pages/languageRevitalization';
import RecordingPractices from './pages/recordingPractices';

const Screen = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.document.title = "Policies - Connected North";
    window.addEventListener('scroll', toggleVisibility);

    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 400);
    }

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [])

  const toggleVisibility = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  function scrollToSection(containerId) {
    var element = document.getElementById(containerId);
    var headerOffset = 100;
    const scrollElement = document.getElementById("main-frame");
    scrollElement.scrollTo({
      top: element.offsetTop - scrollElement.offsetTop - headerOffset,
      behavior: "smooth"
    });
  }

  return (
    <div className="main-container">
      <h1>Policies</h1>
      <div style={{ marginTop: 10, position: "relative" }}>
        <div className="t-content">
          <div className='i-list'><i className="fa fa-list" />&nbsp;&nbsp;Table of Contents</div>
          <div className="t-content-list">
            <div className='policy'>
              <span onClick={() => scrollToSection("code-of-conduct")}>
                <i className="fa fa-angle-right i-policy" />
                {` `}
                <span>Code of Conduct</span>
              </span>
            </div>
            <div className='policy'>
              <span onClick={() => scrollToSection("cancellation-policy")}>
                <i className="fa fa-angle-right i-policy" />
                {` `}
                <span>Cancellation Policy</span>
              </span>
            </div>
            <div className='policy'>
              <span onClick={() => scrollToSection("recording-practices")}>
                <i className="fa fa-angle-right i-policy" />
                {` `}
                <span>Recording Practices</span>
              </span>
            </div>
            <div className='policy'>
              <span onClick={() => scrollToSection("facilitating-sessions")}>
                <i className="fa fa-angle-right i-policy" />
                {` `}
                <span>Facilitating Sessions - Shared Roles & Responsibilities</span>
              </span>
            </div>
            <div className='policy'>
              <span onClick={() => scrollToSection("language-revitalization")}>
                <i className="fa fa-angle-right i-policy" />
                {` `}
                <span>Indigenous Language Revitalization</span>
              </span>
            </div>
          </div>
        </div>

        <div className="policies-content">
          <div id="code-of-conduct" className="policy-item">
            <BPCodeConduct />
          </div>

          <div id="cancellation-policy" className="policy-item">
            <CancellationReschedulePolicy />
          </div>

          <div id="recording-practices" className="policy-item">
            <RecordingPractices />
          </div>

          <div id="facilitating-sessions" className="policy-item">
            <FacilitatingSessions />
          </div>

          <div id="language-revitalization" className="policy-item">
            <LanguageRevitalization />
          </div>
        </div>
      </div>

      {isVisible && (
        <div className="scroll-to-top" onClick={scrollToTop}>
          <svg height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
          </svg>
        </div>
      )}
    </div>
  )
}

export default Screen;