import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import "./styles.scss";
import Loading from "../../../components/Loading";
import airtable from "../../../airtables";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

const DynamicCollectionModal = ({ query, onToggle }) => {
  const { userInfo } = useSelector(state => state.appInfo);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [saving, setSaving] = useState(false);

  const onSave = async () => {
    setSaving(true);
    airtable.tags.create({
      Tag: title,
      Description: desc,
      "Creator on Platform": userInfo.Name,
      "Search Query": query
    }).then(res => {
      setSaving(false);
      toast.success("A new dynamic collection was created successfully.")
      onToggle();
    }).catch(error => {
      toast.error("Oops! Something went wrong.");
      setSaving(false);
    })
  }

  return (
    <Modal
      className="modal-dialog-centered dynamic-collection-modal"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">New Collection</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <label>Tag Name</label>
                <Input
                  type="text"
                  className="form-control"
                  value={title}
                  disabled={saving}
                  onChange={e => setTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Description</label>
                <Input
                  type="textarea"
                  className="form-control"
                  style={{ height: 200 }}
                  value={desc}
                  disabled={saving}
                  onChange={e => setDesc(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer" style={{ justifyContent: 'center', paddingTop: 0 }}>
        <Button
          color="primary"
          type="button"
          disabled={saving || !title || !desc}
          onClick={() => onSave()}
        >{saving && (<Loading size={14} />)} Create</Button>
        <Button
          data-dismiss="modal"
          color="outline-primary"
          type="button"
          onClick={onToggle}
        >Cancel</Button>
      </div>
    </Modal>
  )
}

export default DynamicCollectionModal;