import { useLayoutEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLink } from "../components/ArrowLink";
import {
	APP_LOGIN,
	APP_ROOT,
	ONBOARD_TEACHER_ROUTES,
	UPDATE_TEACHER_ROUTES,
} from "../routing";
import { useSelector } from "react-redux";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { getIsTeacherMissingRequiredFields } from "../utils/get-is-new-teacher";

const tKey = "teacherOnboard";
const pageKey = `${tKey}.onboardStart`;

const getIntroTextKey = (
	isNewTeacher,
	isIncompleteTeacher,
	userEmail = undefined,
	userInfo = undefined,
) => {
	if (isNewTeacher && !getIsTeacherMissingRequiredFields(userInfo)) return `${pageKey}.incompleteIntroText`;
	if (isNewTeacher && userEmail) return `${pageKey}.newIntroTextWithEmail`;
	if (isNewTeacher && !userEmail) return `${pageKey}.newIntroText`;
	if (isIncompleteTeacher) return `${pageKey}.incompleteIntroText`;
	return `${pageKey}.updateIntroText`;
};

export const OnboardStart = ({ isNewTeacher, isIncompleteTeacher }) => {
	const history = useHistory();
	const { userInfo } = useSelector((state) => state.appInfo);

	// If user already has updated settings, send them to the dashboard
	if (
		!isNewTeacher &&
		!isIncompleteTeacher &&
		userInfo[TEACHER_FIELDS.COMPLETED]
	) {
		history.push(APP_ROOT);
	}

	const { t } = useTranslation();
	const { search: searchParamsString } = useLocation();
	const searchParams = new URLSearchParams(searchParamsString);

	const email = searchParams.has("email")
		? decodeURIComponent(searchParams.get("email"))
		: undefined;

	const showNewTeacherFlow = isNewTeacher || isIncompleteTeacher;

	const headingText = t(
		showNewTeacherFlow
			? `${pageKey}.newMainHeading`
			: `${pageKey}.updateMainHeading`,
	);

	const introText = t(
		getIntroTextKey(isNewTeacher, isIncompleteTeacher, email, userInfo),
		{ email },
	);

	const nextLabel = t(
		isNewTeacher && getIsTeacherMissingRequiredFields(userInfo)
			? `${tKey}.continueRegisterButton`
			: `${tKey}.getStartedButton`,
	);

	const nextLink = isNewTeacher && getIsTeacherMissingRequiredFields(userInfo)
		? `${ONBOARD_TEACHER_ROUTES.CREATE.path}${searchParamsString}`
		: UPDATE_TEACHER_ROUTES.DETAILS.path;

	useLayoutEffect(() => {
		document.title = `${t(`${pageKey}.pageTitle`)} - ${t(
			`${tKey}.connectedNorth`,
		)}`;
	}, [t]);

	return (
		<Box className="content-wrap">
			<Typography variant="h1">{headingText}</Typography>
			<Typography variant="body1">{introText}</Typography>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				gap="5rem"
			>
				{isNewTeacher && getIsTeacherMissingRequiredFields(userInfo) && (
					<ArrowLink to={APP_LOGIN} left>
						{t(`${tKey}.returnLoginButton`)}
					</ArrowLink>
				)}
				<ArrowLink className="btn btn-primary" to={nextLink} right>
					{nextLabel}
				</ArrowLink>
			</Box>
		</Box>
	);
};
