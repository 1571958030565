import AirtableTable from "../AirtableTable";

export class ProviderSessionTable extends AirtableTable {
	constructor(instance) {
		super(instance, "Provider Sessions");

		this.fields = {
			"Title": "fldCQ9LU3bSrKeQgR",
			"Provider": "fldpzqIOCtb2C2qWt",
			"Cost": "fldOCtCGf59XpJtN4",
			"Description": "fldSzT0UTvDWFZUGW",
			"Subject": "fldPEg3lqUe8ehtfr",
			"Length (Minutes)": "fldNLB5SMTzqr2dhv",
			"Grade(s)": "fldDzcV0qLMuF4skR",
			"Well-being Link": "fldm75Kjaq6ZFEQ3t",
			// "Sessions": "fldzywTX8fBRnuN4r",
			"Language": "fldYKPITjH6XdPKB1",
			// "Tags": "fldUpHfVjn5OrpndR",
			"Materials Info": "fldb3yAhnyTW6DgnN",
			"Resource Sheets": "fld3L6r8RzibeK9wp",
			"Notes": "fld0FVNQgoHsVLLzT",
			"CILC?": "fldwvx2MxS43Hp4A0",
			"Session Title": "fld8BKuz4DHeKAFsj",
			"Provider Advance Booking Requirement": "fldFO4KNCbKExaisw",
			// "Indigenous Provider?": "fldj7sstOQRV7Wel4",
			"# Booked": "fldAfAI1wK981dPOp",
			// "Hide from Directory": "fldVY34beMQQEfhIe",   // ###
			"Status": "fldZP80B4ZAFa8tko",
			"Date Added": "fldY15uHz2kGtSVOq",
			"Curriculum Links": "fldNeOxVHnAHNUJmw",
			// "# Booked (20-21)": "fldGOjlDvviMqk5M9",
			// "Provider Logo/Photo": "fld4EjEbWXlfsb3h8",
			// "Menu Inclusion 20-21": "fldhK9esyqnxM1xAR",
			// "Provider Updates to Review": "fldr1Xeyol9leECyy",
			// "Menu Photo": "fldr9DYJSbkjhs8pb",
			"Indigenous Group(s)": "fldwTf65Yr0fEqDbi",
			// "Indigenous Communities": "fldCOCoN0dgC6e0Ay",   // ###
			"Indigenous Cultural Group(s)": "fld6C9zaaaHC7mFaJ",
			// "Team Reviewed 22-23": "fldMhmBEU1OBU0QfC",
			"Image/Photo": "fld4IE8IEuEdr0XvQ",
			"Link Title": "fldOfTHqN5fhgBcrU",
			"Link URL": "fldfhyIg89rsfUOBG",
			// "Menu section FR": "fldGQkekGewsmQhjv",
			// "Menu section ENG": "fldb32hYYrSS3f8xd",
			// "Menu Inclusion 21-22": "fldF48cZmHNmqcSDr",
			// "Team Contact": "fldJkpByddBg3ZF96",
			// "Added in design": "fldJ5YcSiSnuFWJeo",
			// "Recording Link (from Sessions)": "fldWvByDjy4NjI3Z3",
			// "New content?": "fldUFzvMSz087t5ki",
			"Supplies": "fld1cdCa7CrA2Ljrq",
			// "Supplies Required?": "fldrqNk0ZmBWNnZe3",
			// "Sent By:": "fldOUJ9EXzJ6PITZG",
			// "Sample Session": "fldmj8w6UYR9bECn4",
			// "Bilingual Session Link": "fldOVkWCQX0DYvufp",
			"Provider Name Text": "fldeLfWi5B3BOa7bQ",
			"Video Sample": "fldGZQmtjEkoiKxPI",
			// "Subject Text": "fldHHgmtUjyQy9srs",
			"Platform Link": "fldVXUIXVz9KTm39Z",
			// "Collections": "fldYJ04OOMtTfF2oA",
			// "Provider Delivered": "fldcZ6zDzdVmUUFKe",      // #####
			// "Do not request rating": "fld46463snUSjv9J6",
			// "No Recording Reminder": "fldCx01eJhNtsJzZU",
			// "Curriculum Reviewed": "fldDSucUQCtfZ3uco",
			// "# Curriculum Links": "fldjoLAMutd3KA0oh",
			"Provider Status": "flduuIe6uk8F9bTI6",
			// "Surveys": "fldw79yGTaAayjITJ",
			// "Teachers Favourited": "fldSdfY0yVRWtkWQm",
			// "Recording Allowed (from Provider)": "fldm1k3BdDmNQ0h5j",
			// "# Booked (21-22)": "fldSjjGpGI8asT5SF",
			// "Curriculum Link PTs": "fld1UJB9HCsoxvnl2",
			"Reviews": "flduRtB580ua2KPax",
			// "Spend (21-22)": "fldMCNkpmEYtAzr12",
			// "Session QR Code": "fldW8V91wg2Tw87gZ",
			// "All BP Modules Completed": "fldOgQQCOf6m4wXNS",
			// "Menu Inclusion 22-23": "fldfNO8iFm4PICyTb",
			// "Menu Description": "fldf3y8YhRSiUWq3h",
			// "Menu DLength": "fldN5sfR3B7RAIQXZ",
			// "Menu DEdited": "fld3oIZT4LdK8yeQ1",
			// "22-23 Provider Reviewed (from Provider)": "fldtc6QuaqRU4N6Mo",
			"Average Rating": "fldybO6rSJmtAst0b",
			// "Fireside Chats (from Provider)": "fldKEsoywTkSnmokT",
			// "Offered (from Provider)": "fldw5j8s33gJaAMKY",
			// "Create to Learn Series (from Provider)": "fldVV5dPnpOYosD4z",
			// "Menu Inclusion 22-23 B": "fldaDobmvRNyaYE3q",
			// "Total Delivered by Provider 21-22": "fldUjozKR7WsaWK7g",
			"Provider (FR)": "fldKXKmt7OqUTIpNQ",
			// "Menu Section 23-24": "fldMCsO3InHwHLrGV",
			// "Provider QR Code": "fldmmH6JqgQkOV3cV",
			// "# Supplies Required": "fldXgsQmE9paCLvh0",
			// "Status Changed": "fld1M6o5lrGd3VlPc",
			// "Provider Date Added": "fldqiuETAGgwZ0XQS",
			// "Fireside Chat QR Codes": "fldx8Y3HSDdoacKAt",
			// "Curriculum Mapped Last By": "fld5YPcmVni0TNw1c",
			// "Curriculum Mapped Last On": "fldv27rWZmbWwenMc",
			"Default Presenter": "fldegqW9KIYFWLBZI",
			// "Record ID": "fld9jIQ4LQcWO3gGq",
			"Flyer": "fldhqgvfwylj9dTlN",
			"# Booked (22-23)": "fldob67bHQI2x6qf6",
			// "# Rubrics (90 days)": "fldtqmkPRN0pcXDbt",
			// "Rubric Photos": "fldZloPdfZMxBLYpe",
			// "Teachers 2": "fldErn9eHHlMn4BVN",
			"Credits": "fldQeFNQogNevzZYI",
			// "Indigenous Community Type(s)": "fldrvTLwh0IsTRYDy",
			// "Featured": "fldbCajaqFkQngfwJ",
			// "Sample Clip Vimeo ID": "fldhoU2NpMV3LI0DA",
			// "Sample Clip Embed": "fldRtYOjj5UaXdbmT",
			// "Providers": "fldh1f9obbKCjF1XB",
			// "Menu Inclusion 23-24": "fld0KHl95haOADXU7",
			// "Menu Inclusion 23-24B": "fldSZrCO7KuzpqR6V",
			// "Session Sample Link": "fldSpqvfzpNwVjRDD",
			// "Session Editor (23-24)": "fldUbmQIuQkILvSeG",
			// "Name (from Session Editor (23-24))": "flddjfnNXYDrN4qNk",
			// "CILC Program ID": "fldRXRS6N0fP5iE3m",
			// "CILC Booking Link": "fldOdTsvAKk8eQ8pH",
			// "Provider CILC Profile URL": "fldpTuuaxXdq2lTiJ",
			// "CILC": "fldwyZMWaAyfsKjyH",
			// "# Rubrics (22-23)": "fld9hHm7LVZwN8dzY",
			// "Kit Quantity Remaining": "fldI7NJ2dv8WTRQkM",
			// "Status of Supplies": "fldHJAVkKZ0L8CAMp",
			// "Team Reviewed 23-24": "fldCde0mau8cjcwU9",
			"# Booked (23-24)": "flddEu4s0VuzpvBTo",
			// "# Rubrics (23-24)": "fldSQ7L4AxBsjk2B0",
			// "23-24 Provider Reviewed (from Provider)": "fldVGmy1SZMJtktFF",
			// "Supplies Cost": "fldu17EJkuV30j9Ho",
			// "Currency": "fld93dBPPmsXkqmBL",
			// "Manual Credit Adjustment": "fldspkvmL7wfz634v",
			// "Image Filename": "fldADwwmqhtgplpXJ",
			// "Indigenous Presenter?": "fldVhD4F0G3hnASHg",
			"Indigenous?": "fldNKxtD9JDfy9E9U",
			"Diversity Adjustment": "fldKPnBAfWLqSYKt7",
			"Prerequisite Session": "fldwTtlAlOnst1pcc",
			// "Similar Sessions": "fldc7iktWaEgw788r",
			// "Credits Without Supplies": "fld2spBmtxQOXsOlj",
			"Multi-class Not Allowed": "fld9Ej1VxA9DQn6pu",
			// "Multi-class Cost": "fldoIAupIkDJCQ3h6",
			// "# Provider Bookings": "flddOVg3J8T7GKWHl",
			"Optimized Image": "fld3xb8AI3fPq8puP",
			// "# Subjects": "fldwGSqEz4oxFSifE",
			// "Activities": "fld6dJtd7mwjWPeWQ",
			// "# Booked (22-23) NU": "fldcmLbuscETkguDT",
			"Credits for Supplies": "flduzRuUguXiI0AE9",
			// "AI Activities": "fldYHXHN8zCbvYR6c",
			"Primary Subject Text": "flddUmWHVTAO9HcwR",
			"Discounted Credits": "fldluRjOB1cLa4eiP",
			"Available in Events?": "fldjNdBsyQvkXgwP6",
			"Test": "fld2oZ5V9YwNP7BXv",
			// "Co-planning": "fldGLPUEcBYMDxHkj",
			"Type of Session": "fldgl2zMwRo4q5EME",
			// "# Curriculum PTs": "fldNnfcFNnhPCZg72",
			"PD Series Resources": "fldPtPy2U4EcbkpJN",
			// "Resource Videos": "fldFTRCQESyaUkba0",
			"Provider Timezone": "flds85AJ8cHpYAVMf",
			"Provider Earliest Start Time": "fldvtB6j3vFQn0Wys",
			"Autobook Provider?": "fldkjtzQ0aDH2wzUw",
			"Cluster-specific": "fldeUcK2u5gnl4KDY",
			"Cluster-specific Cluster IDs": "fldw5NYMA3vIUK7oE",
			"Cluster-specific Cluster Names": "fldvrgCL2d0xppfyn",
			"Fields of Study": "fldYKPITjH6XdPKB1"
		}
	}

	filterProviderSessions = async (
		search,
		language,
		length,
		grades,
		subjects,
		wellBeingLinks,
		indigenousGroups,
		indigenousCommunity,
		culturalGroup,
		credits,
		nosupplies,
		searchstatus,
		sort,
		userclusters = false,
		fieldsOfStudy
	) => {
		const conditions = [];

		if (search && search.trim()) {
			const kWord = search.trim().toUpperCase();

			if (kWord.indexOf(" ") > 0) {
				conditions.push(`OR(REGEX_MATCH(UPPER({Title}), "${kWord}"), REGEX_MATCH(UPPER({Description}), "${kWord}"), REGEX_MATCH(UPPER({Tags}), "${kWord}"))`);
			} else {
				conditions.push(`OR(REGEX_MATCH(UPPER({Title}), "(^|.|,| )${kWord}( |.|,|$)"), REGEX_MATCH(UPPER({Description}), "(^|.|,| )${kWord}( |,|.|$)"), REGEX_MATCH(UPPER({Tags}), "(^|.|,| )${kWord}( |,|.|$)"))`);
			}
		}

		if (length && length.length > 0) {
			var strLength = "OR(";
			length.forEach((len, i) => {
				if (i !== 0) strLength += ", ";
				if (len === 30) {
					strLength += "{Length (Minutes)} <= 30";
				} else if (len === 45) {
					strLength +=
						"AND({Length (Minutes)} > 30, {Length (Minutes)} <= 45)";
				} else if (len === 60) {
					strLength +=
						"AND({Length (Minutes)} > 45, {Length (Minutes)} <= 60)";
				}
			});
			strLength += ")";

			conditions.push(strLength);
		}

		if (grades && grades.length) {
			const cons = [];
			grades.forEach((grade, i) => {
				cons.push(`FIND(";${grade.name};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Grade(s)}), ", ", ";"), ";"))`)
			})

			conditions.push(`OR(${cons.join(", ")})`);
		}

		if (language && language.length === 1) {
			conditions.push(`FIND("${language}", {Language})`);
		}

		if (nosupplies) {
			conditions.push(`AND({Supplies} = "",{Prerequisite Session} = "")`);
		}

		if (subjects) {
			const cons = [];
			subjects.forEach((subject) => {
				let subName = subject["Subject"]
				if (subject["Subject"].indexOf(",") >= 0) subName = `"${subName}"`;
				subName = subName.replaceAll(", ", ";");

				cons.push(`FIND(';${subName};', CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Subject}), ", ", ";"), ";"))`)
			});

			conditions.push(`OR(${cons.join(", ")})`);
		}

		if (wellBeingLinks) {
			const cons = [];
			wellBeingLinks.forEach((linkItem, i) => {
				cons.push(`FIND("${linkItem.name}", {Well-being Link})`)
			});

			conditions.push(`OR(${cons.join(", ")})`);
		}

		if (credits !== undefined && credits !== null) {
			conditions.push(`{Credits} <= ${credits}`);
		}

		if (indigenousGroups && indigenousGroups.length) {
			const cons = [];
			indigenousGroups.forEach((iGroup, i) => {
				cons.push(`FIND("${iGroup.name}", {Indigenous Group(s)})`)
			});

			conditions.push(`OR(${cons.join(", ")})`);
		}

		if (indigenousCommunity) {
			conditions.push(`FIND("${indigenousCommunity}", {Indigenous Communities})`);
		}

		if (culturalGroup) {
			conditions.push(`FIND("${culturalGroup}", {Indigenous Cultural Group(s)})`);
		}

		if (searchstatus.length) {
			let ssquery = 'OR({Status} = "Active (Open for booking)",';
			searchstatus.forEach((ss, i) => {
				if (ss === 'Private') {
					ssquery += '{Status} = "Private (See notes for booking, not in directory)",';
				} else if (ss === 'In Development') {
					ssquery += '{Status} = "In Development",';
				} else if (ss === 'Consider Another Option') {
					ssquery += '{Status} = "Consider Another Option (See notes)",';
				}
			});
			// Remove the last comma and close the OR statement
			ssquery = ssquery.slice(0, -1) + ')';
			conditions.push(ssquery);
		} else {
			if (userclusters && userclusters.length > 0) {
				const clusterConditions = userclusters.map(cluster => `FIND("${cluster}", ARRAYJOIN({Cluster-specific Cluster IDs}))`).join(", ");
				conditions.push(`OR(${clusterConditions}, {Cluster-specific} = BLANK())`);
			} else {
				conditions.push('{Cluster-specific} = BLANK()');
			}
			conditions.push('{Status} = "Active (Open for booking)"');
			conditions.push('{Provider Status} = "Active"');
			conditions.push('{Hide from Directory} = FALSE()');
		}

		if (fieldsOfStudy && fieldsOfStudy.length) {
			const fosConditions = fieldsOfStudy.map(field =>
				`FIND("${field.name}", ARRAYJOIN({Related Fields of Study}))`
			);
			conditions.push(`OR(${fosConditions.join(", ")})`);
		}

		const results = await this.list({
			filterByFormula: `And(${conditions.join(", ")})`,
			sort: sort
		});

		return { results, searchTerm: search ? search.trim().toUpperCase() : null };
	};

	// New function to prioritize results
	prioritizeResults = (results, searchTerm) => {
		if (!searchTerm) return results;

		return results.sort((a, b) => {
			const aTitle = a.Title.toUpperCase();
			const bTitle = b.Title.toUpperCase();
			const aContainsSearch = aTitle.includes(searchTerm);
			const bContainsSearch = bTitle.includes(searchTerm);

			if (aContainsSearch && !bContainsSearch) return -1;
			if (!aContainsSearch && bContainsSearch) return 1;
			return 0;
		});
	};

	getProviderSessions = (pId, pName, sort, isActive) => {
		return this.list({
			filterByFormula: isActive
				? `AND(FIND("${pName}", {Provider}), {Hide from Directory} = FALSE(), {Status} = "Active (Open for booking)")`
				: `FIND("${pName}", {Provider})`,
			sort: [sort],
		}, session => (session.Provider || []).indexOf(pId) >= 0);
	};

	getUpcomingSessions = (sort) => {
		return this.list({
			filterByFormula: `AND(
				FIND("Active (Open for booking)", {Status}),
				{Hide from Directory} = FALSE(),
				{# Provider Bookings} < 12,
				{Indigenous?} = 'true'
			)`,
			sort: sort,
			maxRecords: 100,
		});
	};

	getFreeUpcomingSessions = (sort) => {
		return this.list({
			filterByFormula: `AND(
				FIND("Active (Open for booking)",{Status}),
				{Credits} = 0,
				{Hide from Directory} = FALSE(),
				{Indigenous?} = 'true'
			)`,
			sort: sort,
			maxRecords: 16,
		});
	}

	getRecommendedUpcomingSessions = (subjects, grades, sort) => {
		var filterFormula = `AND(FIND("Active (Open for booking)",{Status}), {Hide from Directory} = FALSE(), {# Provider Bookings} < 12, {Indigenous?} = 'true'`;

		if (subjects && subjects.length > 0) {
			const fSubjects = [];
			subjects.forEach(subject => {
				fSubjects.push(`FIND(";${subject};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Subject}), ", ", ";"), ";"))`)
			})
			filterFormula += `, OR(` + fSubjects.join(", ") + `)`;
		}
		if (grades && grades.length > 0) {
			const fGrades = []
			grades.forEach(grade => {
				fGrades.push(`FIND(";${grade};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Grade(s)}), ", ", ";"), ";"))`)
			})
			filterFormula += `, OR(` + fGrades.join(", ") + `)`;
		}

		filterFormula += `)`
		return this.list({
			filterByFormula: filterFormula,
			sort: sort,
			maxRecords: 16,
		});
	}

	getProviderSessionsByPhoto = (subject, grade) => {
		const cons = [
			'NOT(FIND("heic", {Image/Photo}))',
			'{Provider Delivered} = 0',
			"{Language} = 'English'"
		]

		if (subject) {
			cons.push(`FIND(";${subject};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Subject}), ", ", ";"), ";"))`)
		}

		if (grade) {
			cons.push(`FIND(";${grade};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Grade(s)}), ", ", ";"), ";"))`)
		}

		return this.getFirstPage({
			filterByFormula: `AND(${cons.join(",")})`,
			pageSize: 30,
			sort: [
				{ field: "Indigenous Provider?", direction: "desc" },
				{ field: "# Booked", direction: "desc" },
			],
		});
	};

	getActiveSessionsByIds = (ids) => {
		const filter = ids.map((id) => `RECORD_ID() = '${id}'`).join(",");
		return this.list({
			filterByFormula: `AND(
				OR(${filter}),
				{Status} = 'Active (Open for booking)'
			)`,
		});
	};

	getProviderSessionsByIds = (ids) => {
		if (!ids || ids.length === 0) return [];

		const filter = ids.map((id) => `RECORD_ID() = '${id}'`).join(",");
		return this.list({
			filterByFormula: `AND(
				{Hide from Directory} = FALSE(),
				OR(
					{Status} = "Active (Open for booking)",
					{Status} = "Consider Another Option (See notes)"
				),
				OR(${filter})
			)`,
			sort: [{ field: "Session Title", direction: "asc" }]
		});
	}

	selectSampleSession = (id) => {
		if (!id) return null;

		return this.selectOneByCondition({
			fields: ["Title", "Description"],
			filterByFormula: `RECORD_ID() = "${id}"`,
		})
	}

	getTagSessions = (tag, userclusters = false) => {
		const getDirection = (dir) => {
			if (!dir) return "asc";
			if (dir === "A-Z") return "asc";
			return "desc"
		}
		const ids = tag["Provider Sessions"] ?? [];
		if (!ids || ids.length === 0) return [];

		const filter = ids.map((id) => `RECORD_ID() = '${id}'`).join(",");

		let clusterfilter;
		if (userclusters && userclusters.length > 0) {
			const clusterConditions = userclusters.map(cluster => `FIND("${cluster}", ARRAYJOIN({Cluster-specific Cluster IDs}))`).join(", ");
			clusterfilter = `OR(${clusterConditions}, {Cluster-specific} = BLANK())`;
		} else {
			clusterfilter = '{Cluster-specific} = BLANK()';
		}

		return this.list({
			filterByFormula: `AND(OR(${filter}),${clusterfilter})`,
			sort: [{
				field: tag["Sort By"] ?? "Session Title",
				direction: getDirection(tag["Sort Direction"])
			}]
		});
	}

	selectPrerequisiteSession = (sessionTitle) => {
		return this.selectOneByCondition({
			filterByFormula: `FIND("${sessionTitle}", {Prerequisite Session})`
		})
	}
}
