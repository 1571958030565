import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import BookingsIcon from "../../assets/img/icons/menu/calendar.svg";
import BarChartIcon from "../../assets/img/icons/menu/bar-chart.svg";
import SchoolIcon from "../../assets/img/icons/menu/school.svg";
import PastIcon from "../../assets/img/icons/menu/past.svg";
import SearchIcon from "../../assets/img/icons/menu/search.svg";
import RequestIcon from "../../assets/img/icons/menu/lightbulb_hourglass.svg";
import CurriculumIcon from "../../assets/img/icons/menu/search_curriculum.svg";
import CollectionsIcon from "../../assets/img/icons/menu/collections.svg";
import FavouritesIcon from "../../assets/img/icons/menu/favourites.svg";
import EventsIcon from "../../assets/img/icons/menu/users.svg";
import ContactIcon from "../../assets/img/icons/menu/contacts.svg";
import InfoIcon from "../../assets/img/icons/menu/user.svg";
import SessionsIcon from "../../assets/img/icons/menu/lightbulb_calendar.svg";
import PoliciesIcon from "../../assets/img/icons/menu/papers.svg";
import PracticesIcon from "../../assets/img/icons/menu/award.svg";
import ResourcesIcon from "../../assets/img/icons/menu/resources.svg";
import TourIcon from "../../assets/img/icons/menu/platform_tour.svg";
import PDPlanIcon from "../../assets/img/icons/menu/pd-plan.svg";
import { getIsPDPlanUser } from "../../pages/PDPlan/util/get-is-pd-plan-user";

export const useSidebar = () => {
  const { userType, userInfo, isSessionHost } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const [navs, setNavs] = useState([]);
  const { startTour, show } = useIntercom();

  useEffect(() => {
    setNavs(getNavigations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, userType])

  const getNavigations = () => {
    switch (userType) {
      case "Teacher":
        return getTeacherNavs();
      case "Team":
        return getTeamNavs();
      case "Provider":
        return getProviderNavs();
      case "Presenter":
        return getPresenterNavs();
      default:
        return [];
    }
  }

  const getTeacherNavs = () => {
    const result = [
      { to: "/dashboard", label: t("dashboard"), iconImg: BarChartIcon, intercomLabel: "dashboard" },
      { to: "/upcoming", label: t("my-sessions"), iconImg: BookingsIcon, intercomLabel: "my-sessions" },
    ];

    if (userInfo["School Leader?"] || userInfo["District Leader?"]) {
      let title = ""
      if (
        (
          Array.isArray(userInfo["Schools Managed"]) &&
          userInfo["Schools Managed"].length > 0
        ) || (
          Array.isArray(userInfo["School Name"]) &&
          userInfo["School Name"].length > 1
        ) || userInfo["District Leader?"]
      ) {
        title = t("principal.heading-multi");
      } else {
        title = t("principal.heading");
      }
      result.push({ to: "/principal", label: title, iconImg: SchoolIcon, intercomLabel: "principal.heading" })
    }

    if (userInfo["Limit Booking to Collection"] && userInfo["Limit Booking to Collection"].length) {
      result.push({
        to: "/collection/" + userInfo["Limit Booking to Collection"][0],
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: PastIcon,
        intercomLabel: "search"
      })
    } else {
      result.push({
        to: "/search",
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: SearchIcon,
        intercomLabel: "search"
      })
    }

    result.push({ to: "/pending", label: t("requests"), tooltip: t("pending_session_requests"), iconImg: RequestIcon, intercomLabel: "requests" });
    if (userInfo["Preferred Language"] !== 'French') {
      result.push({ to: "/browser/curriculum", label: t("explore-by-curriculum"), iconImg: CurriculumIcon, intercomLabel: "explore-by-curriculum" });
      result.push({ to: "/collection", label: t("explore-by-collection"), iconImg: CollectionsIcon, intercomLabel: "explore-by-collection" });
    }
    result.push({ to: "/favorites", label: t("menu-favourites"), tooltip: t("my-favourites"), iconImg: FavouritesIcon, intercomLabel: "menu-favourites" });
    result.push({ to: "/events", label: t("events"), iconImg: EventsIcon, intercomLabel: "events" });
    
    if (getIsPDPlanUser(userInfo)) {
      result.push({ to: "/pd-plan", label: t("my-pd-plan"), iconImg: PDPlanIcon });
    }

    result.push({
      to: "/mysettings",
      label: t("my-settings"),
      svg: "setting",
      intercomLabel: "my-settings"
    })

    result.push({
      label: t("platform-tour"),
      iconImg: TourIcon,
      onClick: () => startTour(458667),
      intercomLabel: "platform-tour"
    })

    result.push({
      label: t("help-and-faqs"),
      svg: "faqs",
      onClick: () => show(),
      intercomLabel: "help-and-faqs"
    })

    return result;
  }

  const getTeamNavs = () => {
    if (isSessionHost && !userInfo["Host Training Approved"]) {
      return [{
        to: "/session-host/best-practices/introduction",
        label: "Host Training",
        iconImg: FavouritesIcon,
        intercomLabel: "Host Training"
      }];
    }

    const result = [
      { to: "/upcoming", label: t("upcoming"), tooltip: t("upcoming_sessions"), iconImg: BookingsIcon, intercomLabel: "upcoming_sessions" },
    ];
    if (!isSessionHost) {
      result.push({
        to: "/rate",
        label: t("manage-past"),
        tooltip: t("manage-past-sessions"),
        iconImg: PastIcon,
        intercomLabel: "manage-past"
      })
      result.push({
        to: "/search",
        label: t("search"),
        tooltip: t("search_sessions"),
        iconImg: SearchIcon,
        intercomLabel: "search"
      })
    }

    if (!isSessionHost && userInfo["Schools Managed"] && userInfo["Schools Managed"].length > 0) {
      const lblId = (
        (Array.isArray(userInfo["Schools Managed"]) &&
          userInfo["Schools Managed"].length > 0
        ) || (
          Array.isArray(userInfo["School Name"]) &&
          userInfo["School Name"].length > 1
        ) || userInfo["District Leader?"]
      ) ? "principal.heading-multi" : "principal.heading";

      result.push({ to: "/principal", label: t(lblId), iconImg: SchoolIcon, intercomLabel: "principal" })
    }

    if (isSessionHost) {
      result.push({
        to: "/session-host/best-practices/introduction",
        label: "Host Training",
        iconImg: FavouritesIcon,
        intercomLabel: "Host Training"
      });
      if (userInfo["Host Email"] === 'mfurdyk') {
        result.push({
          to: "/available-sessions",
          label: "Available Sessions",
          iconImg: EventsIcon,
          intercomLabel: "Available Sessions"
        });
      }
      result.push({
        to: "/session-host/availability",
        label: "My Availability",
        iconImg: InfoIcon
      });
      if (userInfo["Host Email"]) {
        result.push({
          to: "https://mail.google.com/a/connectednorth.org",
          label: "My E-mail",
          iconImg: ContactIcon,
          openInNewTab: true
        });
      }
    }

    if (!isSessionHost) {
      result.push({
        to: "/pending",
        label: t("requests"),
        tooltip: t("pending_session_requests"),
        iconImg: RequestIcon,
        intercomLabel: "requests"
      });
      result.push({
        to: "/browser/curriculum",
        label: t("explore-by-curriculum"),
        iconImg: CurriculumIcon,
        intercomLabel: "explore-by-curriculum"
      });
      result.push({
        to: "/collection",
        label: t("explore-by-collection"),
        iconImg: CollectionsIcon,
        intercomLabel: "explore-by-collection"
      });
      result.push({
        to: "/contact",
        label: t("contacts"),
        iconImg: ContactIcon,
        intercomLabel: "contacts"
      });
      result.push({
        to: "/events",
        label: t("events"),
        iconImg: EventsIcon,
        intercomLabel: "events"
      });
    }
    return result;
  }

  const getProviderNavs = () => {
    const result = [
      { to: "/upcoming", label: t("my-bookings"), tooltip: t("upcoming_sessions"), iconImg: BookingsIcon, intercomLabel: "my-bookings" },
      { to: "/pending", label: t("requests"), tooltip: t("pending_session_requests"), iconImg: RequestIcon, intercomLabel: "requests" },
      { to: "/mysessions", label: t("my-sessions"), tooltip: t("search-my-sessions"), iconImg: SessionsIcon, intercomLabel: "my-sessions" },
      { to: "/policies", label: "Policies", iconImg: PoliciesIcon, intercomLabel: "Policies" },
      { to: "/best-practices/introduction", label: t("best-practices"), iconImg: PracticesIcon, intercomLabel: "best-practices" },
      { to: "/resources", label: t("resources-title"), iconImg: ResourcesIcon, intercomLabel: "resources-title" },
      { to: "/upcoming?product_tour_id=466709", label: t("platform-tour"), iconImg: TourIcon, intercomLabel: "platform-tour" },
      { to: "/myinfo", label: t("my-info"), tooltip: t("view-edit-contact-information"), iconImg: InfoIcon, intercomLabel: "my-info" },
    ]

    return result;
  }

  const getPresenterNavs = () => {
    const result = [
      { to: "/upcoming", label: t("my-bookings"), tooltip: t("upcoming_sessions"), iconImg: BookingsIcon, intercomLabel: "my-bookings" },
      { to: "/best-practices/introduction", label: t("best-practices"), iconImg: PracticesIcon, intercomLabel: "best-practices" },
      { to: "/myinfo", label: t("my-info"), tooltip: t("view-edit-contact-information"), iconImg: InfoIcon, intercomLabel: "my-info" },
      { to: "/resources", label: t("resources-title"), iconImg: ResourcesIcon, intercomLabel: "resources-title" },
    ]

    return result;
  }

  return {
    navs
  }
}