import React from "react";
import Loading from "../../../components/Loading";
import { Button, Modal } from "reactstrap";
import "./styles.scss"
import { useTranslation } from "react-i18next";
import useHook from "./useHook";

const ReassignSchoolLead = ({ session, onDone, onToggle }) => {
  const {
    loading,
    availableHosts,
    unavailableHosts,
    leads,
    setLeads,
    saving,
    onSave
  } = useHook(session);
  const { t } = useTranslation();

  const renderBookedSessions = (host) => {
    if (host.bookedSessions === undefined || host.bookedSessions === null) return null;
    return ` (${host.bookedSessions} sessions hosted this day)`
  }

  const renderHosts = (label, nav, hosts) => {
    if (!hosts || hosts.length === 0) return null;

    return (
      <div className="host-group">
        <div className="group-title">{label}</div>
        {hosts.map((team, i) => (
          <div className="custom-control custom-checkbox mb-3" key={i}>
            <input
              className="custom-control-input"
              id={`customcheckbox-${nav}${i}`}
              type="checkbox"
              defaultChecked={leads[team.id] ? true : false}
              disabled={saving}
              onChange={
                (e) => {
                  let ls = { ...leads };
                  if (e.target.checked) {
                    ls[team.id] = true
                  } else {
                    delete ls[team.id];
                  }
                  setLeads(ls)
                }
              }
            />
            <label className="custom-control-label" htmlFor={`customcheckbox-${nav}${i}`}>
              {team.Name}
              {renderBookedSessions(team)}
            </label>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Reassign Session Host</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {
          loading ? (
            <div className="loading-teams">
              <Loading size={24} />
            </div>
          ) : (
            <div className="teams">
              {renderHosts("Available Hosts", "available", availableHosts)}
              {renderHosts("Unavailable Hosts", "unavailable", unavailableHosts)}
            </div>
          )
        }
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          type="button"
          onClick={() => onSave(onDone)}
        >
          {saving ? (<Loading size={14} />) : (null)}{t("save")}
        </Button>
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {t("close")}
        </Button>
      </div>
    </Modal >
  )
}

export default ReassignSchoolLead