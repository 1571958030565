import AirtableTable from "../AirtableTable";

export class TagTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Tags")

    this.fields = {
      "Tag": "fldNbt2W8smFbDxrd",
      "Collection Link": "fldWELjCJfQ8HRJXH",
      "Tag Creator": "fldbF8gcPytYMIKnp",
      "Description": "fldSpuBcVqnqEbbAR",
      "Featured": "fld5ZzC9Ws0If6W5Q",
      "Public": "fld8sw7TQbtYm85Cl",
      "Thumbnail Image": "fldjpUTVwj5yxjEPn",
      "For Teacher(s)": "fldcTitA3GJo0VFVV",
      "For Cluster(s)": "fldKPCFJ34P5x9UbR",
      "For School(s)": "fldV4kwYg8Y3lF9Gr",
      "Created By": "fldQXVdu0qHBmtHIA"
    }
  }

  getAllTags = () => {
    return this.list({
      sort: [{ field: 'Tag', direction: 'asc' }],
      filterByFormula: `OR({Public} = TRUE(),{For Teacher(s)} != '',{For School(s)} != '',{For Cluster(s)} != '')`
    })
  }

  getAllTagsEvenPrivate = () => {
    return this.list({
      sort: [{ field: 'Tag', direction: 'asc' }],
    })
  }
  getTeamTags = (teamName) => {
    return this.list({
      sort: [{ field: 'Tag', direction: 'asc' }],
      filterByFormula: `AND(
        OR(
          FIND("${teamName}", {Created By}),
          {Everyone Can Edit} = TRUE()
        ),
        {Search Query} = BLANK()
      )`
    })
  }
}
