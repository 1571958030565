import airtable from "../../airtables";

export const selectUserByEmail = async (email) => {
  let tUser = null;

  tUser = await airtable.teachers.getTeacherByEmail(email);
  if (tUser) {
    tUser = await airtable.teachers.select(tUser.id);
    delete tUser["24 Report Message"];

    return {
      userType: "Teacher",
      userInfo: { ...tUser }
    }
  }

  tUser = await airtable.teams.getTeamByEmail(email);
  if (tUser) {
    tUser = await airtable.teams.select(tUser.id);

    return {
      userType: "Team",
      userInfo: { ...tUser }
    }
  }

  tUser = await airtable.providers.getProviderByEmail(email);
  if (tUser) {
    tUser = await airtable.providers.select(tUser.id);

    return {
      userType: "Provider",
      userInfo: { ...tUser }
    }
  }

  tUser = await airtable.presenters.getPresenterByEmail(email);
  if (tUser) {
    tUser = await airtable.presenters.select(tUser.id);

    return {
      userType: "Presenter",
      userInfo: { ...tUser }
    }
  }

  let dms = email.split("@");
  tUser = await airtable.providers.getProviderByDomain(dms[1]);;
  if (tUser) {
    return {
      external: true,
      userId: dms[0],
      userType: "Presenter",
      userInfo: { ...tUser }
    }
  }

  tUser = await airtable.clusters.selectClusterByDomain(dms[1]);
  if (tUser) {
    return {
      external: true,
      userId: dms[0],
      userType: "Teacher",
      userInfo: { ...tUser }
    }
  }

  return null;
}

export const getUser2FAMethod = (userType, userInfo) => {
  if (userType === "Team") {
    return userInfo["2FA"] || "SMS";
  }

  return userInfo["2FA"] || "None";
}

export const updateUserInfo = async (
  userType,
  userId,
  data
) => {
  let table = null;
  switch (userType) {
    case "Team":
      table = airtable.teams;
      break;
    case "Teacher":
      table = airtable.teachers;
      break;
    case "Provider":
      table = airtable.providers;
      break;
    case "Presenter":
      table = airtable.presenters;
      break;
    default:
      break;
  }

  if (table) {
    return await table.update(userId, data);
  }
}

export const getUserPhone = (userType, userInfo) => {
  switch (userType) {
    case "Teacher":
      return userInfo["Personal Phone"];
    case "Team":
    case "Provider":
    case "Presenter":
      return userInfo["Phone"];
    default:
      return "";
  }
}