import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row, Progress } from 'reactstrap';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import TwoFactorAuthConfirmModal from '../modals/TwoFactorAuthConfirm';

const Screen = () => {
  const { userInfo } = useSelector(state => state.appInfo);
  const [updating, setUpdating] = useState(false);
  const [communitiesMap, setCommunitiesMap] = useState({});
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pronouns, setPronouns] = useState("")
  const [indigenousCanada, setIndigenousCanada] = useState();
  const [indigenousStatus, setIndigenousStatus] = useState([]);
  const [indigenousAnotherNation, setIndigenousAnotherNation] = useState(false);
  const [indigenousNation, setIndigenousNation] = useState("");
  const [indigenousCommunities, setIndigenousCommunities] = useState([]);
  const [language, setLanguage] = useState(userInfo["Preferred Language"] || 'English')
  const [authMethod, setAuthMethod] = useState("None");
  const [_2FAVerificationInfo, set2FAVerificationInfo] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [phoneDisabled, setPhoneDisabled] = useState(false);

  const [communities, setCommunities] = useState([])
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    airtable.communities.getTotalCommunities().then(res => setCommunities(res))
  }, [])

  useEffect(() => {
    if (!communities || communities.length === 0) return;

    const rMap = communities.reduce((result, item, i) => {
      result[item.id] = item;
      return result;
    }, {});
    setCommunitiesMap(rMap);
  }, [communities])

  useEffect(() => {
    setName(userInfo['Name']);
    setEmail(userInfo['Email']);
    setPhoneNumber(userInfo['Phone']);
    setPronouns(userInfo["Pronouns"]);
    setIndigenousCanada(userInfo["Indigenous to Canada?"] ? true : false);
    setIndigenousStatus(userInfo["Indigenous Status"] ? userInfo["Indigenous Status"] : []);
    if (userInfo["Indigenous Nation"]) setIndigenousAnotherNation(true)
    setIndigenousNation(userInfo["Indigenous Nation"] || "");
    setIndigenousCommunities(userInfo["Indigenous Communities"] || []);
    setLanguage(userInfo["Preferred Language"] || 'English');
    setAuthMethod(userInfo["2FA"] || "None");
    setPhoneDisabled(userInfo["2FA"] === "SMS");
  }, [userInfo])

  const cancelUpdate = () => {
    setName(userInfo['Name']);
    setEmail(userInfo['Email']);
    setPhoneNumber(userInfo['Phone']);
    setPronouns(userInfo["Pronouns"]);
    setIndigenousCanada(userInfo["Indigenous to Canada?"] ? true : false);
    setIndigenousStatus(userInfo["Indigenous Status"] ? userInfo["Indigenous Status"] : []);
    if (userInfo["Indigenous Nation"]) setIndigenousAnotherNation(true);
    setIndigenousNation(userInfo["Indigenous Nation"] || "");
    setIndigenousCommunities(userInfo["Indigenous Communities"] || []);
    setLanguage(userInfo["Preferred Language"] || 'English');
    setAuthMethod(userInfo["2FA"] || "None");
    setEdit(false);
  }

  const updateInfo = async () => {
    if (authMethod === "SMS" && !phoneNumber) {
      alert("Please input a mobile phone number that can receive text messages to enable SMS verification.")
      return;
    }

    if (phoneNumber && phoneNumber.replace(/\D/g, '').length < 10) {
      setPhoneError(t("mysettings.phone-number-error-length"));
      return;
    }
    setPhoneError("");

    let phoneVerified = userInfo["Phone Number SMS Verified"] ? true : false;
    if (phoneNumber && phoneNumber !== userInfo["Phone"]) {
      phoneVerified = false;
    }

    if (authMethod === "SMS" && !phoneVerified) {
      set2FAVerificationInfo({
        type: "sms",
        phone: phoneNumber,
        uInfo: {}
      });
    } else if (authMethod === "App" && !userInfo["2FA Secret"]) {
      set2FAVerificationInfo({
        type: "app",
        uInfo: { userEmail: userInfo["Email"] }
      });
    }

    setUpdating(true);
    try {
      let params = {
        "Name": name,
        "Email": email.trim(),
        "Phone": phoneNumber,
        "Pronouns": pronouns,
        "Indigenous to Canada?": indigenousCanada,
        "Indigenous Nation": indigenousAnotherNation ? indigenousNation : "",
        "Preferred Language": language,
        "2FA": authMethod,
      };

      if (authMethod !== "App" && userInfo["2FA"] !== authMethod) {
        params["2FA Secret"] = "";
      }

      if (indigenousCanada) {
        params["Indigenous Status"] = indigenousStatus;
        params["Indigenous Communities"] = indigenousCommunities;
      }
      const result = await airtable.presenters.update(userInfo.id, params);
      await setSession(CONNECTEDNORTH_SIGNINFO, result);
      dispatch(setAppUserInfo(result));
    } catch (error) { }
    setUpdating(false);
    setEdit(false);
  }

  const verifyPost = async (type, secret) => {
    let nUserInfo = null;
    if (type === "sms") {
      nUserInfo = await airtable.presenters.update(userInfo.id, {
        "Phone": phoneNumber,
        "Phone Number SMS Verified": true,
        "2FA": "SMS"
      });
    } else if (type === "app") {
      nUserInfo = await airtable.presenters.update(userInfo.id, {
        "2FA Secret": secret,
        "2FA": "App"
      });
    }

    if (nUserInfo) {
      await setSession(CONNECTEDNORTH_SIGNINFO, nUserInfo);
      dispatch(setAppUserInfo(nUserInfo));
      set2FAVerificationInfo(null);
      toast.success(t("mysettings.login-security-enabled"));
    }
  }

  return (
    <div className="main-container">
      <Row>
        <Col>
          <h1>{t("my-info")}</h1>
        </Col>
        <Col align="right">
          {
            edit ? (
              <>
                <Button color="link" size="sm" onClick={() => cancelUpdate()}>{t("cancel")}</Button>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => updateInfo()}
                  disabled={(!name || !email)}
                >{t("save")}</Button>
              </>
            ) : (
              <Button color="link" size="sm" onClick={() => setEdit(true)}>{t("edit")}</Button>
            )
          }
        </Col>
      </Row>
      {updating && <Progress />}
      <div style={{ marginTop: 10 }} align="center">
        <div style={{ maxWidth: 600, minHeight: 500, padding: '0 20px', paddingTop: 40 }} align="left">
          <FormGroup row>
            <Label for="name" sm={4}>{t("contact-name")}</Label>
            <Col sm={8} className="v-center">
              {
                edit ? (
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Contact Name"
                    defaultValue={name}
                    onChange={e => setName(e.target.value)}
                  />
                ) : (
                  <span>{name}</span>
                )
              }
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>Preferred Language</Label>
            <Col sm={8} className="v-center">
              {
                edit ? (
                  <Input
                    type="select"
                    className='pronouns form-control'
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="English">English</option>
                    <option value="French">French</option>
                  </Input>
                ) : (
                  <span>{language}</span>
                )
              }
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="name" sm={4}>{t("pronouns")}</Label>
            <Col sm={8} className="v-center">
              {
                edit ? (
                  <Input
                    type="text"
                    name="pronouns"
                    id="pronouns"
                    placeholder={t("pronouns")}
                    defaultValue={pronouns}
                    onChange={e => setPronouns(e.target.value)}
                  />
                ) : (
                  <span>{pronouns}</span>
                )
              }
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>{t("email")}</Label>
            <Col sm={8} className="v-center">
              {
                edit ? (
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                ) : (
                  <span>{email}</span>
                )
              }
            </Col>
          </FormGroup>

          <div className="user-phone">
            <FormGroup row>
              <Label for="phone" sm={4}>{t("phone-number")}</Label>
              <Col sm={8}>
                {edit ? (
                  <>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={e => {
                        setPhoneNumber(e.target.value);
                        setPhoneError("");
                      }}
                      disabled={phoneDisabled}
                    />
                    {phoneError && <div className="text-danger mt-1">{phoneError}</div>}
                  </>
                ) : (
                  <span>{phoneNumber}</span>
                )}
              </Col>
            </FormGroup>
            {phoneDisabled && (
              <p className="phone-error">To edit your phone number, first disable Login Security below and save changes.</p>
            )}
            {(!userInfo["Phone"] && authMethod === "SMS") ? (
              <p className="phone-error">Please add a phone number and save your settings to enable SMS login security.</p>
            ) : (!userInfo["Phone Number SMS Verified"] && authMethod === "SMS") && (
              <p className="phone-error">This phone number is not verified yet. Please <span
                onClick={() => {
                  set2FAVerificationInfo({
                    type: "sms",
                    phone: phoneNumber,
                  });
                }}
              >verify it here</span>.</p>
            )}
          </div>

          <div className="user-auth">
            <FormGroup row>
              <Label for="phone" sm={4}>Login Security</Label>
              <Col sm={8} className="v-center">
                {edit ? (
                  <Input
                    type="select"
                    className='_2fa-selector form-control'
                    value={authMethod}
                    onChange={(e) => setAuthMethod(e.target.value)}
                  >
                    <option value="None">None</option>
                    <option value="SMS">SMS (Text Message)</option>
                    <option value="App">Authenticator App</option>
                  </Input>
                ) : (
                  <span>{authMethod}</span>
                )}
              </Col>
            </FormGroup>
            {(!userInfo["2FA Secret"] && authMethod === "App") && (
              <p className="_2fa-error"><span
                onClick={() => {
                  set2FAVerificationInfo({
                    type: "app",
                    uInfo: { userEmail: userInfo["Email"] }
                  });
                }}
              >Click here to set up an authenticator app</span> to receive passcodes.</p>
            )}
          </div>
          <span style={{ fontSize: '0.85rem' }}>When logging in, to provide additional security you can verify your login request with a SMS Text Message to your mobile device, or a smartphone-based authentication app, such as Google Authenticator or Microsoft Authenticator.</span>

          {
            edit ? (
              <Fragment>
                <FormGroup row>
                  <Col className="v-center checkbox-col">
                    <Input
                      type="checkbox"
                      name="indigenousCanada"
                      id="indigenousCanada"
                      checked={indigenousCanada}
                      className='ml0'
                      onChange={e => setIndigenousCanada(e.target.checked)}
                    />
                  </Col>
                  <Label for="indigenousCanada" sm={10}>Do you identify as Indigenous to Canada?</Label>
                </FormGroup>
                {
                  indigenousCanada && (
                    <Fragment>
                      <FormGroup row>
                        <Label for="phone" sm={4}>Are you First Nations, Métis and/or Inuit?</Label>
                        <Col sm={8} className="v-center">
                          <Select
                            className="form-style full"
                            isMulti
                            value={indigenousStatus.map(v => ({ value: v, label: v }))}
                            onChange={(selections) => setIndigenousStatus(selections.map(v => v.value))}
                            options={["First Nations", "Inuit", "Métis"].map(type => ({ value: type, label: type }))}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="phone" sm={4}>Are you a member of a Nation or Community?</Label>
                        <Col sm={8} className="v-center">
                          <Select
                            className="form-style full"
                            isMulti
                            value={indigenousCommunities.map(v => ({ value: v, label: communitiesMap[v].Name }))}
                            onChange={(selections) => setIndigenousCommunities(selections.map(v => v.value))}
                            options={communities.map(community => ({ value: community.id, label: community.Name }))}
                          />
                        </Col>
                      </FormGroup>
                    </Fragment>
                  )
                }
              </Fragment>
            ) : (
              <Fragment>
                <FormGroup row>
                  <Label sm={4}>Indigenous to Canada?</Label>
                  <Label sm={4}>{indigenousCanada ? "Yes" : "No"}</Label>
                </FormGroup>
                {
                  indigenousCanada && (
                    <FormGroup row>
                      <Label sm={4}>Indigenous Status</Label>
                      <Label sm={4}>{indigenousStatus.join(", ")}</Label>
                    </FormGroup>
                  )
                }
              </Fragment>
            )
          }

          {
            edit ? (
              <Fragment>
                <FormGroup row>
                  <Col className="v-center checkbox-col">
                    <Input
                      type="checkbox"
                      name="indigenousAnotherNation"
                      id="indigenousAnotherNation"
                      checked={indigenousAnotherNation}
                      className='ml0'
                      onChange={e => setIndigenousAnotherNation(e.target.checked)}
                    />
                  </Col>
                  <Label for="indigenousAnotherNation" sm={10}>Do you identify as Indigenous to another country?</Label>
                </FormGroup>
                {
                  indigenousAnotherNation && (
                    <FormGroup>
                      <Label for="indigenousNation">Which Nation or community are you a part of?</Label>
                      <Col style={{ padding: 0 }}>
                        <Input
                          type="textarea"
                          name="indigenousNation"
                          id="indigenousNation"
                          defaultValue={indigenousNation}
                          onChange={e => setIndigenousNation(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  )
                }
              </Fragment>
            ) : (
              indigenousAnotherNation && (
                <FormGroup row>
                  <Label sm={4}>Indigenous Nation</Label>
                  <Label sm={4}>{indigenousNation}</Label>
                </FormGroup>
              )
            )
          }
        </div>
      </div>

      {!!_2FAVerificationInfo && (
        <TwoFactorAuthConfirmModal
          type={_2FAVerificationInfo.type}
          phone={_2FAVerificationInfo.phone}
          uInfo={_2FAVerificationInfo.uInfo}
          onDismiss={() => set2FAVerificationInfo(null)}
          onVerifiedPost={verifyPost}
        />
      )}
    </div>
  )
}

export default Screen;