import React, { useEffect, useLayoutEffect, useState } from "react";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePDData } from "./hooks/use-pd-data.hook";
import { PDPlanLayout } from "./components/PDPlanLayout";
import { PLAN_FIELDS } from "./constants/pd-fields";
import { getTeacherById } from "./util/get-pd-data";
import Plan from "./components/Plan";

const Screen = () => {
	const { teacherId } = useParams(); // Extract teacherId from URL params
	const { activePD, addAdminReview, isLoading } = usePDData();
	const { userInfo } = useSelector((state) => state.appInfo);

	const [teacherName, setTeacherName] = useState("");

	const planStatus = activePD ? activePD[PLAN_FIELDS.COMPLETION_STATUS] : "";
	const isReviewerMode = true;

	useLayoutEffect(() => {
		window.document.title = "PD Plan Reviewer - Connected North";
	}, []);

	useEffect(() => {
		const getTeacherName = async () => {
			if (
				activePD.Teacher &&
				activePD.Teacher.length > 0 &&
				teacherName === ""
			) {
				const teacher = await getTeacherById(activePD.Teacher[0]);
				if (teacher && teacher["Teacher Name"]) {
					setTeacherName(teacher["Teacher Name"]);
				}
			}
		};

		if (activePD) {
			getTeacherName();
		}
	}, [activePD, teacherName]);

	return (
		<PDPlanLayout
			planStatus={planStatus}
			reviewerMode={isReviewerMode}
			teacherName={teacherName}
			userInfo={userInfo}
		>
			{isLoading && <CircularProgress />}
			{!isLoading && !activePD && (
				<Alert
					severity="info"
					sx={{
						justifyContent: "center",
						alignItems: "center",
						fontFamily: "inherit",
					}}
				>
					<Typography variant="body1" component="p">
						This teacher has no active PD Plan.
					</Typography>
				</Alert>
			)}
			{activePD && (
				<Plan
					pd={activePD}
					editMode={false}
					reviewMode={isReviewerMode}
					addReview={addAdminReview}
					loading={isLoading}
					userInfo={userInfo}
					reviewTeacherId={teacherId}
				/>
			)}
		</PDPlanLayout>
	);
};

export default Screen;
