import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Airtable from "../../airtables";
import { Pagination } from "../../components/Pagination";
import { useTranslation } from "react-i18next";
import CarouselProvider from "../../components/Carousel";
import Placeholder from "../../assets/img/login-back.jpg";
import {
	Row,
	Col
} from "reactstrap";
  
const responsiveSessions = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 2000 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 2000, min: 1024 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const SHOW_PER_PAGE = 10;

export function Collections() {
	const { t } = useTranslation();
	const {
		allCollections,
		schoolLeadCollections,
		featured,
		setPage,
		onPageSelect,
		page,
		pageCount,
	} = useCollection();
	// `allCollections` and `schoolLeadCollections` have the same shape.
	// I just filted it in the query hook too keep the template clean :D
	// have fun
	const { userType } = useSelector((state) => state.appInfo);
    useEffect(() => {
        window.document.title = "Explore Collections - Connected North"
    }, [])

	return (
		<div className="main-container collection-hub">
			<Row className="mb-5">
				<Col md="10" lg="8">
					<h1>{t('collections.explore-collections')}</h1>
					<p>{t('collections.collections-intro')}</p>
				</Col>
			</Row>
			{featured ? (
			<>
				<h2>{t('collections.featured')}</h2>
				<Row className="featured-collection align-items-start mx-0 mt-4 mb-5 py-4 px-2">
					<Col md="5">
						
						<div className="image-wrap">
						{(featured["Thumbnail Image"]) ? (
							<img src={featured["Thumbnail Image"][0].url} alt="" />
						): (
							<img src={Placeholder} alt="" />
						)} 
						</div>
					</Col>
					<Col className="text-left">
						<h3 className="font-weight-bold mb-2">{featured["Tag"]}</h3>
						<p>{featured["Description"] && featured["Description"].length > 300 ?`${featured["Description"].substring(0, 300)}...` : featured["Description"] }</p>
						<a href={'/cn/collection/' + featured["id"]} className="mt-4 btn btn-primary">{t('collections.view-collection')}</a>
					</Col>
				</Row>
			</>
		 	) : (
				null
			)} 
			{schoolLeadCollections && schoolLeadCollections.length ? (
				<div className="my-5">
					<CarouselProvider
						responsive={responsiveSessions}
						infinite={false}
						title={userType === "Team" ? t('collections.my-collections') : t('collections.from-school-lead')}
					>
						{schoolLeadCollections.map((c) => (
							<div key={c["id"]} className="collection-card mt-3">
								<div className="image-wrap">
									{(c["Thumbnail Image"]) ? (
										<img src={c["Thumbnail Image"][0].url} alt="" />
									): (
										<img src={Placeholder} alt="" />
									)}
								</div>
								<div className="content">
									<h3 className="font-weight-bold mb-2">{c["Tag"]}</h3>
									<p>
										{c["Description"] && c["Description"].length > 150 ?`${c["Description"].substring(0, 150)}...` : c["Description"] }
									</p>
									<a href={'/cn/collection/' + c["id"]} className="mt-4 btn btn-primary">{t('collections.view-collection')}</a>
								</div>
							</div>
						))}
					</CarouselProvider>
				</div>
			): null }

			<h2>{t('collections.all-collections')}</h2>
			{allCollections && allCollections.length ? (
				<Row className="my-5">
					{allCollections.map((c) => (
						<Col key={c["id"]} md="6">
							<div className="collection-card mb-4">
								<div className="image-wrap">
									{(c["Thumbnail Image"]) ? (
										<img src={c["Thumbnail Image"][0].url} alt="" />
										
									): (
											<img src={Placeholder} alt="" />
										)}
								</div>
								<div className="content">
									<h3 className="font-weight-bold mb-2">{c["Tag"]}</h3>
									<p>
										{c["Description"] && c["Description"].length > 250 ?`${c["Description"].substring(0, 250)}...` : c["Description"] }
									</p>
									<a href={'/cn/collection/' + c["id"]} className="mt-4 btn btn-primary">{t('collections.view-collection')}</a>
								</div>
							</div>
						</Col>
					))}
				</Row>
			): null }

			<Pagination
				page={page}
				pageCount={pageCount}
				setPage={setPage}
				onPageSelect={onPageSelect}
			/>
		</div>
	);
}

function useCollection() {
	/** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
	const [all, setAll] = useState([]);

	/** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
	const [leadCollection, setLeadCollection] = useState([]);

	/** @type {[object | null, React.Dispatch<React.SetStateAction<any[]>>]} */
	const [featured, setFeatured] = useState(null);

	/** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
	const [loading, setLoading] = useState(true);

	const { userInfo, userType } = useSelector((state) => state.appInfo);
	useEffect(() => {
		(async () => {
			setLoading(() => true);
			try {
				let result;
				if (userType === "Team") {
					result = await Airtable.tags.getAllTagsEvenPrivate();
				} else {
					result = await Airtable.tags.getAllTags();
				}
				const all = [];
				const schoolLeadCollection = [];

				for (const c of result) {
					/** @type {string} */
					if (c["Created By"] === userInfo["Name"] || 
						((c["For Teacher(s)"] && c["For Teacher(s)"].includes(userInfo["id"])) || 
						(c["For Cluster(s)"] && userInfo["Cluster"] && 
							userInfo["Cluster"].some(cluster => c["For Cluster(s)"].includes(cluster))) || 
						(c["For School(s)"] && userInfo["School Name"] && 
							userInfo["School Name"].some(school => c["For School(s)"].includes(school))))) {
						schoolLeadCollection.push(c);
					} else if (c["Public"]) {
						all.push(c);
					}
					if (c["Featured"]) {
						setFeatured(() => c);
					}
				}

				setAll(() => all);
				setLeadCollection(() => schoolLeadCollection);
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(() => false);
			}
		})();
	},[userInfo,userType]);

	// PAGINATIONS
	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [page, setPage] = useState(0);

	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [pageCount, setPageCount] = useState(0);
	useEffect(() => {
		setPageCount(() => Math.ceil(all.length / SHOW_PER_PAGE));
	}, [all]);

	/** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
	const [allCollections, setAllCollections] = useState([]);
	useEffect(() => {
		const l = page * SHOW_PER_PAGE;
		const h = l + SHOW_PER_PAGE;
		setAllCollections(() => all.slice(l, h));
	}, [page, all]);

	/**
	 * @param {number} i
	 */
	function onPageSelect(i) {
		return () => setPage(() => i);
	}

	return {
		schoolLeadCollections: leadCollection,
		allCollections,
		loading,
		featured,
		pageCount,
		page,
		setPage,
		onPageSelect,
	};
}
