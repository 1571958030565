import airtable from "../../../airtables/PDAirtable";
import {
	PLAN_FIELDS,
	STANDARD_FIELDS,
	TEACHER_FIELDS,
} from "../constants/pd-fields";

export const getStandardOptions = async (userInfo, reviewTeacherId = null) => {
	const { CODE, FOCUS_AREA } = STANDARD_FIELDS;
	let userType = 'Teacher';
	if (reviewTeacherId) {
		// Fetch the teacher's info if we're reviewing their plan
		const teacherInfo = await airtable.nunavutTeachers.getTeacherByRecordId(reviewTeacherId);
		if (teacherInfo["Grade(s) Taught"]) {
			const grades = teacherInfo["Grade(s) Taught"];
			if (grades.includes("Learning Coach")) {
				userType = 'Learning Coach';
			} else if (grades.includes("SST")) {
				userType = 'SST';
			} else {
				userType = 'Teacher';
			}
		} else {
			userType = 'Teacher';
		}
	} else {
		// Use the logged-in user's info
		if (userInfo["School Leader?"]) {
			userType = 'School Leader';
		} else if (userInfo["Grade(s) Taught"]) {
			const grades = userInfo["Grade(s) Taught"];
			if (grades.includes("Learning Coach")) {
				userType = 'Learning Coach';
			} else if (grades.includes("SST")) {
				userType = 'SST';
			}
		}
	}

	const response = await airtable.standards.listByUserType(userType);

	return response?.map((standard) => ({
		id: standard.id,
		code: standard[CODE],
		focusArea: standard[FOCUS_AREA],
	}))
	.sort((a, b) => {
		const [majorA, minorA = 0] = a.code.split('.').map(Number);
		const [majorB, minorB = 0] = b.code.split('.').map(Number);
		
		if (majorA !== majorB) {
			return majorA - majorB;
		}
		return minorA - minorB;
	});
};

export const getPDData = async (teacherId) => {
	const { PD_PLANS } = TEACHER_FIELDS;
	const { GOALS } = PLAN_FIELDS;

	//I've made this function do a deep pull so it'll have all plans and their goals rather than just ids.
	const response = await airtable.nunavutTeachers.getTeacherByRecordId(
		teacherId,
		PD_PLANS,
		GOALS,
	);
	return response;
};

export const getPDPlanById = async (planId) => {
	try {
		const planResponse = await airtable.pdPlans.getPlansByIds([planId]);
		if (!planResponse || planResponse.length === 0) {
			return null;
		}

		// Plan comes with array of Goal IDs, so we fetch the goals from those
		// IDs and merge them with the plan object.
		const plan = planResponse[0];
		const goalsResponse = await airtable.pdPlanGoals.getGoalsByIds(
			plan[PLAN_FIELDS.GOALS],
		);

		return { ...plan, [PLAN_FIELDS.GOALS]: goalsResponse || [] };
	} catch (error) {
		console.log("Could not fetch PD Plan: ", error);
		return null;
	}
};

export const getTeacherById = async (teacherId) => {
	try {
		const response = await airtable.nunavutTeachers.getTeachersByIds([
			teacherId,
		]);
		return response && response.length > 0 ? response[0] : null;
	} catch (e) {
		console.log("Could not fetch user: ", e);
		return null;
	}
};

export const getTeacherBySyncedId = async (teacherId) => {
	try {
		const response = await airtable.nunavutTeachers.getTeachersBySyncedId([
			teacherId,
		]);
		return response && response.length > 0 ? response[0] : null;
	} catch (e) {
		console.log("Could not fetch user: ", e);
		return null;
	}
};
