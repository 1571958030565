import React, { useEffect, useId, useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { Box, TextField, Typography } from "@mui/material";
import { Accordion } from "./Accordion";
import { getReviewStatus, STATUS_LABELS } from "../util/review-dates";
import { StatusLabel } from "./StatusLabel";
import { ToastMessage } from "./ToastMessage";
import { getTeacherById } from "../util/get-pd-data";

const StaticComment = ({ heading = null, comment = null }) => (
	<Box my="1rem">
		{heading && (
			<Typography variant="h6" component="h4">
				{heading}
			</Typography>
		)}
		{comment && (
			<Typography variant="body1" component="p">
				{comment}
			</Typography>
		)}
	</Box>
);

const OpenComment = ({ onSubmit, onChange, value, label }) => {
	const commentId = useId();
	return (
		<Box mt="1rem" mb="2rem" component="form" onSubmit={onSubmit}>
			<Box>
				<Typography
					variant="h6"
					component="label"
					htmlFor={`${commentId}-admin`}
				>
					{label}
				</Typography>
			</Box>
			<Box>
				<TextField
					id={`${commentId}-admin`}
					multiline
					rows="6"
					display="block"
					sx={{ width: "100%" }}
					value={value}
					required
					onChange={(e) => onChange(e.target.value)}
				/>
			</Box>
			<Box my="1.5rem">
				<Button color="primary" display="block" type="submit">
					Submit
				</Button>
			</Box>
		</Box>
	);
};

const Review = ({
	reviewMode,
	editMode,
	review,
	addReview,
	onChange,
	expanded,
	status,
	...props
}) => {
	const { label, name, date, comment, teacher_comment } = review;

	const [adminName, setAdminName] = useState("");

	const isAdminView = reviewMode && !editMode;
	const isTeacherView = !reviewMode && editMode;

	const [adminReview, setAdminReview] = useState(comment || "");
	const [teacherReview, setTeacherReview] = useState(teacher_comment || "");

	const { reviewStatus, reviewIndex, hasAdminReview } =
		getReviewStatus(review);

	const onSubmitAdminReview = async (e) => {
		e.preventDefault();
		if (adminReview === "") return;

		const addedReview = await addReview(reviewIndex, {
			comment: adminReview.trim(),
		});

		if (addedReview) {
			toast.success(
				<ToastMessage>
					Your Review of this PD Plan has been submitted.
				</ToastMessage>,
			);
		} else {
			toast.error(
				<ToastMessage>
					Your Review could not be saved. Please try again.
				</ToastMessage>,
			);
		}
	};

	const onSubmitTeacherReview = async (e) => {
		e.preventDefault();
		if (teacherReview === "") return;

		const addedReview = await addReview(reviewIndex, {
			teacher_comment: teacherReview.trim(),
		});

		if (addedReview) {
			toast.success(
				<ToastMessage>
					Your Review comment has been submitted.
				</ToastMessage>,
			);
		} else {
			toast.error(
				<ToastMessage>
					Your Review comment could not be saved. Please try again.
				</ToastMessage>,
			);
		}
	};

	useEffect(() => {
		const getAdminName = async () => {
			if (name.length > 0 && adminName === "") {
				const admin = await getTeacherById(name[0]);
				if (admin && admin["Teacher Name"]) {
					setAdminName(admin["Teacher Name"]);
				}
			}
		};

		if (name) {
			getAdminName();
		}
	}, [adminName, name]);

	return (
		<div {...props}>
			<Accordion
				title={label}
				onChange={onChange}
				expanded={expanded}
				status={<StatusLabel label={reviewStatus} />}
			>
				{!reviewStatus && (
					<StaticComment comment="Review period has not started" />
				)}

				{reviewStatus === STATUS_LABELS.COMPLETE && (
					<>
						<StaticComment
							heading={`${adminName} - ${date}`}
							comment={comment}
						/>
						<StaticComment
							heading={
								isAdminView
									? "Teacher Comment(s)"
									: "Your Comment(s)"
							}
							comment={teacher_comment}
						/>
					</>
				)}

				{reviewStatus && reviewStatus !== STATUS_LABELS.COMPLETE && (
					<>
						{isAdminView && (
							<>
								{hasAdminReview ? (
									<StaticComment
										heading={`${adminName} - ${date}`}
										comment={comment}
									/>
								) : (
									<OpenComment
										label="Add Admin Review"
										onSubmit={onSubmitAdminReview}
										onChange={setAdminReview}
										value={adminReview}
									/>
								)}
							</>
						)}
						{isTeacherView && (
							<>
								{hasAdminReview ? (
									<>
										<StaticComment
											heading={`${adminName} - ${date}`}
											comment={comment}
										/>
										<OpenComment
											label="Add Your Comment(s)"
											onSubmit={onSubmitTeacherReview}
											onChange={setTeacherReview}
											value={teacherReview}
										/>
									</>
								) : (
									<StaticComment
										heading="Admin Review - Pending"
										comment="Your Principal or administrator has not yet submitted their review."
									/>
								)}
							</>
						)}
					</>
				)}
			</Accordion>
		</div>
	);
};

export default Review;
