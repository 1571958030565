import AirtableTable from "../AirtableTable";

export class TeacherTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Teachers")

    this.fields = {
      "Teacher Name": "fldlHENv8ejttYlSn",
      "Personal Phone": "fldIVvJ9CO53QixiU",
      "Email": "fld3vdHfgxlGpWscj",
      "Sessions": "fldMSwVTdpyqer2iy",
      "School Name": "fldvcXGx6tNrBFZKT",
      "School Name Text": "fldAelONuFpNLBRX1",
      "Credit Balance": "fldWYEBomGHTDbXYb",
      "23-24 Credit Allocation": "flddGvbriaIrLpBCB",
      "Last E-mail Days": "fldqvCqTyCkA3mCTU",
      "% Indigenous TY": "fldeYEbh3nfFvDhBA",
      "Upcoming TY": "fldsv9hYVcUfBTCUY",
      "Completed TY": "fldOjSDGBwGGZD0IT",
      "Subject(s) Taught": "fldOeqJwU1z97VdYC",
      "Grade(s) Taught": "fldS3TJ3V0hONIRdL",
      "First Name": "fld6WXhnhE3x3odwy",
      "School Lead E-mail": "fldGkziRL1udXtPSt",
      "Sessions Completed": "fldEws4xj3S5WzdUr",
      "Favourites": "fldWq45CFAWJTsPoM",
      "School P/T Text": "fldCBUJDyUfjHX8J4",
      "School PT": "fldWzIpSngGIQcbuY",
      "PD Plan Status": "fldvGWBBixt4kZvA5",
    }
  }

  selectTeacherByEmail = (email) => {
    const em = email.toUpperCase();
    return this.selectOneByCondition({
      filterByFormula: `OR("${em}" = UPPER({Email}), "${em}" = UPPER({Secondary Email}))`
    });
  }

  getTeacherByEmail = async (email) => {
    const em = email.toUpperCase();
    let teacher = await this.selectOneByCondition({
      filterByFormula: `OR("${em}" = UPPER({Email}), "${em}" = UPPER({Secondary Email}))`
    });

    if (!teacher) {
      const [localPart, domain] = em.split('@');
      const noDotEmail = `${localPart.replace(/\./g, '')}@${domain}`;
      const possibleEmails = [
        noDotEmail,
        ...this.generatePossibleDotCombinations(localPart, domain)
      ];
      
      // Add alternative domain for KPDSB teachers who often forget which domain they signed up with
      if (domain === 'KPDSB.ON.CA' || domain === 'K12.KPDSB.CA') {
        const altDomain = domain === 'KPDSB.ON.CA' ? 'K12.KPDSB.CA' : 'KPDSB.ON.CA';
        possibleEmails.push(`${localPart}@${altDomain}`);
        possibleEmails.push(`${localPart.replace(/\./g, '')}@${altDomain}`);
        possibleEmails.push(...this.generatePossibleDotCombinations(localPart, altDomain));
      }
      
      const filterFormula = possibleEmails.map(e => 
        `OR("${e}" = UPPER({Email}), "${e}" = UPPER({Secondary Email}))`
      ).join(',');

      teacher = await this.selectOneByCondition({
        filterByFormula: `OR(${filterFormula})`
      });
    }

    return teacher;
  }

  generatePossibleDotCombinations(localPart, domain) {
    const chars = localPart.split('');
    const combinations = [];
    
    for (let i = 1; i < chars.length; i++) {
      const withDot = [...chars.slice(0, i), '.', ...chars.slice(i)].join('');
      combinations.push(`${withDot}@${domain}`);
    }
    
    return combinations;
  }

  getAllTeachersWithPagination = (params, listener) => {
    const { pageSize } = params;
    const conditions = [
      "{Teacher Name} != ''",
      "{Status} != 'Alumni'"
    ];

    return this.listItems({
      filterByFormula: `And(${conditions.join(",")})`,
      sort: [{ field: 'Teacher Name', direction: 'asc' }],
      pageSize: pageSize
    }, listener);
  }

  getTeachersByName = (name) => {
    return this.selectOneByCondition({
      filterByFormula: `{Teacher Name} = "${name}"`
    })
  }

  disableRatingRequest = (id, val) => {
    return this.update(id, { "Disable Rating Requests": val })
  }

  getTeachersForTeam = (teamEmail) => {
    if (!teamEmail) return [];

    return this.list({
      filterByFormula: `AND(
                {Status} = 'Active', 
                FIND(";${teamEmail.toLowerCase()};", CONCATENATE(";", SUBSTITUTE(LOWER(ARRAYJOIN({School Lead E-mail})), ",", ";"), ";"))
            )`,
      sort: [{ field: 'Teacher Name', direction: 'asc' }]
    })
  }

  getSchoolTeachers = (schoolName) => {
    return this.list({
      filterByFormula: `AND({School Name}='${schoolName}',{Status}='Active')`,
      sort: [{ field: 'Teacher Name', direction: 'asc' }]
    })
  }

  getClusterTeachers = (clusterName) => {
    return this.list({
      filterByFormula: `AND(
                {Status} = 'Active',
                FIND(';${clusterName};', CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Cluster}), ", ", ";"), ";"))
            )`,
      sort: [{ field: 'Teacher Name', direction: 'asc' }]
    })
  }

  getTeacherByRecordId = (teacherId) => {
    return this.selectOneByCondition({
      filterByFormula: `RECORD_ID() = '${teacherId}'`
    });
  }
}