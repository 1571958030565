import React, { useEffect, useRef, useState } from 'react';
import LoadingBar from '../../components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import { setAppUserInfo } from '../../redux/actions';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import { Trans, useTranslation } from 'react-i18next';
import MapWithAKmlLayer from '../../components/MapWithAKmlLayer';
import OverlayLoading from '../../components/OverlayLoading';
import PresenterFormModal from '../modals/PresenterForm';
import { setPresentersForProvider } from '../../redux/actions';
import airtable from '../../airtables';
import uploadFile from '../../libs/aws/uploadfile';
import { toast } from 'react-toastify';
import Select from 'react-select';
import './styles.scss';
import { DAY_LIMITATIONS, SELECTBOX_STYLE } from '../../utils/constants';
import TwoFactorAuthConfirmModal from '../modals/TwoFactorAuthConfirm';
import ProviderCalendars from './providerCalendars';

const Screen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userInfo, presentersForProvider } = useSelector(state => state.appInfo);
  const fileInputRef = useRef();

  const [updating, setUpdating] = useState(false);
  const [name, setName] = useState("");
  const [pronouns, setPronouns] = useState("")
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [logo, setLogo] = useState("");
  const [logoFile, setLogoFile] = useState();
  const [instructions, setInstructions] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [community, setCommunity] = useState("");
  const [dayLimitation, setDayLimitation] = useState([]);
  const [earliestStartTime, setEarliestStartTime] = useState("");
  const [recordingAllowed, setRecordingAllowed] = useState("");
  const [receiveRequestNotifications, setReceiveRequestNotifications] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processingTitle, setProcessingTitle] = useState("");
  const [activePresenter, setActivePresenter] = useState(null);
  const [activePresenterNo, setActivePresenterNo] = useState();
  const [showPresenterModal, setShowPresenterModal] = useState(false);
  const [authMethod, setAuthMethod] = useState("None");
  const [language, setLanguage] = useState(userInfo["Preferred Language"] || 'English')
  const [communities, setCommunities] = useState([]);

  const [_2FAVerificationInfo, set2FAVerificationInfo] = useState(null);

  const [calendarSelected, setCalendarSelected] = useState("");

  useEffect(() => {
    airtable.communities.getTotalCommunities().then(res => setCommunities(res))
  }, [])

  useEffect(() => {
    setName(userInfo['Contact Name']);
    setPronouns(userInfo["Pronouns"]);
    setEmail(userInfo['Email']);
    setPhoneNumber(userInfo['Phone']);
    setTimezone(userInfo["Timezone"]);
    setDayLimitation(getSelectRecords(userInfo["Day Limitations"]))
    setEarliestStartTime(userInfo["Earliest Start Time"] || "8:30");
    setBio(userInfo["Introduction/Bio"]);
    setRecordingAllowed(userInfo["Recording Allowed"]);
    setReceiveRequestNotifications(userInfo["Autobook"]);
    setInstructions(userInfo["Special Instructions"] ? userInfo["Special Instructions"] : '');
    setCommunity(userInfo["Indigenous Communities"] ? userInfo["Indigenous Communities"][0] : "");
    setAuthMethod(userInfo["2FA"] || "None");
    if (userInfo["Logo/Photo"] && userInfo["Logo/Photo"].length) {
      if (userInfo["Logo/Photo"][0].thumbnails) {
        setLogo(userInfo["Logo/Photo"][0].thumbnails.large.url)
      } else {
        setLogo(userInfo["Logo/Photo"][0].url)
      }
    }
    setLanguage(userInfo["Preferred Language"] || 'English');
    setPhoneDisabled(userInfo["2FA"] === "SMS");
  	setCalendarSelected(userInfo['Calendar Selected']);
  }, [userInfo])

  const getSelectRecords = (val) => {
    if (!val) return [];
    return val.map(item => ({
      value: item,
      label: item
    }))
  }

  const getValuesFromSelectRecords = (records) => {
    const vs = records.map(record => record.value)
    if (vs.length === 0) return null;
    return vs;
  }

  const updateInfo = async () => {
    if (phoneNumber && phoneNumber.replace(/\D/g, '').length < 10) {
      setPhoneError(t("mysettings.phone-number-error-length"));
      return;
    }
    setPhoneError("");

    let phoneVerified = userInfo["Phone Number SMS Verified"] ? true : false;
    if (phoneNumber !== userInfo["Phone"]) {
      phoneVerified = false;
    }

    if (authMethod === "SMS" && !phoneVerified) {
      set2FAVerificationInfo({
        type: "sms",
        phone: phoneNumber,
        uInfo: {}
      });
    } else if (authMethod === "App" && !userInfo["2FA Secret"]) {
      set2FAVerificationInfo({
        type: "app",
        uInfo: { userEmail: userInfo["Email"] }
      });
    }

    setUpdating(true);
    try {
      let params = {
        "Contact Name": name,
        "Email": email.trim(),
        "Phone": phoneNumber || null,
        "Timezone": timezone,
        "Indigenous Communities": community ? [community] : [],
        "Day Limitations": getValuesFromSelectRecords(dayLimitation),
        "Earliest Start Time": earliestStartTime,
        "Introduction/Bio": bio,
        "Recording Allowed": recordingAllowed,
        "Pronouns": pronouns,
        "Special Instructions": instructions,
        "Autobook": receiveRequestNotifications,
        "Preferred Language": language,
        "2FA": authMethod,
        "Phone Number SMS Verified": phoneVerified,
		"Calendar Selected": calendarSelected
      }

      if (authMethod !== "App" && userInfo["2FA"] !== authMethod) {
        params["2FA Secret"] = "";
      }

      if (logoFile) {
        const uRes = await uploadFile(logoFile);
        params["Logo/Photo"] = [{ url: uRes }];
        setLogoFile(null);
      }

      const info = await airtable.providers.update(userInfo.id, params);

      await setSession(CONNECTEDNORTH_SIGNINFO, info);
      dispatch(setAppUserInfo(info));
      toast.success("Saved successfully!")
    } catch (error) { }
    setUpdating(false);
  }

  const archivePresenter = async (presenter, no) => {
    setProcessingTitle("Archiving...");
    setProcessing(true);
    await airtable.presenters.update(presenter.id, {
      Archived: true
    });
    let newPs = [...presentersForProvider];
    newPs[no].Archived = true;
    dispatch(setPresentersForProvider(newPs))
    setProcessingTitle("");
    setProcessing(false);
  }

  const unarchivePresenter = async (presenter, no) => {
    setProcessingTitle("Unarchiving...");
    setProcessing(true);
    await airtable.presenters.update(presenter.id, {
      Archived: false
    });
    let newPs = [...presentersForProvider];
    newPs[no].Archived = false;
    dispatch(setPresentersForProvider(newPs))
    setProcessingTitle("");
    setProcessing(false);
  }

  const editPresenter = async (presenter, no) => {
    setActivePresenter(presenter);
    setActivePresenterNo(no);
    setShowPresenterModal(true)
  }

  const addPresenter = () => {
    setActivePresenter(null);
    setActivePresenterNo(-1);
    setShowPresenterModal(true);
  }

  const updatePresenterFromModal = async (email, name, phone) => {
    setShowPresenterModal(false);

    setProcessing(true);
    if (activePresenter) {
      setProcessingTitle("Updating...");
      const presenterInfo = await airtable.presenters.update(activePresenter.id, {
        "Name": name,
        "Email": email,
        "Phone": phone,
      });

      let newPs = [...presentersForProvider];
      newPs[activePresenterNo] = presenterInfo;
      dispatch(setPresentersForProvider(newPs));
    } else {
      setProcessingTitle("Adding...");
      const params = {
        Email: email,
        Name: name,
        Phone: phone,
        Provider: [userInfo.id]
      };
      const pInfo = await airtable.presenters.create(params);
      let newPs = [...presentersForProvider, pInfo];
      dispatch(setPresentersForProvider(newPs));
    }
    setProcessingTitle("");
    setProcessing(false);

    setActivePresenter(null);
    setActivePresenterNo(-1);
  }

  const deletePresenter = async (presenter, i) => {
    if (window.confirm("Are you sure you want to delete this presenter?")) {
      setProcessingTitle("Deleting...");
      setProcessing(true);
      await airtable.presenters.delete(presenter.id);

      let newPs = [...presentersForProvider];
      newPs.splice(i, 1);
      dispatch(setPresentersForProvider(newPs));

      setProcessingTitle("");
      setProcessing(false);
    }
  }

  const verifyPost = async (type, secret) => {
    let nUserInfo = null;
    if (type === "sms") {
      nUserInfo = await airtable.providers.update(userInfo.id, {
        "Phone": phoneNumber,
        "Phone Number SMS Verified": true,
        "2FA": "SMS"
      });
    } else if (type === "app") {
      nUserInfo = await airtable.providers.update(userInfo.id, {
        "2FA Secret": secret,
        "2FA": "App"
      });
    }

    if (nUserInfo) {
      await setSession(CONNECTEDNORTH_SIGNINFO, nUserInfo);
      dispatch(setAppUserInfo(nUserInfo));
      set2FAVerificationInfo(null);
      toast.success(t("mysettings.login-security-enabled"));
    }
  }

  return (
    <div className="main-container">
      {
        userInfo["Unique Schools"] >= 3 && (
          <div style={{ marginBottom: 20 }}>
            <h1>{userInfo["Unique Schools"]} {t("schools-reached")}</h1>
            <MapWithAKmlLayer recId={userInfo.id} type="provider_schools" />
          </div>
        )
      }

      <h1>{t("my-info")}</h1>

      {updating && <LoadingBar />}
      <div className="info-container">
        <div className="image-upload-section">
          <div className="image-preview">
            {logo ? (
              <img src={logo} alt="" />
            ) : <div className="no-img" />}
          </div>

          <input
            type="file"
            accept="application/image"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={e => {
              setLogoFile(e.target.files[0]);
              setLogo(URL.createObjectURL(e.target.files[0]));
            }}
          />

          <div className="image-upload-button">
            <Button size="sm" onClick={() => fileInputRef.current.click()} type="button" color="secondary">
              Select Profile Image to Upload
            </Button>
          </div>
        </div>
        <div className="form-section">
          <FormGroup row>
            <Label for="name" sm={4}>{t("contact-name")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Contact Name"
                defaultValue={name}
                onChange={e => setName(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="name" sm={4}>{t("pronouns")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="text"
                name="pronouns"
                id="pronouns"
                placeholder={t("pronouns")}
                defaultValue={pronouns}
                onChange={e => setPronouns(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>{t("email")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                defaultValue={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>Preferred Language</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                className='pronouns form-control'
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="English">English</option>
                <option value="French">French</option>
              </Input>
            </Col>
          </FormGroup>

          <div className="user-phone">
            <FormGroup row>
              <Label for="phone" sm={4}>{t("phone-number")}</Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder={t("mysettings.phone-number-placeholder")}
                  value={phoneNumber}
                  onChange={e => {
                    setPhoneNumber(e.target.value);
                    setPhoneError("");
                  }}
                  disabled={phoneDisabled}
                />
                {phoneError && <div className="text-danger mt-1">{phoneError}</div>}
              </Col>
            </FormGroup>
            {phoneDisabled && (
              <p className="phone-error">{t("mysettings.phone-number-disabled")}</p>
            )}
            {(!phoneNumber && authMethod === "SMS") ? (
              <p className="phone-error">{t("mysettings.phone-number-add-for-sms")}</p>
            ) : (!userInfo["Phone Number SMS Verified"] && authMethod === "SMS" && phoneNumber) && (
              <p className="phone-error">
                {t("mysettings.phone-number-not-verified")}{" "}
                <span
                  onClick={() => {
                    set2FAVerificationInfo({
                      type: "sms",
                      phone: phoneNumber,
                    });
                  }}
                >
                  {t("mysettings.phone-number-verify-here")}
                </span>.
              </p>
            )}
          </div>

          <div className="user-auth">
            <FormGroup row>
              <Label for="phone" sm={4}>{t("mysettings.login-security")}</Label>
              <Col sm={8} className="v-center">
                <Input
                  type="select"
                  className='_2fa-selector form-control'
                  value={authMethod}
                  onChange={(e) => setAuthMethod(e.target.value)}
                >
                  <option value="None">None</option>
                  <option value="SMS">SMS (Text Message)</option>
                  <option value="App">Authenticator App</option>
                </Input>
              </Col>
            </FormGroup>
            {(!userInfo["2FA Secret"] && authMethod === "App") && (
              <p className="_2fa-error"><span
                onClick={() => {
                  set2FAVerificationInfo({
                    type: "app",
                    uInfo: { userEmail: userInfo["Email"] }
                  });
                }}
              >Click here to set up an authenticator app</span> to receive passcodes.</p>
            )}
          </div>
          <span style={{ fontSize: '0.85rem' }}>When logging in, to provide additional security you can verify your login request with a SMS Text Message to your mobile device, or a smartphone-based authentication app, such as Google Authenticator or Microsoft Authenticator.</span>
          
          <FormGroup row style={{ marginTop: '1rem' }}>
            <Label for="timezone" sm={4}>{t("timezone")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                name="timezone"
                id="timezone"
                className="form-control"
                value={timezone}
                onChange={e => setTimezone(e.target.value)}
              >
                <option></option>
                <option>Canada/Atlantic</option>
                <option>Canada/Central</option>
                <option>Canada/Eastern</option>
                <option>Canada/Mountain</option>
                <option>Canada/Pacific</option>
                <option>Canada/Saskatchewan</option>
                <option>Canada/Yukon</option>
                <option>US/Alaska</option>
                <option>Australia/Sydney</option>
                <option>Other</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="community" sm={4}>{t("community")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                name="community"
                id="community"
                className="form-control"
                value={community}
                onChange={e => setCommunity(e.target.value)}
              >
                <option></option>
                {communities.map((com, i) => <option value={com.id} key={i}>{com.Name}</option>)}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="dayLimitation" sm={4}>Daily Availability</Label>
            <Col sm={8}>
              <Select
                isMulti
                className="form-style"
                value={dayLimitation}
                onChange={(selectedOptions) => setDayLimitation(selectedOptions)}
                options={DAY_LIMITATIONS.map(limitation => ({
                  value: limitation,
                  label: limitation
                }))}
                styles={SELECTBOX_STYLE}
              />
            </Col>
          </FormGroup>
          <span style={{ fontSize: '0.85rem' }}>If you're only available for sessions on certain days of the week, select those days to limit when requests can be made. If you are available on any day of the week, you do not need to make a selection.</span>
          <FormGroup row style={{ marginTop: '1rem' }}>
            <Label for="earliestStartTime" sm={4}>Earliest Start Time</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                name="earliestStartTime"
                id="earliestStartTime"
                className="form-control"
                value={earliestStartTime}
                onChange={e => setEarliestStartTime(e.target.value)}
              >
                <option>5:00</option>
                <option>5:30</option>
                <option>6:00</option>
                <option>6:30</option>
                <option>7:00</option>
                <option>7:30</option>
                <option>8:00</option>
                <option>8:30</option>
                <option>9:00</option>
                <option>9:30</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="recordingAllowed" sm={4}>{t("recording-allowed")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                name="recordingAllowed"
                id="recordingAllowed"
                className="form-control"
                value={recordingAllowed}
                onChange={e => setRecordingAllowed(e.target.value)}
                disabled
              >
                <option></option>
                <option>Yes</option>
                <option>Ask each time</option>
                <option>No</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={{ size: 12 }}>
              <p style={{ fontSize: '0.85rem' }}>
                <Trans
                  i18nKey="myinfo.recording-policy.message1"
                  components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    a: <a href="/cn/policies#recording-practices" style={{ textDecoration: 'underline' }} />
                  }}
                />
                <br />
                <Trans
                  i18nKey="myinfo.recording-policy.message2"
                  components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    a: <a href="mailto:contentproviders@connectednorth.org"></a>
                  }}
                />
              </p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="introductionBio" sm={4}>{t("introduction-bio")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="textarea"
                name="introductionBio"
                id="introductionBio"
                style={{ height: 150 }}
                value={bio}
                onChange={e => setBio(e.target.value)}
              ></Input>
            </Col>
          </FormGroup>

          <FormGroup row style={{ marginBottom: 0 }}>
            <Label for="receiveNotifications" data-intercom-target="Request Notifications" sm={4}>{t("receive-request-notifications")}</Label>
            <Col sm={8} className="v-center">
              <input
                id="receiveNotifications"
                type="checkbox"
                checked={receiveRequestNotifications}
                onChange={(e) => setReceiveRequestNotifications(e.target.checked)}
              />
            </Col>
          </FormGroup>

          <span style={{ fontSize: '0.85rem' }}>{t("myinfo-receive-request-notifications-desc")}</span>

          <FormGroup row style={{ marginTop: '1rem' }}>
            <Label for="instructions" sm={4}>{t("availability-booking-instructions")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="textarea"
                name="instructions"
                id="instructions"
                style={{ height: 150 }}
                value={instructions}
                placeholder="Please provide any general notes on availability, for example, only available Tuesday and Thursday, or special booking instructions, such as a booking web form or process"
                onChange={e => setInstructions(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="timezone" data-intercom-target="Google Calendar" sm={4}>{t("google-calendar")}</Label>
            <Col sm={8} className="v-center">
              {
                userInfo['Calendar Token'] ? (
                  <span>✓ {t("connected")} (<a href={`https://research.tigweb.org/connectednorth/provider-availability/disconnect.html?provider=${userInfo.id}`}>{t("disconnect")}</a>)</span>
                ) : (
                  <a href={`https://research.tigweb.org/connectednorth/provider-availability/calendarauth.html?provider=${userInfo.id}`}><span>{t("connect-google-calendar")}</span></a>
                )
              }
            </Col>
          </FormGroup>
          {
                userInfo['Calendar Token'] ? (
          <>
          <FormGroup row style={{ marginTop: '1rem' }}>
            <Label for="calendarSelected" sm={4}>Use this Calendar:</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                name="calendarSelected"
                id="calendarSelected"
                className="form-control"
                value={calendarSelected}
                onChange={e => setCalendarSelected(e.target.value)}
              >
                <ProviderCalendars provider={userInfo.id} />
              </Input>
            </Col>
          </FormGroup>
          </>
          ) : null
          }
          <span style={{ fontSize: '0.85rem' }}>{t("myinfo-google-calendar-desc")}</span>

          <div className="ms-action" data-intercom-target="Save">
            <Button
              color="primary"
              size="sm"
              onClick={() => updateInfo()}
              disabled={(!name || !email)}
            >{t("save-change")}</Button>
          </div>
        </div>
      </div>

      {
        userInfo["Individual/Org/Post-Sec."] !== 'Individual' && (
          <div style={{ marginTop: 20 }}>
            <Row>
              <Col>
                <h1>Presenters</h1>
              </Col>
              <Col align="right">
                <Button color="primary" size="sm" onClick={() => addPresenter()}><i className="fa fa-plus" />&nbsp;Add Presenter</Button>
              </Col>
            </Row>
            <div>
              {
                presentersForProvider.map((presenter, i) => (
                  <div className="presenter-container" key={i}>
                    <div style={{ flex: 1, display: 'flex' }}>
                      <div style={{ flex: 1 }}><span>{presenter.Name}{
                        presenter.Archived ? (<span>(Archived)</span>) : null
                      }</span></div>
                      <div style={{ flex: 1 }}><span>{presenter.Email}</span></div>
                      <div style={{ flex: 1 }}><span>{presenter.Phone}</span></div>
                    </div>
                    <UncontrolledDropdown>
                      <DropdownToggle color="secondary">
                        <i className="fa fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => editPresenter(presenter, i)}>Edit</DropdownItem>
                        {
                          !presenter.Archived ? (
                            <DropdownItem onClick={() => archivePresenter(presenter, i)}>Archive</DropdownItem>
                          ) : (
                            <DropdownItem onClick={() => unarchivePresenter(presenter, i)}>Unarchive</DropdownItem>
                          )
                        }
                        {
                          (!presenter["Sessions Delivered"]) ? (
                            <DropdownItem onClick={() => deletePresenter(presenter, i)}>Delete</DropdownItem>
                          ) : null
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                ))
              }
            </div>
            <OverlayLoading visible={processing} title={processingTitle} />
            {
              showPresenterModal ? (
                <PresenterFormModal
                  presenter={activePresenter}
                  onToggle={() => {
                    setShowPresenterModal(false);
                    setActivePresenter(null);
                    setActivePresenterNo(-1);
                  }}
                  onSave={updatePresenterFromModal}
                />
              ) : null
            }
          </div>
        )
      }

      {
        !!_2FAVerificationInfo && (
          <TwoFactorAuthConfirmModal
            type={_2FAVerificationInfo.type}
            phone={_2FAVerificationInfo.phone}
            uInfo={_2FAVerificationInfo.uInfo}
            onDismiss={() => set2FAVerificationInfo(null)}
            onVerifiedPost={verifyPost}
          />
        )
      }
    </div >
  )
}

export default Screen;
