import {
  SET_REQUEST_SESSION,
} from "./modalTypes";

const initialState = {
  requestSession: {
    visible: false,
    providerSession: null,
    isEdit: false,
  }
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REQUEST_SESSION:
      return {
        ...state,
        requestSession: action.payload
      }

    default:
      return state;
  }
}

export default modalReducer;