import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import qrcode from "qrcode";
import VerificationInput from "react-verification-input";
import { reuqestOTPUri, sendVerifySms, verifyOTP, verifySmsCode } from "../../../libs/apis";
import Loading from "../../../components/Loading";
import guideStep1Img from "../../../assets/img/2fa_step1.png"
import guideStep3Img from "../../../assets/img/2fa_step3.png"
import { detectOS } from "../../../utils/deviceDetection";
import "./styles.scss";

const TwoFactorAuthConfirmModal = ({
  type,
  phone,
  uInfo: { userSecret, userEmail } = {},
  onDismiss,
  onVerifiedPost,
  canClose = true
}) => {
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState("");
  const [qrImageUrl, setQRImageUrl] = useState();
  const [uSecret, setUSecret] = useState(userSecret || '');
  const [userOS, setUserOS] = useState("");

  const initialize = async () => {
    if (type === "sms") {
      await sendVerifySms(phone);
    } else if (type === "app") {
      if (!uSecret) {
        const resData = await reuqestOTPUri(userEmail, "Connected North");
        const { otpauth, secret } = resData;
        setUSecret(secret);
        qrcode.toDataURL(otpauth, (err, imageUrl) => {
          if (err) {
            console.log('Error with QR');
            return;
          }
          setQRImageUrl(imageUrl);
        });
      }
    }
  }

  useEffect(() => {
    initialize();
    setUserOS(detectOS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAppLink = (appName) => {
    const links = {
      "Google Authenticator": {
        ios: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        android: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      },
      "Duo Mobile": {
        ios: "https://apps.apple.com/us/app/duo-mobile/id422663827",
        android: "https://play.google.com/store/apps/details?id=com.duosecurity.duomobile",
      },
      "Authy": {
        ios: "https://apps.apple.com/us/app/authy/id494168017",
        android: "https://play.google.com/store/apps/details?id=com.authy.authy",
        windows: "https://authy.com/download/",
      },
      "Microsoft Authenticator": {
        ios: "https://apps.apple.com/us/app/microsoft-authenticator/id983156458",
        android: "https://play.google.com/store/apps/details?id=com.azure.authenticator",
      },
    };

    const appLinks = links[appName];
    if (userOS === "Android") return appLinks.android;
    if (userOS === "Windows" && appLinks.windows) return appLinks.windows;
    return appLinks.ios;
  };

  const completeCode = async (code) => {
    setVerifying(true)
    if (type === "sms") {
      const res = await verifySmsCode(phone, code);
      if (res.valid) {
        await onVerifiedPost(type);
      } else {
        setError("The code is not valid.")
      }
    } else if (type === "app") {
      const isValid = await verifyOTP(uSecret, code);
      if (isValid) {
        await onVerifiedPost(type, uSecret);
      } else {
        setError("The code entered is not valid. Please check the code you received and try again.")
      }
    }
    setVerifying(false)
  }

  const renderContent = () => {
    if (type === "sms" || (type === "app" && userSecret)) {
      return (
        <Fragment>
          <div className="_2fa-head">
            <h3>Enter Your Code</h3>
          </div>
          <div className="_2fa-content" align="center">
            {type === "sms" ? (
              <Fragment>
                <p>We've sent an SMS code to <b>{phone}</b>.</p>
                <p>To complete the verification process, please enter the 6-digit code</p>
              </Fragment>
            ) : (
              <p>Check your authenticator app on your mobile device for the 6-digit code</p>
            )}

            <div className="_2fa-code-container">
              <VerificationInput
                length={6}
                autoFocus
                validChars="0-9"
                inputProps={{ inputMode: "numeric", disabled: verifying }}
                placeholder="_"
                classNames={{
                  container: "_2fa-container",
                  character: "_2fa-char",
                  characterSelected: "_2fa-char-selected",
                  characterInactive: "_2fa-char-inactive",
                }}
                onComplete={completeCode}
              />
              <span className="_2fa-error">{error}</span>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <div className="_2fa-app-guide">
          <div className="_2fa-app-guide-step">
            <div className="_2fa-step-logo">
              <img src={guideStep1Img} alt="2FA authenticator app configuration step 1" />
            </div>
            <div className="_2fa-step-content">
              <span>Step 1</span>
              <h4>Get an app</h4>
              <p>
                Download and install the{" "}
                <a href={getAppLink("Google Authenticator")} target="_blank" rel="noopener noreferrer">Google Authenticator</a>,{" "}
                <a href={getAppLink("Duo Mobile")} target="_blank" rel="noopener noreferrer">Duo Mobile</a>,{" "}
                <a href={getAppLink("Authy")} target="_blank" rel="noopener noreferrer">Authy</a> or{" "}
                <a href={getAppLink("Microsoft Authenticator")} target="_blank" rel="noopener noreferrer">Microsoft Authenticator</a>{" "}
                app for your phone or tablet.
              </p>
            </div>
          </div>
          <hr />
          <div className="_2fa-app-guide-step">
            <div className="_2fa-step-logo">
              {!!qrImageUrl && (
                <img src={qrImageUrl} alt="OTP QR code" />
              )}
            </div>
            <div className="_2fa-step-content">
              <span>Step 2</span>
              <h4>Scan this barcode</h4>
              <p className="nomargin">Open the authentication app and:</p>
              <ul>
                <li>Tap the "+" icon, usually in the top-right or bottom-right of the app</li>
                <li>Scan the image to the left using your phone's camera</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="_2fa-app-guide-step">
            <div className="_2fa-step-logo">
              <img src={guideStep3Img} alt="2FA authenticator app configuration step 3" />
            </div>
            <div className="_2fa-step-content">
              <span>Step 3</span>
              <h4>Enter verification code</h4>
              <p>Once the barcode above is scanned, enter the 6-digit verification code generated by the app.</p>
            </div>
          </div>
          <div className="_2fa-code-container">
            <VerificationInput
              length={6}
              autoFocus
              validChars="0-9"
              inputProps={{ inputMode: "numeric", disabled: verifying }}
              placeholder="_"
              classNames={{
                container: "_2fa-container",
                character: "_2fa-char",
                characterSelected: "_2fa-char-selected",
                characterInactive: "_2fa-char-inactive",
              }}
              onComplete={completeCode}
            />
            <span className="_2fa-error">{error}</span>
          </div>
        </div>
      )
    }
  }

  return (
    <Modal
      className="modal-dialog-centered _2fa-configuration"
      isOpen={true}
      size={(type === "app" && !userSecret) ? "lg" : "md"}
    >
      <div className="modal-body">
        {verifying && (
          <div className="_2fa-code-verifying">
            <Loading size={40} />
          </div>
        )}
        {canClose && (
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => onDismiss()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        )}
        {renderContent()}
      </div>
    </Modal>
  )
}

export default TwoFactorAuthConfirmModal;