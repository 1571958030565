import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import greatImg from "../../../assets/img/great.jpg";

const NavContent = ({
  confirmContent
}) => {
  const { t } = useTranslation();

  return (
    <div className="nav-content-wrap">
      <div className="main-content">
        <h4>{t("session-host-bp.host-session.title")}</h4>
        <p>
          <Trans
            i18nKey="session-host-bp.host-session.p1"
            components={{ b: <b /> }}
          />
        </p>
        <p>{t("session-host-bp.host-session.p2")}</p>
        <p>{t("session-host-bp.host-session.p3")}</p>
        <p>
          <Trans
            i18nKey="session-host-bp.host-session.p4"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ a: <a href="https://drive.google.com/file/d/1CoMtc2dmaZaZBYV4G-p3Mw7NAQoolsAX/view" target="_blank" rel="noreferrer" /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="session-host-bp.host-session.p5"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ a: <a href="https://docs.google.com/document/d/1Ob4btzVAzg79EbQkCBYrN5YtUCBaTJ0Ux9Hl9fOgGfU/edit?usp=sharing" target="_blank" rel="noreferrer" /> }}
          />
        </p>
        <img className="great sh-img" src={greatImg} alt="GREAT" />
        <span
          className="nav-action"
          onClick={confirmContent}
        >
          {t("session-host-bp.host-session.continue")}
        </span>
      </div>
    </div>
  )
}

export default NavContent;