import { NUNAVUT_CLUSTER } from "../constants/pd-fields";
const pdPlanClusterId = NUNAVUT_CLUSTER;

// Array of allowed user IDs
const allowedUserIds = [
  "rec6rkdIxhr0Nrg3E",
  "recAv3fj6U3M9rsRn",
  "rec1GeXCvr6qA14xI",
  "recCxn5WKxWf7LdWV",
  "receJBAixwffiSUgQ",
  "recNPFMTS05QTZIko",
];

export const getIsPDPlanUser = (userInfo) => {
	if (!userInfo) return false;

	// Check if the user's Record ID is in the allowedUserIds array
	if (allowedUserIds.includes(userInfo["Record ID"])) {
		return true;
	}

	// Nunavut Teachers Only
	if (userInfo["Cluster"] && userInfo["Cluster"].length) {
		return userInfo["Cluster"].includes(pdPlanClusterId);
	}

	return false;
};
