import React from 'react';

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { Card } from "../../../components/Card";
import Vimeo from '@u-wave/react-vimeo';
import airtable from '../../../airtables';
import VideoPlayer from "../../../components/VideoPlayer";

import { getDisplayInfos } from "../../SessionInfoDisplay";
import SessionInfo from "../../../components/SessionInfo";
import CarouselProvider from "../../../components/Carousel";
import { nanoid } from "nanoid";
import EmbedVimeoPlayer from '../../../components/EmbedVimeoPlayer';

const responsiveSessions = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 1.5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 1.25,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsiveStrands = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function CurriculumRow({ header, content }) {
  return (
    <>
      {content && header && (
        <div className="mb-2">
          <h6 className="h6 font-weight-bold d-inline">{header}:</h6>
          <p className="d-inline">&nbsp;{content}</p>
        </div>
      )}
    </>
  );
}

export const SessionContent = ({
  session,
  provider,
  otherSessions,
  similarSessions,
  reviews,
  videos,
  curriculumLinks,
  setOpenSuggestModal,
}) => {
  const { userType, userInfo } = useSelector((state) => state.appInfo);
  const { t } = useTranslation();

  const getSessionKey = (linkedSessions) => {
    return linkedSessions === 2 ? "view-other-session" : "view-other-sessions";
  }

  const onPlay = async (video) => {
    let starters = video["Started Watching"] || [];
    if (starters.indexOf(userInfo.id) >= 0) return;

    const videoRecord = await airtable.videos.select(video.id);
    if (videoRecord && userType !== 'Team') {
      starters = videoRecord["Started Watching"] || [];
      if (!starters.length || starters.indexOf(userInfo.id) < 0) {
        starters.push(userInfo.id)
      }

      await airtable.videos.update(video.id, {
        "Started Watching": starters
      });
    }
  }

  const onEnd = async (video) => {
    let finishers = video["Started Watching"] || [];
    if (finishers.indexOf(userInfo.id) >= 0) return;

    const videoRecord = await airtable.videos.select(video.id);
    if (videoRecord && userType !== 'Team') {
      finishers = videoRecord["Finished Watching"] || [];
      if (!finishers.length || finishers.indexOf(userInfo.id) < 0) {
        finishers.push(userInfo.id)
      }

      await airtable.videos.update(video.id, {
        "Finished Watching": finishers
      });
    }
  }

  return (
    <div style={{ marginTop: 20 }}>
      <div className="mb-6">
        {session["Video Sample"] &&
          session["Video Sample"].length &&
          session["Video Sample"][0] && (!videos || userType === 'Team' || (userInfo["Sessions Completed"] && !userInfo["Provider Sessions Completed"]?.includes(session.id))) ? (
          <div>
            <h2 className="font-weight-bold">
              {t("video-session-preview")}
            </h2>
            <div className='video-col embed-responsive embed-responsive-16by9' style={{ width: '100%', borderRadius: '16px', overflow: 'hidden' }}>
              <VideoPlayer
                videoData={session["Video Sample"][0]}
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="session-info">
        {videos && videos.length > 0 &&
          (userType === "Team" ||
            (userInfo["Sessions Completed"] &&
              userInfo["Provider Sessions Completed"]?.includes(session.id))) ? (
          <div className="mb-5">
            <CarouselProvider
              title="Resource Videos"
              responsive={responsiveSessions}
              infinite={false}
            >
              {videos.map((video, j) => (
                <div key={nanoid(5)} className="session-detail-carousel-item">
                  <div
                    className="review font-weight-bold"
                    key={j}
                  >
                    {session.id === 'recGdS3kxweS8E5s7' ? (
                      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                        <EmbedVimeoPlayer
                          videoId={video.VimeoID}
                          allowFullScreen
                          title={video.Title}
                          frameBorder="0"
                          onPlay={() => onPlay(video)}
                          onEnd={() => onEnd(video)}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        />
                      </div>
                    ) : (
                      <div className="embeded-conatiner">
                        <Vimeo
                          video={video.VimeoID}
                          onPlay={() => onPlay(video)}
                          onEnd={() => onEnd(video)}
                          responsive
                        />
                      </div>
                    )}
                  </div>
                  <span>
                    {video.Title}
                  </span>
                </div>
              ))}
            </CarouselProvider>
          </div>
        ) : null}
        {reviews && reviews.length ? (
          <div className="mb-5">
            <CarouselProvider
              title={t("session-feedback")}
              responsive={responsiveSessions}
              infinite={false}
            >
              {reviews.map((review, i) => (
                <div key={nanoid(5)} className="session-detail-carousel-item">
                  <div
                    className="review font-weight-bold"
                    key={i}
                  >
                    <span>{review.Quote}</span>
                  </div>
                  <span>
                    - {review["Teacher Grade(s)"]}{" "}
                    Teacher from{" "}
                    {review["Teacher Location"]}
                  </span>
                </div>
              ))}
            </CarouselProvider>
          </div>
        ) : null}

        {provider &&
          <div className="mb-6 pb-4">
            <h2 className="font-weight-bold">
              {t("about-the-provider")}
            </h2>
            <Row className="session-card m-0 about-provider">
              {(provider["Logo/Photo"] && provider["Logo/Photo"].length) && (
                <Col xs="12" sm="4" className="d-flex align-items-center justify-content-center">
                  <div className="d-flex align-items-center justify-content-center session-image">
                    <img src={provider["Logo/Photo"][0].url} alt="Content Provider" />
                  </div>
                </Col>
              )}
              <Col xs="12" sm="8" style={{ textAlign: 'left' }}>
                <h3>{provider['Name']}</h3>
                <span className="h5 tagline">{[provider['Indigenous Group(s)'], provider['Cultural Group(s)'], provider['Indigenous Communities Text']].filter(x => x).join(" | ")}</span>
                <p>{provider["Introduction/Bio"]}</p>
                <Button
                  color="outline-primary"
                  onClick={() => window.location.href = `/cn/provider/${provider.id}`}
                >
                  {t("view-profile")}
                </Button>
              </Col>
            </Row>
          </div>
        }

        {curriculumLinks && curriculumLinks.length > 0 && (
          <div className="mb-5">
            <CarouselProvider
              title={t('curriculum-expectation')}
              responsive={responsiveStrands}
              infinite={false}
            >
              {curriculumLinks.map((link) => {
                return (
                  <Card key={nanoid(10)} style={{ background: "white", height: "100%" }}>
                    <h6 className="font-weight-bold h3">{link["Strand"]}</h6>
                    <CurriculumRow header={t("topic")} content={link['Topic']} />
                    <CurriculumRow
                      header={t("subject-areas")}
                      content={link["Subject Text"]}
                    />
                    {link["Grade(s)"] && Array.isArray(link["Grade(s)"]) && link["Grade(s)"].length > 0 && (
                      <CurriculumRow 
                        header={t("grades")} 
                        content={link["Grade(s)"].join(", ")} 
                      />
                    )}
                    {link["Region Name"] && Array.isArray(link["Region Name"]) && link["Region Name"].length > 0 && (
                      <CurriculumRow 
                        header="Region" 
                        content={link["Region Name"].join(", ")} 
                      />
                    )}
                    <a
                      href={`/cn/curriculum/${link.id}`}
                      className="btn btn-primary mt-4"
                    >
                      {link["Linked Sessions"] > 1 && t(getSessionKey(link["Linked Sessions"]), { number: link["Linked Sessions"] - 1 })}
                    </a>
                  </Card>
                )
              })}
            </CarouselProvider>
            <div className="mt-4 mb-7">
              <h3 className="h5 font-weight-bold">
                {t("missing-expectations")}
              </h3>
              <p>{t("please-let-us-know")}</p>
              {userType === "Teacher" && (
                <Button
                  className="primary"
                  onClick={() => setOpenSuggestModal(true)}
                >
                  {t("make-suggestion")}
                </Button>
              )}
            </div>
          </div>
        )}

        <div className="mb-5">
          {otherSessions && otherSessions.length > 0 &&
            <CarouselProvider
              title={t('other-sessions-by', { name: provider.Name })}
              responsive={responsiveSessions}
              infinite={false}
            >
              {
                otherSessions.map((s) =>
                  <SessionInfo
                    displayInfos={getDisplayInfos(s, 'Session-Page')}
                    sessionDetailLink={s.id}
                    hideProvider={true}
                    key={nanoid(10)}
                    session={s}
                    actionMenu={[
                      "copy-provider-session-link",
                      "open-detail",
                    ]}
                  />
                )
              }
            </CarouselProvider>
          }
        </div>
        <div className="mb-5">
          {similarSessions && similarSessions.length > 0 &&
            <CarouselProvider
              title={t('similar-sessions')}
              responsive={responsiveSessions}
              infinite={false}
            >
              {
                similarSessions.map((s) => (
                  <SessionInfo
                    displayInfos={getDisplayInfos(s, 'Session-Page')}
                    openSessionDetail={() => window.location.href = `/cn/session/${s.id}`}
                    sessionDetailLink={s.id}
                    key={nanoid(10)}
                    session={s}
                    actionMenu={[
                      "copy-provider-session-link",
                      "open-detail",
                    ]}
                  />
                ))
              }
            </CarouselProvider>
          }
        </div>
      </div>
    </div>
  )
}
