import AirtableTable from "../../AirtableTable";

export class PDPlansTable extends AirtableTable {
	constructor(instance) {
		super(instance, "PD Plans");

		this.fields = {
			ID: "fld4py2ST6l1aSuTz",
			Teacher: "fld5dCuAwWm6jCugC",
			"Plan Status": "fldteTV4AJderlqIH",
			"Completion Status": "fldjD0s1qWBwQQ7jG",
			Year: "fldxlh19cKOi0VrFQ",
			Created: "fld1l6ZXZTrSqgJEI",
			Goals: "fldEHx0V80dq7wMDR",
			"Complete for Review": "fldY0Bks9HimWpeoG",
			"Review 1 Date": "fldpTKionqFm9RYLV",
			"Review 1 Administrator": "fldtbcKxCsZQn0aah",
			"Review 1 Admin Comment": "fldEx1941OQEvHjKq",
			"Review 1 Teacher Comment": "fldbttIa0fOmihHb7",
			"Review 2 Date": "fldjeHOgJlwuaj842",
			"Review 2 Administrator": "fldsRlZFgf72ZqTFh",
			"Review 2 Admin Comment": "fldGvfVi3hJW1PTC0",
			"Review 2 Teacher Comment": "fldhC4ZovpWl5KCMI",
			"Review 3 Date": "fldcNDXjxkBL8GGrB",
			"Review 3 Administrator": "fldwng89JRPdoQzVg",
			"Review 3 Admin Comment": "fldBo28ngj7QzQuTP",
			"Review 3 Teacher Comment": "fldnmh2ZJiEc3RsE6",
		};
	}

	getPlansByIds = (ids = []) => {
		if (ids.length === 0) return [];
		const filter = ids.map((id) => `RECORD_ID() = "${id}"`);
		return this.list({
			filterByFormula: `OR(${filter})`,
		});
	};
}
