import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { distinctArray, hasCommonItems } from "../../../utils/array";
import airtable from '../../../airtables';
import { setGroupTeachers } from "../../../redux/actions";

const useHook = (data) => {
  const {
    userInfo,
    userType,
    groupTeachers: {
      loaded: teachersLoaded,
      teachers: groupTeachers
    }
  } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [matchedTeachers, setMatchedTeachers] = useState([]);
  const [otherTeachers, setOtherTeachers] = useState([]);
  const [selectedMatchedTeachers, setSelectedMatchedTeachers] = useState([]);
  const [selectedOtherTeachers, setSelectedOtherTeachers] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!data) return;

    setGrades(distinctArray(data["Grade(s)"] || []));
    setSubjects(distinctArray(data["Subject/Curriculum"] || []));
  }, [data]);

  useEffect(() => {
    if (!teachersLoaded) {
      if (userType === 'Team') {
        airtable.teachers.getTeachersForTeam(userInfo["Email"]).then(ts => {
          dispatch(setGroupTeachers({
            loaded: true,
            teachers: ts
          }));
        })
      } else if (userType === 'Teacher' && userInfo["Cluster Leadership"]) {
        airtable.teachers.getClusterTeachers(userInfo["Cluster Lead Text"][0]).then(ts => {
          dispatch(setGroupTeachers({
            loaded: true,
            teachers: ts
          }));
        })
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!groupTeachers || !groupTeachers.length) {
      setMatchedTeachers([]);
      setOtherTeachers([]);
      return;
    }

    const mts = [], ots = [];
    groupTeachers.forEach(teacher => {
      if (isMatchedTeacher(teacher)) {
        mts.push(teacher);
      } else {
        ots.push(teacher);
      }
    });
    setMatchedTeachers([...mts]);
    setOtherTeachers([...ots]);
    setLoading(false);
  }, [groupTeachers, subjects, grades]) // eslint-disable-line react-hooks/exhaustive-deps

  const isMatchedTeacher = (teacher) => {
    const hasMatchingSubject = hasCommonItems(teacher["Subject(s) Taught"], subjects);
    const hasMatchingGrade = hasCommonItems(teacher["Grade(s) Taught"], grades);
    return hasMatchingSubject && hasMatchingGrade;
  }

  const addTeachers = async () => {
    setProcessing(true);
    try {
      const tIds = [...selectedMatchedTeachers, ...selectedOtherTeachers].map(t => t.value);
      const teachers = groupTeachers.filter(t => tIds.indexOf(t.id) >= 0);

      await airtable.events.createMultiple(teachers.map((teacher) => ({
        Name: teacher["Teacher Name"],
        "E-mail Address": teacher["Email"],
        School: [teacher["School Name"][0]],
        Session: [data.id]
      })))
      setProcessing(false)
    } catch (error) {
      setProcessing(false);
    }
  }

  return {
    loading,
    totalTeachers: groupTeachers,
    matchedTeachers,
    otherTeachers,
    selectedMatchedTeachers,
    setSelectedMatchedTeachers,
    selectedOtherTeachers,
    setSelectedOtherTeachers,
    addTeachers,
    processing
  }
}

export default useHook;