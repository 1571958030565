import {
  SET_APP_USERINFO,
  SET_APP_USERTYPE,
  SET_APP_IS_SESSION_HOST,
  SET_PASS_SESSION_PAGE_TYPE,
  SET_APP_LOCALE,
  SET_SUBJECT_MAP,
  SET_TOTAL_SUBJECTS,
  SET_MY_FAVORITES,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  SET_PRESENTER_UPCOMING,
  SET_PRESENTER_PAST,
  SET_PRESENTER_LOADING,

  SET_PRESENTERS_FOR_PROVIDER,
  SET_TOTAL_REGIONS,
  SET_REGIONS_MAP,
  SET_IS_NEW_PROVIDER,
  SET_COMMUNITIES,
  SET_GROUP_TEACHERS,
  SET_TOTAL_TEAMS,
  SET_TAGS,
  SET_TIMEZONES,
  SET_TEAM_SCHOOLS,
} from './types';

export const setIsNewProvider = (n) => {
  return {
    type: SET_IS_NEW_PROVIDER,
    payload: n
  }
}

export const setAppUserInfo = (ui) => {
  return {
    type: SET_APP_USERINFO,
    payload: ui
  }
}

export const setAppIsSessionHost = (p) => {
  return {
    type: SET_APP_IS_SESSION_HOST,
    payload: p
  }
}

export const setAppUserType = (ut) => {
  return {
    type: SET_APP_USERTYPE,
    payload: ut
  }
}

export const setPassSessionPageType = (pspt) => {
  return {
    type: SET_PASS_SESSION_PAGE_TYPE,
    payload: pspt
  }
}

export const setAppLocale = (locale) => {
  return {
    type: SET_APP_LOCALE,
    payload: locale
  }
}

export const setSubjectMap = (subjectMap) => {
  return {
    type: SET_SUBJECT_MAP,
    payload: subjectMap
  }
}

export const setTotalSubjects = (subs) => {
  return {
    type: SET_TOTAL_SUBJECTS,
    payload: subs
  }
}

export const setMyFavorites = (favorites) => {
  return {
    type: SET_MY_FAVORITES,
    payload: favorites
  }
}

export const addFavorite = (f) => {
  return {
    type: ADD_FAVORITE,
    payload: f
  }
}

export const removeFavorite = (f) => {
  return {
    type: REMOVE_FAVORITE,
    payload: f
  }
}

export const setPresenterUpcoming = (s) => {
  return {
    type: SET_PRESENTER_UPCOMING,
    payload: s
  }
}

export const setPresenterPast = (s) => {
  return {
    type: SET_PRESENTER_PAST,
    payload: s
  }
}

export const setPresenterLoading = (loading) => {
  return {
    type: SET_PRESENTER_LOADING,
    payload: loading
  }
}

export const setPresentersForProvider = (ps) => {
  return {
    type: SET_PRESENTERS_FOR_PROVIDER,
    payload: ps
  }
}

export const setTotalRegions = (rs) => {
  return {
    type: SET_TOTAL_REGIONS,
    payload: rs
  }
}

export const setRegionsMap = (rm) => {
  return {
    type: SET_REGIONS_MAP,
    payload: rm
  }
}

export const setCommunities = (cms) => {
  return {
    type: SET_COMMUNITIES,
    payload: cms
  }
}

export const setGroupTeachers = (tInfo) => {
  return {
    type: SET_GROUP_TEACHERS,
    payload: tInfo
  }
}

export const setTotalTeams = (tTeams) => {
  return {
    type: SET_TOTAL_TEAMS,
    payload: tTeams
  }
}

export const setTags = (ts) => {
  return {
    type: SET_TAGS,
    payload: ts
  }
}

export const setTimezones = tzs => {
  return {
    type: SET_TIMEZONES,
    payload: tzs
  }
}

export const setTeamSchools = ts => {
  return {
    type: SET_TEAM_SCHOOLS,
    payload: ts,
  }
}