import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const HostEmailRedirect = () => {
  const { userInfo, isSessionHost } = useSelector(state => state.appInfo);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tigsso = searchParams.get('tigsso');
    
    if (!tigsso) {
      console.error('Missing tigsso parameter');
      return;
    }

    const baseUrl = "https://register.tigweb.org/saml/process_response_tig.php";
    const url = `${baseUrl}?tigsso=${tigsso}&cnchecked=true`;

    if (isSessionHost && userInfo["Host Email"]) {
      window.location.href = `${url}&HostEmail=${encodeURIComponent(userInfo["Host Email"])}`;
    } else {
      const pushpath = encodeURIComponent(`research.tigweb.org/saml/process_response_tig.php?tigsso=${tigsso}`);
      window.location.href = `http://www.tigweb.org/members/login.html?pushpath=${pushpath}`;
    }
  }, [isSessionHost, location.search, userInfo]);

  return <></>;
}

export default HostEmailRedirect;