import AirtableTable from "../AirtableTable";

export class CreditTable extends AirtableTable {
	constructor(instance) {
		super(instance, "tbl9h4F4mWNPg1YcR");

		this.fields = {
			"ID": "fldgxMPCplO9N3SGE",
			"Teacher": "fld1gw7zotiYGh9Az",
			"Credits": "fldJzq7Y9lvQuo3h6",
			"Action": "fldovB8lPuYtfIsQO",
			"Session": "fldiMGOacGd4HZX2p",
			"Session Status": "fld58jmA0SoQwJwXh",
			"Display Note": "fldPOjXo7nH1CPm8s",
			"Transaction ID": "fldqvOs9Y0P1pJQ1Z",
			"Transferred To/From": "flduLp2YLdNj9gt3X",
			"Date": "fldEdHmw0lPWCQfPm"
		}
	}
}
