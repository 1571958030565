import AirtableTable from "../../AirtableTable";

export class StandardsTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Professional Standards");

    this.fields = {
      "ID": "fldGvFZZZR8uzPpZN",
      "Code": "fldtgLZR14cVwzOwg",
      "Focus Area": "fldQnXthaqRTKbVIY",
      "Usertype": "fldPDiEDwp7kQXJJ3",
      "Standard": "fldaXElglOqW8mJQr",
      "Domain": "fldSsWK3EOMEGhcFS",  
      "PD Plan Goals": "fld1TOsPLhIoYtuKk"
    };
  }

  listByUserType(userType) {
    return this.list({
      filterByFormula: `{Usertype} = '${userType}'`,
      sort: [{ field: 'Code', direction: 'asc' }]
    });
  }
}