export const GOAL_FIELDS = {
	PLAN: "PD Plan",
	STANDARD: "Standard",
	SPECIFIC: "Specific",
	MEASURABLE: "Measurable",
	MEASURABLE_OTHER: "Measurable Other",
	ACHIEVABLE: "Achievable",
	RELEVANT: "Relevant",
	TIME_PHASED: "Time-Phased",
	SCHOOL_SUPPORTS: "School Supports",
};

export const PLAN_FIELDS = {
	ID: "ID",
	TEACHER: "Teacher",
	STATUS: "Plan Status",
	COMPLETION_STATUS: "Completion Status",
	YEAR: "Year",
	CREATED: "Created",
	GOALS: "Goals",
	COMPLETE_FOR_REVIEW: "Complete for Review",
	REVIEW_1_DATE: "Review 1 Date",
	REVIEW_1_ADMIN: "Review 1 Administrator",
	REVIEW_1_ADMIN_COMMENT: "Review 1 Admin Comment",
	REVIEW_1_TEACHER_COMMENT: "Review 1 Teacher Comment",
	REVIEW_2_DATE: "Review 2 Date",
	REVIEW_2_ADMIN: "Review 2 Administrator",
	REVIEW_2_ADMIN_COMMENT: "Review 2 Admin Comment",
	REVIEW_2_TEACHER_COMMENT: "Review 2 Teacher Comment",
	REVIEW_3_DATE: "Review 3 Date",
	REVIEW_3_ADMIN: "Review 3 Administrator",
	REVIEW_3_ADMIN_COMMENT: "Review 3 Admin Comment",
	REVIEW_3_TEACHER_COMMENT: "Review 3 Teacher Comment",
	TRACKED_GOALS: "# of Goals",
};

export const REVIEW_FIELDS = [
	{
		LABEL: "November Review",
		NAME: PLAN_FIELDS.REVIEW_1_ADMIN,
		DATE: PLAN_FIELDS.REVIEW_1_DATE,
		COMMENT: PLAN_FIELDS.REVIEW_1_ADMIN_COMMENT,
		TEACHER_COMMENT: PLAN_FIELDS.REVIEW_1_TEACHER_COMMENT,
	},
	{
		LABEL: "December Review",
		NAME: PLAN_FIELDS.REVIEW_2_ADMIN,
		DATE: PLAN_FIELDS.REVIEW_2_DATE,
		COMMENT: PLAN_FIELDS.REVIEW_2_ADMIN_COMMENT,
		TEACHER_COMMENT: PLAN_FIELDS.REVIEW_2_TEACHER_COMMENT,
	},
	{
		LABEL: "May/June Review",
		NAME: PLAN_FIELDS.REVIEW_3_ADMIN,
		DATE: PLAN_FIELDS.REVIEW_3_DATE,
		COMMENT: PLAN_FIELDS.REVIEW_3_ADMIN_COMMENT,
		TEACHER_COMMENT: PLAN_FIELDS.REVIEW_3_TEACHER_COMMENT,
	},
];

export const TEACHER_FIELDS = {
	NAME: "Teacher Name",
	SCHOOL: "School Name",
	RECORD_ID: "Record ID",
	PRINCIPAL: "Principal",
	PD_PLANS: "PD Plans",
	REVIEW_1_ADMIN: "Review 1 Administrator",
	REVIEW_2_ADMIN: "Review 2 Administrator",
	REVIEW_3_ADMIN: "Review 3 Administrator",
	CLUSTER_RECORD_IDS: "Cluster Record IDs",
};

export const STANDARD_FIELDS = {
	CODE: "Code",
	FOCUS_AREA: "Focus Area",
	STANDARD: "Standard",
	DOMAIN: "Domain",
	PD_PLAN_GOALS: "PD Plan Goals",
};

export const INPUT_OPTIONS = [
	"singleSelect",
	"multiSelect",
	"smallText",
	"largeText",
	"datePicker",
];

export const GOAL_INPUTS = () => {
	return [
		{
			id: GOAL_FIELDS.SPECIFIC,
			type: "smallText",
			label: "The specific developmental goal I want to achieve",
		},
		{
			id: GOAL_FIELDS.STANDARD,
			type: "multiSelect",
			label: " The standard this aligns with",
		},
		{
			id: GOAL_FIELDS.TIME_PHASED,
			type: "datePicker",
			label: " Timeframe to achieve my goal",
		},
		{
			id: GOAL_FIELDS.MEASURABLE,
			type: "singleSelect",
			label: "evidence to demonstrate my progression",
		},
		{
			id: GOAL_FIELDS.MEASURABLE_OTHER,
			type: "smallText",
			label: "Other Reason",
		},
		{
			id: GOAL_FIELDS.ACHIEVABLE,
			type: "largeText",
			label: "Actions to achieve my goal",
		},
		{
			id: GOAL_FIELDS.RELEVANT,
			type: "largeText",
			label: "Why this goal is relevant to  my professional growth, my school’s priorities, and my students’ outcomes",
		},

		{
			id: GOAL_FIELDS.SCHOOL_SUPPORTS,
			type: "largeText",
			label: "School support(s) to achieve my goal",
		},
	];
};

export const DEFAULT_GOAL = {
	[GOAL_FIELDS.STANDARD]: [],
	[GOAL_FIELDS.TIME_PHASED]: "",
	[GOAL_FIELDS.SPECIFIC]: "",
	[GOAL_FIELDS.MEASURABLE]: ["Student survey(s)"],
	[GOAL_FIELDS.MEASURABLE_OTHER]: "",
	[GOAL_FIELDS.ACHIEVABLE]: "",
	[GOAL_FIELDS.RELEVANT]: "",
	[GOAL_FIELDS.SCHOOL_SUPPORTS]: "",
};

export const MEASURABLE_VALUES = [
	"Student survey(s)",
	"School-specific assessments (performance evaluations, peer reviews)",
	"School-specific assessments (scheduled tests/exams)",
	"Rubric(s)",
	"Other",
];

export const REQUIRED_FIELDS = [
	"Standard",
	"Specific",
	"Measurable",
	"Achievable",
	"Relevant",
	"Time-Phased",
	"School Supports",
];
export const REQUIRED_FIELDS_OTHERS = [...REQUIRED_FIELDS, "Measurable Other"];

export const NUNAVUT_CLUSTER = "recvxymwLWjeYAfwv";
