import { useEffect, useState } from "react";
import Airtable from "../../airtables";

/**
 * @typedef {Object} HookProps
 * @property {boolean} loading
 * @property {Provider} [provider]
 * @property {any[]} sessions
 * @property {FeaturedVideoSample} sample
 * @property {CreateToLearn[]} [courses]
 * @property {Review[]} [reviews]
 *
 * @param {string} providerID
 * @returns {HookProps}
 */
export function useProviderDetails(providerID) {
	/** @type {[Provider | undefined, React.Dispatch<React.SetStateAction<Provider | undefined>>]} */
	const [provider, setProvider] = useState();

	/** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
	const [loading, setLoading] = useState(true);

	/** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
	const [sessions, setSessions] = useState([]);

	/** @type {[FeaturedVideoSample | undefined, React.Dispatch<React.SetStateAction<FeaturedVideoSample | undefined>>]} */
	const [sample, setSample] = useState(undefined);

	/** @type {[c2l[] | undefined, React.Dispatch<React.SetStateAction<c2l[] | undefined>>]} */
	const [courses, setCourses] = useState(undefined);

	/** @type {[Review[] | undefined, React.Dispatch<React.SetStateAction<Review[] | undefined>>]} */
	const [reviews, setReviews] = useState(undefined);
	useEffect(() => {
		(async () => {
			try {
				setLoading(() => true);

				const provider = await queryProvider(providerID);
				setProvider(() => provider);

				if (provider) {
					const {
						providerSessions,
						sampleID,
						createToLearn,
						reviewIDs,
					} = provider;
					const [
						sessions,
						sampleData,
						c2lData,
						reviews,
					] = await Promise.all([
						Airtable.providerSessions.getProviderSessionsByIds(providerSessions),
						querySample(sampleID),
						queryCreateToLearn(createToLearn),
						queryReviews(reviewIDs),
					]);

					setSessions(() => sessions);
					setSample(() => sampleData);
					setCourses(() => c2lData);

					// attaching session name to review
					if (reviews) {
						/**
						 * @type {Record<string, string>}
						 * mapping session id -> session title
						 */
						const sessionMap = {};
						for (const s of sessions) {
							sessionMap[s.id] = s.title;
						}
						for (let i = 0; i < reviews.length; i++) {
							const sessionID = reviews[i].sessionID;
							reviews[i].session = sessionMap[sessionID];
						}
						setReviews(() => reviews);
					}
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(() => false);
			}
		})();
	}, [providerID]);

	return { loading, provider, sessions, sample, courses, reviews };
}

/**
 * @typedef {Object} Review
 * @property {string} id
 * @property {string} review
 * @property {string} sessionID
 * @property {string} [session] - this will be need to get from the `Session` object.
 * @property {{name: string, school: string | undefined}} teacher
 *
 * @param {Array<string>} ids
 * @returns {Promise<Array<Review>> | void}
 */
async function queryReviews(ids) {
	const reviews = await Airtable.reviews.getReviewsByIds(ids);

	return reviews.map((r) => {
		const id = r["id"];
		const review = r["Quote"];
		const sessiontitle = r["Session Title"];
		const teachergrades = r["Teacher Grade(s)"];
		const teacherlocation = r["Teacher Location"];
		const sessionID = r["Session"][0];

		return { id, review, teachergrades, teacherlocation, sessiontitle, sessionID };
	});
}

/**
 * @typedef {Object} CreateToLearn
 * @property {string} id
 * @property {string} title
 * @property {string} creator
 * @property {string} [description]
 * @property {string} difficulty
 * @property {string} [thumbnail]
 * @property {string} url
 * @property {number} videoCount
 * @property {number} length - in minutes, i think
 *
 * @param {string[]} ids
 * @returns {Promise<CreateToLearn[] | void>}
 *
 * NOTE: the data I'm getting back has 0 length.
 */
async function queryCreateToLearn(ids) {
	const cl = await Airtable.c2l.getC2lsByIds(ids)

	return cl.map((c) => {
		const thumbnail = c["Thumbnail"][0]["url"] ?? undefined;
		const videoCount = c["Videos"].split(",").length;
		return {
			id: c["id"],
			title: c["Series Name"],
			creator: c["Creator"],
			description: c["Description"],
			thumbnail,
			videoCount,
			length: c["Total Length"],
			difficulty: c["Difficulty"],
			url: c["Web URL"],
		};
	});
}

/**
 * @typedef {Object} FeaturedVideoSample
 * @property {string} id
 * @property {string} title
 * @property {string} description
 *
 * @param {string} [id]
 * @returns {Promise<FeaturedVideoSample | void>} sample
 */
async function querySample(id) {
	const r = await Airtable.providerSessions.selectSampleSession(id);

	if (!r) return null;

	return {
		title: r["Title"],
		id: r["id"],
		description: r["Description"],
	};
}

/**
 * @typedef {Object} Provider
 * @property {string} name
 * @property {string} [nameFr]
 * @property {string} [bio]
 * @property {string[]} groups
 * @property {string} [logo]
 * @property {number} offeredSessions
 * @property {string} [exampleRecording]
 * @property {string} [sampleID]
 * @property {string} [embedSample]
 * @property {string[]} firesideChatIDs
 * @property {Array<string>} providerSessions
 * @property {Array<string>} createToLearn
 * @property {Array<string>} reviewIDs
 *
 * @param {string} id
 * @returns {Promise<Provider>}
 */
async function queryProvider(id) {
	const d = await Airtable.providers.select(id);

	const name = d["Name"];
	/** @type {string | undefined} */
	const nameFr = d["Name (FR)"];
	/** @type {string | undefined} */
	const bio = d["Introduction/Bio"];
	/** @type {number} */
	const offeredSessions = d["Offered"];
	let logo = d["Logo/Photo"];
	if (!logo || !logo.length) {
		logo = undefined;
	} else {
		logo = logo[0]["url"];
	}
	/** @type {string | undefined} */
	const exampleRecording = d["Example Recording Link"];
	/** @type {Array<string>} */
	const groups = d["Indigenous Group(s)"] ?? [];
	/** @type {Array<string>} */
	const providerSessions = d["Provider Sessions"] ?? [];

	const s = d["Featured Video Sample Session"];
	/** @type {string | undefined} */
	const sampleID = s ? s[0] : undefined;

	/** @type {string | undefined} */
	const embedSample = d["Embed Sample"];

	/** @type {Array<string>} */
	const firesideChatIDs = d["Fireside Chats"] ?? [];
	const firesideChatURL = d["FSC Web URL"] ?? [];
	const firesideChatIllustration = d["FSC Illustration"] ?? [];
	const firesideChatQuote = d["FSC Quote"] ?? [];
	const firesideChatSubtitle = d["FSC Subtitle"] ?? [];
	const firesideChatCategory = d["FSC Category Text"] ?? [];

	/** @type {Array<string>} */
	const createToLearn = d["C2L"] ?? [];

	/** @type {Array<string>} */
	const reviewIDs = d["Reviews"] ?? [];

	return {
		name,
		nameFr,
		bio,
		offeredSessions,
		logo,
		exampleRecording,
		groups,
		providerSessions,
		sampleID,
		embedSample,
		firesideChatIDs,
		firesideChatIllustration,
		firesideChatQuote,
		firesideChatSubtitle,
		firesideChatURL,
		firesideChatCategory,
		createToLearn,
		reviewIDs,
		fullData: d,
	};
}
