/**
 * 
 * @param {string} contactType 
 * @param {any} contact 
 * @param {string} keyword 
 * @returns 
 */
export const checkContactByKeyword = (
    contactType,
    contact,
    keyword
) => {
    const k = keyword.toLocaleUpperCase();
    const fieldNames = [];
    if (contactType === 'Teacher') {
        fieldNames.push("Teacher Name");
        fieldNames.push("School Name Text");
        fieldNames.push("Email");
    } else if (contactType === 'Provider') {
        fieldNames.push("Name");
        fieldNames.push("Email");
    } else {
        fieldNames.push("Name");
    }

    return fieldNames.some(field => {
        const fieldValue = String(contact[field]); // Convert field value to string
        return fieldValue.toLocaleUpperCase().search(k) >= 0;
    })
}

/**
 * 
 * @typedef {Object} ContactPage
 * @property {number} pageNo
 * @property {[]} items
 * 
 * @param {ContactPage[]} previous 
 * @param {*} contact
 * @param {number} pageSize
 */
export const addSearchResult = (
    previous,
    contact,
    pageSize = 10
) => {
    if (
        previous.length === 0 ||
        previous[previous.length - 1].items.length === pageSize
    ) {
        previous.push({
            pageNo: previous.length,
            items: []
        })
    }

    previous[previous.length - 1].items.push(contact);
}